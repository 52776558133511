import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'antd'

import InvoiceAddonTwoColumn from '../InvoiceAddonTwoColumn/InvoiceAddonTwoColumn'
import ReimbursementsTable from '../ReimbursementsTable/ReimbursementsTable'
import Form from './form'

const ReimbursementForm = ({
  reimbursements,
  onAddReimbursement,
  onRemoveReimbursement,
}) => {
  const rightColumn = () => (
    <>
      <h3>
        <FormattedMessage
          key="ReimbursementForm.intoTitle"
          defaultMessage="About Reimbursements"
        />
      </h3>
      <p>
        <FormattedMessage
          key="ReimbursementForm.infoText1"
          defaultMessage="Här kan du lägga till utlägg du haft för kostnader som varit nödvändiga för ditt arbetes utförande. Dessa kostnader måste förburkas under fakturaperioden/arbetets gång och får inte ha ett bestående värde."
        />
      </p>
      <p>
        <FormattedMessage
          key="ReimbursementForm.infoText2"
          defaultMessage="Om din kund även ska debiteras för kostnaden för varan du köpt in på uppdrag av dem så ska du lägga in detta som ett utlägg samt även lägga till nettobeloppet av utlägget i en egen fakturarad (exklusive moms)."
        />
      </p>
      <p>
        <a
          href="https://konsultfaktura.zendesk.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            key="ReimbursementForm.infoTextCta"
            defaultMessage="Read more about Reimbursements"
          />
        </a>
      </p>
      <p>
        <a
          href="http://konsultfaktura.se/wp-content/uploads/2020/04/KF-Avdragsguide.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            key="ReimbursementForm.infoPdfCta"
            defaultMessage="Read the Reimbursements guide"
          />
        </a>
      </p>
    </>
  )

  return (
    <>
      <InvoiceAddonTwoColumn rightColumn={rightColumn()}>
        <h3>
          <FormattedMessage
            key="ReimbursementForm.title"
            defaultMessage="Reimbursements"
          />
        </h3>

        <Form onAddReimbursement={onAddReimbursement} />
      </InvoiceAddonTwoColumn>
      {reimbursements && reimbursements.length > 0 && (
        <ReimbursementsTable
          reimbursements={reimbursements}
          onRemoveReimbursement={onRemoveReimbursement}
        />
      )}

      <Divider style={{ marginTop: 40, marginBottom: 70 }} />
    </>
  )
}

export default ReimbursementForm
