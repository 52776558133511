import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Select } from 'antd'

const InvoiceDueDaysSelect = ({ dueDays, onChange }) => (
  <Select value={dueDays} onChange={onChange} style={{ width: '100%' }}>
    <Select.Option value={10}>
      <FormattedMessage key="due10" defaultMessage="10 days" />
    </Select.Option>
    <Select.Option value={15}>
      <FormattedMessage key="due15" defaultMessage="15 days" />
    </Select.Option>
    <Select.Option value={20}>
      <FormattedMessage key="due20" defaultMessage="20 days" />
    </Select.Option>
    <Select.Option value={30}>
      <FormattedMessage key="due30" defaultMessage="1 month" />
    </Select.Option>
    <Select.Option value={60}>
      <FormattedMessage key="due60" defaultMessage="2 months" />
    </Select.Option>
    <Select.Option value={90}>
      <FormattedMessage key="due90" defaultMessage="3 months" />
    </Select.Option>
  </Select>
)

export default InvoiceDueDaysSelect
