import React, { Component } from 'react'
import { defineMessages } from 'react-intl'
import { Row, Col, Card } from 'antd'
import { FormattedMessage } from 'react-intl'
import ActivityWidget from '../../components/ActivityWidget/ActivityWidget'
import SalaryCalculator from '../../components/SalaryCalculator/SalaryCalculator'
import FaqWidget from '../../components/FaqWidget/FaqWidget'
import InvoicesWidget from '../../components/InvoicesWidget/InvoicesWidget'
import MyFinancesWidget from '../../components/MyFinancesWidget/MyFinancesWidget'
import PageLayout from '../../components/PageLayout/PageLayout'
import OnboardingProgress from '../../components/OnboardingProgress/OnboardingProgress'
import { withAuth } from 'services/auth'

const messages = defineMessages({
  pageTitle: {
    id: 'App.HomeView.pageTitle',
    defaultMessage: 'Home'
  }
})

class UserHomeView extends Component {
  render() {
    return (
      <PageLayout pageTitleKey={messages.pageTitle} wide>
        <div>
          <Row gutter={32}>
            <Col sm={24} md={12}>
              <OnboardingProgress />

              <Card style={{ marginBottom: 42 }} bordered={false} bodyStyle={{ padding: 0 }}>
                <h2 style={{ fontWeight: 700, paddingBottom: 12, borderBottom: '1px #e8e8e8 solid' }}>
                  <FormattedMessage key="invoicesTitle" defaultMessage="My invoices" />
                </h2>
                <InvoicesWidget />
              </Card>

              <Card style={{ marginBottom: 42 }} bordered={false} bodyStyle={{ padding: 0 }}>
                <h2 style={{ fontWeight: 700, paddingBottom: 12, borderBottom: '1px #e8e8e8 solid'  }}>
                  <FormattedMessage key="activityTitle" defaultMessage="Activities" />
                </h2>
                <ActivityWidget />
              </Card>
            </Col>
            <Col sm={24} md={12}>
              {/* <FormattedMessage key="myFinancesTitle" defaultMessage="My Finances" /> */}
              <MyFinancesWidget />

              <SalaryCalculator user={this.props.user} />

              <Card style={{ marginBottom: 42 }} bordered={false} bodyStyle={{ padding: 0 }}>
                <h2 style={{ fontWeight: 700, paddingBottom: 12, borderBottom: '1px #e8e8e8 solid'  }}>
                  <FormattedMessage key="faqTitle" defaultMessage="Questions and answers" />
                </h2>
                <FaqWidget />
              </Card>
            </Col>
          </Row>
        </div>
      </PageLayout>
    )
  }
}

export default withAuth(UserHomeView)