import React, { Component } from 'react'
import { message } from 'antd'
import { injectIntl } from 'react-intl'
import { Mutation } from 'react-apollo'
import PasswordForm from './password-form'
import changePasswordMutation from './changePasswordMutation.gql'

class ChangePassword extends Component {
  state = {
    fieldErrors: {}
  }
  onCompleted({ changePassword }) {
    if (changePassword.errors) {
      this.setState({ fieldErrors: changePassword.errors })
    } else {
      message.success(this.props.intl.formatMessage({ id: 'components.ChangePassword.successMessage', defaultMessage: 'Your password has been successfully changed' }))
    }
  }

  render() {
    const { user } = this.props
    if (!user) {
      return null
    }
    return (
      <Mutation mutation={changePasswordMutation} onCompleted={args => this.onCompleted(args)}>
        {(mutate, { loading }) => (
          <PasswordForm
            fieldErrors={this.state.fieldErrors}
            onSubmit={input => {
              mutate({
                variables: { password: input.password, previousPassword: input.previousPassword }
              })
            }}
            saving={loading}
          />
        )}
      </Mutation>
    )
  }
}

export default injectIntl(ChangePassword)
