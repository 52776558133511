import { Col, Row, Tabs } from 'antd'
import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { withAuth } from 'services/auth'
import CustomerSelector from '../CustomerSelector/CustomerSelector'
import EmployeeDetailsMissing from '../EmployeeDetailsMissing/EmployeeDetailsMissing'
import FancyButton from '../FancyButton/FancyButton'
import InvoiceAddons from '../InvoiceAddons/InvoiceAddons'
import InvoiceDescriptionInput from '../InvoiceDescriptionInput/InvoiceDescriptionInput'
import InvoiceDueDaysSelect from '../InvoiceDueDaysSelect/InvoiceDueDaysSelect'
import InvoiceErrors from '../InvoiceErrors/InvoiceErrors'
import InvoiceRows, { emptyRow } from '../InvoiceRows/InvoiceRows'
import InvoiceSummary from '../InvoiceSummary/InvoiceSummary'
import SectionHeader from '../SectionHeader/SectionHeader'
import styles from './InvoiceForm.module.css'
import InvoiceCalendar from 'components/InvoiceCalendar/InvoiceCalendar'
import moment from 'moment'
import getRotRutDeductable from 'lib/getRotRutDeductable'
import { calculate } from 'lib/shared/salary-calculator'

const { TabPane } = Tabs

const messages = defineMessages({
  chooseCustomer: {
    id: 'InvoiceForm.chooseCustomer',
    defaultMessage: 'Choose Customer',
  },
  invoiceRows: {
    id: 'InvoiceForm.invoiceRows',
    defaultMessage: 'Items / Services',
  },
  invoiceDescription: {
    id: 'InvoiceForm.invoiceDescription',
    defaultMessage: 'Description',
  },
  invoiceDueDays: {
    id: 'InvoiceForm.invoiceDuedays',
    defaultMessage: 'Payment terms',
  },
  extras: {
    id: 'InvoiceForm.extras',
    defaultMessage: 'Extras',
  },
  summary: {
    id: 'InvoiceForm.summary',
    defaultMessage: 'Summary',
  },
  saveDraft: {
    id: 'InvoiceForm.saveDraft',
    defaultMessage: 'Save invoice',
  },
  errors: {
    id: 'InvoiceForm.errors',
    defaultMessage: 'Just a few issues',
  },
  errorNoCustomerId: {
    id: 'InvoiceForm.errorNoCustomerId',
    defaultMessage: 'You nee to specify a customer.',
  },
  noInvoiceDetails: {
    id: 'InvoiceForm.noInvoiceDetails',
    defaultMessage: 'Missing invoice details.',
  },
  errorNoItems: {
    id: 'InvoiceForm.errorNoItems',
    defaultMessage: 'You must add invoice items.',
  },
  errorTooLowAmount: {
    id: 'InvoiceForm.errorTooLowAmount',
    defaultMessage: 'Salary to be paid out must be at least 500 kr.',
  },
  errorNoEmployeeDetails: {
    id: 'InvoiceForm.errorNoEmployeeDetails',
    defaultMessage: 'You need to add employee details to be able to invoice.',
  },
  errorNoEmployeeDetailsCta: {
    id: 'InvoiceForm.errorNoEmployeeDetailsCta',
    defaultMessage: 'Do it here.',
  },
  errorNoEmail: {
    id: 'InvoiceForm.errorNoEmail',
    defaultMessage: 'You need to add your email to be able to invoice.',
  },
  errorNoEmailCta: {
    id: 'InvoiceForm.errorNoEmailCta',
    defaultMessage: 'Do it here.',
  },
  errorTooHighAddonAmount: {
    id: 'InvoiceForm.errorTooHighAddonAmount',
    defaultMessage:
      'Your travel expenses, reimbursements and tractament cannot exceed the invoice amount!',
  },
  sendInvoice: {
    id: 'InvoiceForm.sendInvoice',
    defaultMessage: 'Send invoice',
  },
  invoiceRowsTitle: {
    id: 'InvoiceForm.invoiceRowsTitle',
    defaultMessage: 'Invoice rows',
  },
  calendarTitle: {
    id: 'InvoiceForm.calendarTitle',
    defaultMessage: 'Calendar',
  },
  chooseCustomerHelp: {
    id: 'InvoiceForm.chooseCustomerHelp',
    defaultMessage:
      'Här väljer du vilken kund du vill fakturera och ta betalt från. Har du tidigare skapat en faktura till kunden så finns den redan i listan. Annars lägger du till en ny med hjälp av knappen "skapa en ny kund" och matar in de uppgifter som efterfrågas i fälten.',
  },
  invoiceDescriptionHelp: {
    id: 'InvoiceForm.invoiceDescriptionHelp',
    defaultMessage:
      'Beskriv så gott du kan med egna ord vad fakturan avser och/eller vad uppdraget gått ut på. En tydlig beskrivning minimerar risken för att eventuella missförstånd kan uppstå.',
  },
  invoiceDueDaysHelp: {
    id: 'InvoiceForm.invoiceDueDaysHelp',
    defaultMessage:
      'Här väljer du hur lång tid din kund har på sig att betala fakturan. Har ni inte kommit överens om annat så brukar standard vara 30 dagar.',
  },
  invoiceRowsHelp: {
    id: 'InvoiceForm.invoiceRowsHelp',
    defaultMessage:
      'Här specificerar du och lägger till det du vill ta betalt för. Det finns två vyer du kan välja mellan. I ”Kalendervyn” kan du välja datum och i ”Fakturarader" kan du lägga in det mesta, både tid och varor m.m. Du skriver in varan/tjänsten och väljer antal samt belopp (ex moms). Till höger på varje rad ser du summeringen (ex moms). Det är 25% moms på nästan alla varor och tjänster du säljer (är du osäker kontakta oss eller läs mer på skatteverkets hemsida). Vill du lägga till fler rader så klickar du bara på knappen "+ Lägg till rad" och om du vill ta bort en rad så klickar du på "papperskorgen" till höger på den rad som du vill ta bort.',
  },
  extrasHelp: {
    id: 'InvoiceForm.extrasHelp',
    defaultMessage:
      'Vill du göra avdrag i fakturan så väljer du det här. För varje kategori finns en kort beskrivande informationsruta och skulle du behöva mer hjälp kring hur de olika avdragen fungerar så kan du klicka dig vidare till vårt hjälpcenter. Annars är det bara att du tar kontakt med oss så hjälper vi dig vidare med detta!',
  },
  summaryHelp: {
    id: 'InvoiceForm.summaryHelp',
    defaultMessage:
      'Här summeras allt du matat in ovan och visar vad fakturabeloppet kommer bli (det kunden ska betala ex moms) samt det du kommer få utbetalt i lön. Om du vill se hur beräkningen är gjord med alla avgifter och skatter så kan du klicka på "visa detaljer". Slutligen är det bara att välja om du vill skicka fakturan direkt (Skicka faktura) eller om du vill föranmäla uppdraget (Föranmäl faktura).',
  },
})

function calculateTotalAmountExVat(invoiceRows) {
  return invoiceRows.reduce((sum, row) => {
    return sum + Math.round(parseInt(row.amountExVat) * parseInt(row.count))
  }, 0)
}

function calculateTotalVat(invoiceRows) {
  return invoiceRows.reduce((sum, row) => {
    const vatPercentage = parseInt(row.vatPercentage.split('_')[1]) / 100.0
    return (
      sum +
      Math.round(parseInt(row.amountExVat) * parseInt(row.count)) *
        vatPercentage
    )
  }, 0)
}

function calculateTotalRotAmount(invoiceRows) {
  return invoiceRows.reduce((sum, row) => {
    return sum + getRotRutDeductable(row)
  }, 0)
}

const calculateReimbursementsSum = (rows) => {
  return rows.reduce((sum, row) => sum + row.amount, 0)
}

const calculateTractamentsSum = (rows) => {
  return rows.reduce((sum, row) => sum + row.amount, 0)
}

const calculateTravelExpensesSum = (rows) => {
  return rows.reduce((sum, row) => sum + row.amount, 0)
}

function addVat(invoiceRows) {
  return invoiceRows.map((row) => {
    return {
      description: row.description,
      count: row.count,
      vatPercentage: row.vatPercentage,
      amountExVat: row.amountExVat,
      rot: row.rot,
      rotHours: row.rotHours,
      rotType: row.rotType,
    }
  })
}

class InvoiceForm extends React.Component {
  state = {
    description: '',
    customerId: null,
    dueDays: 30,
    invoiceRows: [emptyRow],
    action: null,
    rot: null,
    reimbursements: [],
    travelExpenses: [],
    tractaments: [],
    invoiceItemsMode: 'rows',
    customer: null,
  }

  setInvoiceItemsMode = (invoiceItemsMode) => {
    const { invoiceRows: unfilteredInvoiceRows } = this.state

    // If switching to calendar, remove all rows that doesnt fit calendar mode
    const invoiceRows =
      invoiceItemsMode === 'calendar'
        ? unfilteredInvoiceRows.filter((r) => moment(r.description).isValid())
        : unfilteredInvoiceRows

    this.setState({ invoiceItemsMode, invoiceRows })
  }
  setCustomer = (customer) => {
    this.setState({ customerId: customer.id, customer })
  }
  setInvoiceRows = (invoiceRows) => {
    this.setState({ invoiceRows })
  }
  setDescription = (description) => {
    this.setState({ description })
  }
  setDueDays = (dueDays) => {
    this.setState({ dueDays })
  }

  onAddReimbursement = (reimbursement) => {
    this.setState({
      reimbursements: [...this.state.reimbursements, reimbursement],
    })
  }

  onRemoveReimbursement = (index) => {
    const { reimbursements } = this.state
    reimbursements.splice(index, 1)
    this.setState({
      reimbursements,
    })
  }

  onAddTravelExpense = (travelExpense) => {
    this.setState({
      travelExpenses: [...this.state.travelExpenses, travelExpense],
    })
  }

  onRemoveTravelExpense = (index) => {
    const { travelExpenses } = this.state
    travelExpenses.splice(index, 1)
    this.setState({
      travelExpenses,
    })
  }

  onAddTractament = (tractament) => {
    this.setState({
      tractaments: [...this.state.tractaments, tractament],
    })
  }

  onRemoveTractament = (index) => {
    const { tractaments } = this.state
    tractaments.splice(index, 1)
    this.setState({
      tractaments,
    })
  }

  onRotChange = (key, value) => {
    const { rot: currentRot } = this.state
    const rot = currentRot ? currentRot : {}
    rot[key] = value
    this.setState({ rot })
  }
  onRemoveRot = () => {
    this.setState({ rot: null })
  }

  getInvoiceData = () => {
    const {
      customerId,
      invoiceRows,
      description,
      dueDays,
      rot,
      reimbursements,
      travelExpenses,
      tractaments,
    } = this.state

    const invoice = {
      rows: addVat(invoiceRows),
      customerId,
      description,
      dueDays,
      reimbursements,
      travelExpenses,
      tractaments,
      ...rot,
    }
    console.log(invoice)
    return invoice
  }

  componentDidMount() {
    const invoice = this.props.invoice

    if (invoice) {
      const { dueDays, customer, rows, description } = invoice

      const newState = {
        customerId: customer.id,
        dueDays,
        description,

        invoiceRows: rows.map(
          ({ description, amountExVat, vatPercentage, count }) => {
            return {
              description,
              amountExVat: amountExVat.valueInCent,
              vatPercentage,
              count,
            }
          }
        ),
      }

      if (invoice && invoice.taxReduction) {
        newState.rot = {
          propertyDesignation: invoice.taxReduction.propertyDesignation,
          residenceAssociationOrgNo:
            invoice.residenceAssociationOrganisationNumber,
        }
      }

      this.setState(newState)
    }

    this.setState({ initialState: this.state })
  }

  handleSave = (e) => {
    e.preventDefault()

    if (this.validateForm().length > 0) {
      return this.setState({ showErrors: true })
    }

    this.setState({ action: 'save' })

    const invoice = this.getInvoiceData()
    this.props.onSave(invoice)
  }

  handleSend = (e) => {
    e.preventDefault()

    if (this.validateForm().length > 0) {
      return this.setState({ showErrors: true })
    }

    this.setState({ action: 'send' })

    const invoice = this.getInvoiceData()

    this.props.onSend(invoice)
  }

  validateForm = () => {
    const { readyToInvoice, user } = this.props

    const errors = []
    const {
      customerId,
      invoiceRows,
      reimbursements,
      travelExpenses,
      tractaments,
    } = this.state

    const totalAmount = calculateTotalAmountExVat(invoiceRows)
    const reimbursementsSum = calculateReimbursementsSum(reimbursements)
    const tractamentsSum = calculateTractamentsSum(tractaments)
    const travelExpensesSum = calculateTravelExpensesSum(travelExpenses)
    const taxYear = new Date().getFullYear()

    const amounts = calculate(
      totalAmount,
      { taxYear, serviceFeePercentage: user.serviceFeePercentage },
      {
        reimbursementsSum,
        tractamentsSum,
        travelExpensesSum,
      }
    )

    if (!readyToInvoice) {
      errors.push(
        <>
          <FormattedMessage {...messages.errorNoEmployeeDetails} />{' '}
          <Link to="/settings">
            <FormattedMessage {...messages.errorNoEmployeeDetailsCta} />
          </Link>
        </>
      )
    }

    if (!user.email) {
      errors.push(
        <>
          <FormattedMessage {...messages.errorNoEmail} />{' '}
          <Link to="/settings/change-email">
            <FormattedMessage {...messages.errorNoEmailCta} />
          </Link>
        </>
      )
    }

    if (!customerId) {
      errors.push(<FormattedMessage {...messages.errorNoCustomerId} />)
    }

    if (invoiceRows.length < 1) {
      errors.push(<FormattedMessage {...messages.errorNoItems} />)
    }

    if (amounts.totalSalary < 50000) {
      errors.push(<FormattedMessage {...messages.errorTooLowAmount} />)
    }

    const totalAddonsAmount =
      reimbursementsSum + tractamentsSum + travelExpensesSum
    if (totalAddonsAmount > totalAmount) {
      errors.push(<FormattedMessage {...messages.errorTooHighAddonAmount} />)
    }

    return errors
  }

  render() {
    const { loading, readyToInvoice, user, invoice } = this.props
    const {
      customerId,
      invoiceRows,
      description,
      dueDays,
      rot,
      reimbursements,
      travelExpenses,
      tractaments,
      invoiceItemsMode,
      customer,
    } = this.state

    const totalAmountExVat = calculateTotalAmountExVat(invoiceRows)
    const totalVatAmount = calculateTotalVat(invoiceRows)
    const reimbursementsSum = calculateReimbursementsSum(reimbursements)
    const tractamentsSum = calculateTractamentsSum(tractaments)
    const travelExpensesSum = calculateTravelExpensesSum(travelExpenses)
    const totalRotAmount = calculateTotalRotAmount(invoiceRows)

    const errors = this.validateForm()
    // eslint-disable-next-line no-unused-vars
    const customerType = customer && customer.customerType

    if (invoice && invoice.taxReduction) {
      return (
        <div>
          Denna faktura är inte skickad men går tyvärr inte att redigera. Du får
          skapa en ny faktura - Kontakta support@konsultfaktura.se för mer
          information.
        </div>
      )
    }

    return (
      <>
        {!readyToInvoice && <EmployeeDetailsMissing />}

        <SectionHeader
          title={<FormattedMessage {...messages.chooseCustomer} />}
          help={<FormattedMessage {...messages.chooseCustomerHelp} />}
        />
        <CustomerSelector value={customerId} onChange={this.setCustomer} />

        <Row gutter={24}>
          <Col md={16}>
            <SectionHeader
              title={<FormattedMessage {...messages.invoiceDescription} />}
              help={<FormattedMessage {...messages.invoiceDescriptionHelp} />}
            />
            <InvoiceDescriptionInput
              description={description}
              onChange={this.setDescription}
            />
          </Col>
          <Col md={8}>
            <SectionHeader
              title={<FormattedMessage {...messages.invoiceDueDays} />}
              help={<FormattedMessage {...messages.invoiceDueDaysHelp} />}
            />
            <InvoiceDueDaysSelect
              dueDays={dueDays}
              onChange={this.setDueDays}
            />
          </Col>
        </Row>

        <SectionHeader
          title={<FormattedMessage {...messages.invoiceRows} />}
          help={<FormattedMessage {...messages.invoiceRowsHelp} />}
        />

        <Tabs
          activeKey={invoiceItemsMode}
          onChange={this.setInvoiceItemsMode}
          animated={false}
        >
          <TabPane
            tab={<FormattedMessage {...messages.invoiceRowsTitle} />}
            key="rows"
          >
            <InvoiceRows
              invoiceRows={invoiceRows}
              onChange={this.setInvoiceRows}
            />
          </TabPane>
          <TabPane
            tab={<FormattedMessage {...messages.calendarTitle} />}
            key="calendar"
          >
            <InvoiceCalendar
              invoiceRows={invoiceRows}
              onChange={this.setInvoiceRows}
            />
          </TabPane>
        </Tabs>

        <SectionHeader
          title={<FormattedMessage {...messages.extras} />}
          help={<FormattedMessage {...messages.extrasHelp} />}
          border
        />
        <InvoiceAddons
          invoiceRows={invoiceRows}
          rot={rot}
          customer={customer}
          onRotChange={this.onRotChange}
          onRemoveRot={this.onRemoveRot}
          setInvoiceRows={this.setInvoiceRows}
          reimbursements={reimbursements}
          onAddReimbursement={this.onAddReimbursement}
          onRemoveReimbursement={this.onRemoveReimbursement}
          travelExpenses={travelExpenses}
          onAddTravelExpense={this.onAddTravelExpense}
          onRemoveTravelExpense={this.onRemoveTravelExpense}
          tractaments={tractaments}
          onAddTractament={this.onAddTractament}
          onRemoveTractament={this.onRemoveTractament}
        />

        <SectionHeader
          title={<FormattedMessage {...messages.summary} />}
          help={<FormattedMessage {...messages.summaryHelp} />}
          border
        />

        <InvoiceSummary
          totalAmountExVat={totalAmountExVat}
          totalRotAmount={totalRotAmount}
          totalVatAmount={totalVatAmount}
          reimbursementsSum={reimbursementsSum}
          tractamentsSum={tractamentsSum}
          travelExpensesSum={travelExpensesSum}
          serviceFeePercentage={user.serviceFeePercentage}
        />

        {this.state.showErrors && (
          <InvoiceErrors
            errors={errors}
            title={<FormattedMessage {...messages.errors} />}
          />
        )}

        <div style={{ textAlign: 'right' }}>
          <FancyButton
            variant="secondary"
            style={{ marginTop: 20 }}
            onClick={this.handleSave}
            loading={this.state.action === 'save' && loading}
          >
            <FormattedMessage {...messages.saveDraft} />
          </FancyButton>

          <FancyButton
            variant="cta"
            style={{ marginTop: 10 }}
            onClick={this.handleSend}
            className={styles.cta}
            loading={this.state.action === 'send' && loading}
          >
            <FormattedMessage {...messages.sendInvoice} />
          </FancyButton>
        </div>
        <div style={{ height: 30 }} />
      </>
    )
  }
}

export default withAuth(InvoiceForm)
