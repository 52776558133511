import React from 'react'
import { Mutation } from 'react-apollo'
import { message } from 'antd'
import CustomerForm from 'components/CustomerForm/form'
import updateCustomer from './updateCustomer.gql'
import listCustomers from 'components/CustomersTable/listCustomers.gql'
import getCustomerForEdit from '../../views/user/customers/getCustomerForEdit.gql'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

const UpdateCustomer = ({ customer, customerId, loading }) => {
  const history = useHistory()
  const intl = useIntl()
  const onCancel = () => {
    history.push('/customers')
  }

  return (
    <Mutation
      mutation={updateCustomer}
      awaitRefetchQueries={true}
      refetchQueries={[
        { query: listCustomers },
        { query: getCustomerForEdit, variables: { id: customerId } },
      ]}
      onCompleted={res => {
        message.success(
          intl.formatMessage({
            id: 'components.UpdateCustomer.successMessage',
            defaultMessage: 'Successfully saved the customer',
          })
        )
      }}
    >
      {(mutate, { loading: saving }) => (
        <CustomerForm
          onCancel={onCancel}
          loading={loading || saving}
          customer={customer}
          onSubmit={values => {
            mutate({
              variables: { input: values, customerId },
              refetchQueries: ['CustomerSelectorListCustomers'],
            })
          }}
        />
      )}
    </Mutation>
  )
}

export default UpdateCustomer
