import { defineMessages, FormattedMessage } from 'react-intl'
import React from 'react'

const messages = defineMessages({
  compareToFirstPasswordError: 'Password doesn\'t match'
})

const compareToFirstPassword = (form, field) => (rule, value, callback) => {
  if (value && value !== form.getFieldValue(field)) {
    callback(<FormattedMessage {...messages.compareToFirstPasswordError} />)
  } else {
    callback()
  }
}
const validateToNextPassword = (form, field) => (rule, value, callback) => {
  if (value) {
    form.validateFields([field], { force: true })
  }
  callback()
}

const fileUpload = () => (_rule, value, callback) => {
  if (value === 'error' || (Array.isArray(value) && value.some(val => val === 'error'))) {
    callback(<FormattedMessage key="fileUploadErrors" defaultMessage="There were some files that failed to upload. Please retry or remove those." />)
  }

  if (value === 'uploading' || (Array.isArray(value) && value.some(val => val === 'uploading'))) {
    callback(<FormattedMessage key="fileUploadInProgress" defaultMessage="Wait for all files to upload" />)
  }

  callback()
}
export { compareToFirstPassword, validateToNextPassword, fileUpload }
