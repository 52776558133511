import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import deleteInvoiceDraft from './deleteInvoiceDraft.gql'
import { Mutation } from 'react-apollo'
import { Tooltip, message, Popconfirm } from 'antd'
import { useHistory } from 'react-router-dom'
import FancyButton from '../FancyButton/FancyButton'
import getInvoice from '../InvoiceView/getInvoice.gql'
import listInvoices from '../InvoiceTable/listInvoices.gql'

const DeleteInvoiceDraftButton = ({ invoice }) => {
  const intl = useIntl()
  const history = useHistory()

  if (!invoice) return null

  const { id, status } = invoice

  if (status !== 'draft') {
    return (
      <Tooltip
        title={
          <FormattedMessage
            key="incorrectStatus"
            defaultMessage="This invoice cannot be deleted because it has status {status}"
            values={{ status }}
          />
        }
      >
        <FancyButton variant="secondary" type="primary" disabled>
          <FormattedMessage key="buttonText" defaultMessage="Delete" />
        </FancyButton>
      </Tooltip>
    )
  }

  return (
    <Mutation
      mutation={deleteInvoiceDraft}
      variables={{ id }}
      refetchQueries={[
        { query: getInvoice, variables: { id } },
        { query: listInvoices },
      ]}
      awaitRefetchQueries={true}
      onCompleted={({ deleteInvoiceDraft: { errors } }) => {
        if (errors) {
          message.error(errors.map(({ message }) => message).join(', '))
        } else {
          history.push('/invoices')
          message.success(
            intl.formatMessage({
              id: 'components.DeleteInvoiceDraftButton.successMessage',
              defaultMessage: 'Invoice draft was deleted',
            })
          )
        }
      }}
    >
      {(mutate, { loading }) => (
        <Popconfirm
          title={
            <FormattedMessage
              key="confirmTitle"
              defaultMessage="Are you sure you want to delete this invoice?"
            />
          }
          onConfirm={() => mutate()}
          okText={intl.formatMessage({
            id: 'components.DeleteeInvoiceDraftButton.okText',
            defaultMessage: 'Yes',
          })}
          cancelText={intl.formatMessage({
            id: 'components.DeleteeInvoiceDraftButton.cancelText',
            defaultMessage: 'No',
          })}
        >
          <FancyButton variant="secondary" type="danger" loading={loading}>
            <FormattedMessage key="buttonText" defaultMessage="Delete" />
          </FancyButton>
        </Popconfirm>
      )}
    </Mutation>
  )
}

export default DeleteInvoiceDraftButton
