import { Layout } from 'antd'
import React, { Component } from 'react'
import { withAuth } from 'services/auth'
import Sidebar from '../../components/Sidebar/Sidebar'
import AdminAlert from './admin-alert'
import AdminGhostBar from 'components/AdminGhostBar/AdminGhostBar'
import AppErrorBoundary from 'components/AppErrorBoundary/AppErrorBoundary'
import { defineMessages, injectIntl } from 'react-intl'

import home from './icons/63-home-outline.json'
import invoices from './icons/60-documents-outline.json'
import customers from './icons/271-three-male-avatars-outline.json'
import invoicesNew from './icons/89-document-plus-outline.json'
import settings from './icons/201-settings-sliders-morph-outline.json'
import support from './icons/202-chat-outline.json'
import logout from './icons/94-lock-morph-outline.json'
import Zendesk from 'components/Zendesk/Zendesk'

const menuMessages = defineMessages({
  'menu.home': 'Home',
  'menu.invoices': 'Invoices',
  'menu.customers': 'Customers',
  'menu.invoices.new': 'New invoice',
  'menu.settings': 'Settings',
  'menu.settings.employee': 'Employee Details',
  'menu.support': 'Support',
  'menu.logout': 'Logout',
})

class UserLayout extends Component {
  get sidebarMenuItems() {
    const { intl } = this.props
    // console.log(this.props)

    return [
      {
        key: 'home',
        to: '/home',
        lottieIcon: home,
        text: intl.formatMessage(menuMessages['menu.home']),
      },
      {
        key: 'invoices-create',
        to: '/invoices/create',
        lottieIcon: invoicesNew,
        text: intl.formatMessage(menuMessages['menu.invoices.new']),
      },
      {
        key: 'invoices',
        to: '/invoices',
        lottieIcon: invoices,
        text: intl.formatMessage(menuMessages['menu.invoices']),
      },
      {
        key: 'customers',
        to: '/customers',
        lottieIcon: customers,
        text: intl.formatMessage(menuMessages['menu.customers']),
      },
      {
        key: 'settings',
        to: '/settings',
        lottieIcon: settings,
        text: intl.formatMessage(menuMessages['menu.settings']),
      },
      {
        key: 'support',
        to: '/support',
        lottieIcon: support,
        text: intl.formatMessage(menuMessages['menu.support']),
      },
      {
        key: 'logout',
        onClick: this.props.logout,
        lottieIcon: logout,
        text: intl.formatMessage(menuMessages['menu.logout']),
      },
    ].filter(Boolean)
  }

  render() {
    const { user } = this.props

    return (
      <>
        <Layout style={{ height: '100vh' }}>
          <div className="alert-banners">
            <AdminGhostBar />
            <AdminAlert user={user} />
          </div>

          <Layout>
            <Sidebar menuItems={this.sidebarMenuItems} />
            <AppErrorBoundary>
              <Layout.Content
                style={{
                  background: '#fff',
                }}
              >
                {this.props.children}
              </Layout.Content>
            </AppErrorBoundary>
          </Layout>
        </Layout>
        <Zendesk />
      </>
    )
  }
}

export default withAuth(injectIntl(UserLayout))
