
import React from 'react'
import styles from './InvoiceAddonTwoColumn.module.css'
import { Row, Col } from 'antd'

const InvoiceAddonTwoColumn = ({ children, rightColumn }) => (
  <div className={styles.wrapper}>
    <Row gutter={80}>
      <Col xs={24} sm={24} md={12}>
        {children}
      </Col>
      <Col xs={24} sm={24} md={12}>
        <div className={styles.infoBox}>
          {rightColumn}
        </div>
      </Col>
    </Row>
  </div>
)

export default InvoiceAddonTwoColumn