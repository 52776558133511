import React from 'react'
import { Card } from 'antd'
import InvoiceStatusIcon from '../InvoiceStatusIcon/InvoiceStatusIcon'
import InvoiceStatusText from '../InvoiceStatusText/InvoiceStatusText'
import { FormattedAmount } from 'lib/locale'
import { useHistory } from 'react-router-dom'

const MobileInvoiceItem = ({ invoice }) => {
  const history = useHistory()

  return (
    <Card
      bordered={false}
      onClick={() => {
        history.push(`/invoices/${invoice.id}`)
      }}
    >
      <div style={{ lineHeight: 1.8 }}>
        <div style={{ fontSize: 18, fontWeight: 700 }}>
          <InvoiceStatusIcon status={invoice.status} />
          <span style={{ paddingLeft: 12 }}>{invoice.customerName}</span>
        </div>
        <div style={{ paddingLeft: 45 }}>
          <FormattedAmount {...invoice.totalExVat} />
        </div>
        <div style={{ paddingLeft: 45 }}>
          <InvoiceStatusText invoice={invoice} />
        </div>
        <div style={{ paddingLeft: 45 }}>
          {`#${invoice.documentNumber}`}
        </div>
      </div>
    </Card>
  )
}

export default MobileInvoiceItem
