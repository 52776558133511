const tablePagination = ({ intl, collection, fetchMore, params, dataRef, history }) => {
  const onChange = (page, pageSize) => {
    if (!pageSize) {
      return
    }
    const skip = page * pageSize - pageSize
    params.set(`${dataRef}.skip`, skip)
    history.push({
      search: params.toString()
    })
    fetchMore({
      variables: {
        skip
      },
      updateQuery: (_previousResult, { fetchMoreResult }) => {
        return fetchMoreResult
      }
    })
  }

  const showTotal = (total, range) => {
    const from = intl.formatNumber(range[0])
    const to = intl.formatNumber(range[1])
    const totalFormatted = intl.formatNumber(total)

    return intl.formatMessage(
      {
        id: 'lib.pagination-helpers.showTotal',
        defaultMessage: '{from}-{to} of {total} items'
      },
      { from, to, total: totalFormatted }
    )
  }

  return {
    pageSize: collection.pageSize,
    total: collection.totalCount,
    current: collection.pageInfo.pageNumber,
    showTotal,
    onChange
  }
}

export { tablePagination }
