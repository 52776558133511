import { Form, Icon, Input } from 'antd'
import FancyButton from 'components/FancyButton/FancyButton'
import withFormDecorator from 'hocs/with-form-decorator'
import { compareToFirstPassword, validateToNextPassword } from 'lib/validators'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import messages from './messages'
import PasswordInput from 'components/PasswordInput/PasswordInput'

class ForgotPasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return

      this.props.onSubmit(values)
    })
  }

  render() {
    const { form, saving, intl } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Form.Item label={<FormattedMessage {...messages.newPasswordLabel} />}>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: <FormattedMessage {...messages.newPasswordRuleRequired} />
              },
              {
                validator: validateToNextPassword(form, 'password-confirm')
              }
            ]
          })(<PasswordInput autoComplete="new-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} prefix={<Icon type="lock" />} />)}
        </Form.Item>
        <Form.Item label={<FormattedMessage {...messages.repeatPasswordLabel} />}>
          {getFieldDecorator('passwordConfirm', {
            rules: [
              {
                required: true,
                message: <FormattedMessage {...messages.repeatPasswordRuleRequired} />
              },
              {
                validator: compareToFirstPassword(form, 'password')
              }
            ]
          })(<Input.Password autoComplete="new-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} prefix={<Icon type="lock" />} />)}
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <FancyButton type="primary" htmlType="submit" style={{ width: '100%' }} loading={saving}>
            <FormattedMessage key="submitButton" defaultMessage="Change password" />
          </FancyButton>
          <p style={{ marginTop: '20px' }}>
            <FormattedMessage key="loginCaption" defaultMessage="Or if you remember your password" />{' '}
            <Link to="/login">
              <FormattedMessage key="loginLink" defaultMessage="login" />
            </Link>
          </p>
        </Form.Item>
      </Form>
    )
  }
}

export default injectIntl(withFormDecorator(ForgotPasswordForm))
