import React from 'react'
import { Query } from 'react-apollo'
import getCustomerForEdit from './getCustomerForEdit.gql'
import { Spin } from 'antd'

import PageLayout from 'components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'
import { useParams } from 'react-router-dom'
import UpdateCustomer from 'components/UpdateCustomer/UpdateCustomer'

const messages = defineMessages({
  pageTitle: {
    id: 'App.CustomerEdit.pageTitle',
    defaultMessage: 'Edit Customer',
  },
})

const UserCustomerEditView = () => {
  const { id } = useParams()
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <Query query={getCustomerForEdit} variables={{ id }}>
        {({ loading, error, data = {} }) => {
          if (error)
            return <div style={{ color: 'red' }}>Error loading customer.</div>
          if (loading) return <Spin />
          const customer = data.customer

          return (
            <UpdateCustomer
              loading={loading}
              customerId={customer.id}
              customer={customer}
            />
          )
        }}
      </Query>
    </PageLayout>
  )
}

export default UserCustomerEditView
