import React, { Component } from 'react'
import { Input } from 'antd'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

export class SearchFilter extends Component {
  static propTypes = {
    setFilter: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    currentFilter: PropTypes.object,
    options: PropTypes.object
  }

  setSearchString = e => {
    const { value } = e.target
    const { setFilter, currentFilter } = this.props
    const newState = currentFilter

    if (value.length > 0) {
      newState.$ilike = `${value}%`
    } else {
      delete newState.$ilike
    }

    setFilter(newState)
  }

  render() {
    const { currentFilter, confirm, inputRef, intl } = this.props

    return (
      <div style={{ padding: '8px' }}>
        <Input
          ref={inputRef}
          placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
          value={(currentFilter.$ilike || '').replace('%', '')}
          onChange={this.setSearchString}
          onPressEnter={confirm}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
      </div>
    )
  }
}

export default injectIntl(SearchFilter)
