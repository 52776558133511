import React from 'react'
import { Typography } from 'antd'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsTestInfo.pageTitle',
    defaultMessage: 'Settings - Test info'
  }
})

const { Paragraph, Title } = Typography

const UserSettingsView = () => {
  const token = localStorage.getItem('token')
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="test">
        <div>
          <Title level={4}>Header for testing graphql request.</Title>
          <Paragraph copyable>
            {`
          { "authorization": "Bearer ${token}"}
        `}
          </Paragraph>
          <Title level={4}>Latest release</Title>
          <Paragraph copyable>{process.env.REACT_APP_VERSION || 'REACT_APP_VERSION not set'}</Paragraph>
        </div>
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
