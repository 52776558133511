import React from 'react'
import { Table, Checkbox } from 'antd'
import { FormattedMessage } from 'react-intl'
import { FormattedAmount } from 'lib/locale'
import RotVariantSelect from '../RotVariantSelect/RotVariantSelect'
import getRotRutDeductable from 'lib/getRotRutDeductable'

const Footer = ({ invoiceRows }) => {
  const sum = invoiceRows.reduce((sum, row) => {
    return sum + getRotRutDeductable(row)
  }, 0)

  return (
    <>
      <span
        style={{
          fontSize: 18,
        }}
      >
        <FormattedMessage
          id="RotTable.Footer.sumTitle"
          defaultMessage="ROT Sum"
        />
      </span>
      <strong style={{ float: 'right', fontSize: 18 }}>
        <FormattedAmount valueInCent={sum} currency="SEK" />
      </strong>
    </>
  )
}

class RotTable extends React.Component {
  onChangeValue = (index, key, value) => {
    const { invoiceRows } = this.props
    invoiceRows[index][key] = value
    this.props.setInvoiceRows(invoiceRows)
  }

  render() {
    const { invoiceRows, disabled } = this.props
    const dataSource = invoiceRows.map((item, id) => {
      return {
        id,
        key: id,
        ...item,
      }
    })

    const columns = [
      {
        title: (
          <FormattedMessage id="RotTable.Header.rot" defaultMessage="ROT/RUT" />
        ),
        dataIndex: 'rot',
        key: 'rot',
        render: (rot, row, index) => (
          <Checkbox
            disabled={disabled}
            checked={Boolean(rot)}
            onChange={() => {
              this.onChangeValue(index, 'rot', !rot)
              if (!row.rotType) {
                this.onChangeValue(index, 'rotType', 'CONSTRUCTION')
              }
              if (!row.rotHours) {
                this.onChangeValue(index, 'rotHours', row.count)
              }
            }}
          />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="RotTable.Header.description"
            defaultMessage="Description"
          />
        ),
        dataIndex: 'description',
        key: 'description',
      },
      {
        title: (
          <FormattedMessage id="RotTable.Header.hours" defaultMessage="Hours" />
        ),
        dataIndex: 'rotHours',
        key: 'rotHours',
        render: (rotHours, row, index) => {
          if (!row.rot) return null
          return rotHours
          // return (
          //   <InputNumber
          //     value={rotHours}
          //     min={1}
          //     max={1000}
          //     step={1}
          //     pattern="/\d+/"
          //     style={{ width: 80 }}
          //     onChange={(newVal) => {
          //       this.onChangeValue(index, 'rotHours', newVal)
          //     }}
          //   />
          // )
        },
      },
      {
        title: (
          <FormattedMessage
            id="RotTable.Header.rotType"
            defaultMessage="ROT Type"
          />
        ),
        dataIndex: 'rotType',
        key: 'rotType',
        render: (rotType, row, index) =>
          row.rot && (
            <RotVariantSelect
              value={rotType}
              onChange={(newVal) => {
                this.onChangeValue(index, 'rotType', newVal)
              }}
            />
          ),
      },
      {
        title: (
          <FormattedMessage
            id="RotTable.Header.amountBeforeRot"
            defaultMessage="Amount (before Rot)"
          />
        ),
        dataIndex: 'amountExVat',
        key: 'amountExVat',
        align: 'right',
        render: (amountExVat, row) => (
          <FormattedAmount valueInCent={row.amountExVat * row.count} />
        ),
      },
      {
        title: (
          <FormattedMessage
            id="RotTable.Header.rotAmount"
            defaultMessage="ROT amount"
          />
        ),
        dataIndex: 'amountExVat',
        key: 'amount',
        align: 'right',
        render: (_, row) =>
          row.rot && (
            <FormattedAmount
              valueInCent={getRotRutDeductable(row)}
              currency="SEK"
            />
          ),
      },
    ]

    return (
      <Table
        dataSource={dataSource}
        columns={columns}
        style={{ opacity: disabled ? 0.4 : 1 }}
        footer={() => <Footer invoiceRows={invoiceRows} />}
        pagination={false}
      />
    )
  }
}

export default RotTable
