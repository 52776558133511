import { defineMessages } from 'react-intl'

export default defineMessages({
  youTitle: 'About you',
  bankTaxTitle: 'Bank & Taxes',
  contactTitle: 'Contact information',
  taxTable: 'Tax Table',
  taxTablePlaceholder: '33',
  taxTableRequired: 'Tax table is required',
  personalIdentityNumber: 'Personnr',
  personalIdentityNumberPlaceholder: 'yyyymmddxxxx',
  personalIdentityNumberDisabledText:
    'Personal identity number can\'t be changed',
  personalIdentityNumberRequired: 'Personal identity number is required',
  personalIdentityNumberInvalid:
    'Make sure it follows pattern YYYYMMDDNNNN and that it is not fake.',
  email: 'Email',
  emailRequired: 'Email is required',
  emailInvalid: 'Email does not look valid',
  emailPlaceholder: 'yourname@domain.se',
  emailDisabledText: 'email can be changed in the email menu',
  firstName: 'First name',
  firstNamePlaceholder: 'Maja',
  firstNameRequired: 'First name is required',
  lastName: 'Last name',
  lastNamePlaceholder: 'Karlsson',
  lastNameRequired: 'Last name is required',

  address: 'Adress',
  addressPlaceholder: 'Storgatan 1',
  zipCode: 'Postnummer',
  zipCodePlaceholder: '11234',
  city: 'Ort',
  cityPlaceholder: 'Stockholm',
  country: 'Land',
  countryPlaceholder: 'Sverige',
  phone: 'Telefon',
  phonePlaceholder: '07012345678',
  clearingNo: 'Bank - Clearingnummer',
  clearingNoPlaceholder: 'xxxx',
  clearingNoRequired: 'Clearing number is required',
  clearingNoInvalid: 'Clearing number is invalid',
  bankAccountNo: 'Bank - Kontonummer',
  bankAccountNoPlaceholder: 'xxxxxxx',
  bankAccountNoRequired: 'Bank account numer is required',

  submit: 'Spara',
})
