import React, { Component } from 'react'
import { Mutation } from 'react-apollo'
import bankidAuth from './bankid-auth.gql'

import BankidSignForm from './form'
import BankidPoller from './BankidPoller'
import { message } from 'antd'
class BankidSign extends Component {
  state = { orderRef: null }

  render() {
    const { onCompleted, loginUi } = this.props
    const { orderRef } = this.state

    if (orderRef) return <BankidPoller onCompleted={onCompleted} orderRef={orderRef} onRetry={() => this.setState({ orderRef: null })} />

    return (
      <Mutation
        mutation={bankidAuth}
        onCompleted={data => {
          const { orderRef, error } = data.bankidAuth
          if (error) {
            message.error(error.message)
          } else {
            this.setState({ orderRef })
          }
        }}
      >
        {(mutate, { loading }) => (
          <BankidSignForm
            loginUi={loginUi}
            onSubmit={({ personalIdentityNumber }) => {
              mutate({
                variables: {
                  personalIdentityNumber
                }
              })
            }}
            loading={loading}
          />
        )}
      </Mutation>
    )
  }
}

export default BankidSign
