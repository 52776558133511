import React from 'react'
import { Spin } from 'antd'
import { Query } from 'react-apollo'
import getFaqItems from './getFaqItems.gql'
import { Collapse } from 'antd'
const { Panel } = Collapse

const FaqItem = ({ item }) => (
  <div>
    <p>
      <strong>{item.question}</strong>
    </p>
    <div dangerouslySetInnerHTML={{ __html: item.answer }} />
  </div>
)

const FaqWidget = ({ faqGroups, loading }) => {
  if (loading) return <Spin />

  if (!Array.isArray(faqGroups)) return null
  return (
    <Collapse bordered={false} defaultActiveKey={['1']}>
      {faqGroups.map(faqGroup => (
        <Panel header={faqGroup.title} key={faqGroup.id}>
          {faqGroup.items.map(item => (
            <FaqItem item={item} key={item.id} />
          ))}
        </Panel>
      ))}
    </Collapse>
  )
}

const FaqWidgetWithData = () => (
  <Query query={getFaqItems}>
    {({ loading, error, data = {} }) => {
      if (error) return <div style={{ color: 'red' }}>Error loading FAQ.</div>
      return <FaqWidget faqGroups={data.faqGroups} loading={loading} />
    }}
  </Query>
)

export default FaqWidgetWithData
