import { Alert, Button } from 'antd'
import React from 'react'
import { withAuth } from 'services/auth'

const AdminGhostBar = ({ isGhost, logout }) => {
  if (!isGhost || !isGhost()) return null

  return (
    <Alert
      message={
        <>
          <span
            style={{
              marginRight: '5px'
            }}
          >
            You're currently logged in as a user via your admin account.
          </span>
          <Button type="link" size="small" style={{ padding: 0 }} onClick={logout}>
            Logout
          </Button>
        </>
      }
      type="error"
      banner
    />
  )
}

export default withAuth(AdminGhostBar)
