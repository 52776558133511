import React from 'react'
import { Input } from 'antd'
import { defineMessages, injectIntl } from 'react-intl'
import BankidLogo from '../BankidLogo/BankidLogo'

const messages = defineMessages({
  placeholder: {
    id: 'BankidInput.placeholder',
    defaultMessage: 'Personnummer'
  }
})

const BankidInput = ({ intl, size = 'large', disabled = false, placeholder,  ...props }) => {
  const placeholderStr = placeholder || intl.formatMessage(messages.placeholder)

  return (
    <Input 
      disabled={disabled}
      inputMode='numeric'
      size={size}
      placeholder={placeholderStr}
      suffix={<BankidLogo />}
      {...props}
    />
  )
}
export default injectIntl(BankidInput)