import React from 'react'
import moment from 'moment'
import { Steps } from 'antd'
import { FormattedMessage } from 'react-intl'
import './InvoiceTimeline.css'

const { Step } = Steps

function getCurrentStep(invoice) {
  const { status } = invoice
  if (status === 'draft') return 0
  if (status === 'readyForApproval') return 1
  if (status === 'approved') return 1
  if (status === 'invoicePaid') return 2
  if (status === 'credited') return 2
  if (status === 'notApproved') return 2
  return 0
}

export default function InvoiceTimeline({ invoice }) {
  const { status } = invoice
  const current = getCurrentStep(invoice)

  const showAllSteps = status !== 'credited' && status !== 'notApproved'

  return (
    <Steps current={current} className="invoice-timeline">
      <Step 
        title={
          <FormattedMessage key="InvoiceTimeline.created" defaultMessage="Invoice created" />
        } 
        description={moment(invoice.createdAt).format('YYYY-MM-DD')} />
      {status === 'readyForApproval' ? (
        <Step 
          title={
            <FormattedMessage key="InvoiceTimeline.sentForApproval" defaultMessage="Being reviewed" />
          }
          description={
            <FormattedMessage key="InvoiceTimeline.sentForApprovalDescription" defaultMessage="It will be sent soon" />
          } />
      ) : (
        <Step 
          title={
            <FormattedMessage key="InvoiceTimeline.sent" defaultMessage="Sent" />
          }
          description={invoice.invoiceDate} />
      )}

      {status === 'credited' && <Step title={<FormattedMessage key="InvoiceTimeline.credited" defaultMessage="Credited" />} />}

      {status === 'notApproved' && <Step 
        title={
          <FormattedMessage key="InvoiceTimeline.notApproved" defaultMessage="Not Approved" />
        }
        description={
          <FormattedMessage key="InvoiceTimeline.notApprovedDescription" defaultMessage="Contact us for details" />
        } />}

      {showAllSteps && 
        <Step 
          title={
            <FormattedMessage key="InvoiceTimeline.paid" defaultMessage="Paid by customer" />
          }
          description={
            <FormattedMessage key="InvoiceTimeline.paidDescription" defaultMessage="Due {dueDate}" values={{ dueDate: invoice.dueDate }} />
          }
        />
      }
      {showAllSteps && (
        <Step
          title={
            <FormattedMessage key="InvoiceTimeline.salaryPaid" defaultMessage="Salary paid" />
          }
          description={moment(invoice.dueDate)
            .add(2, 'days')
            .format('YYYY-MM-DD')}
        />
      )}
    </Steps>
  )
}
