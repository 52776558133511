import React from 'react'
import InvoiceTable from '../../../components/InvoiceTable/InvoiceTable'
import PageLayout from '../../../components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.InvoicesView.pageTitle',
    defaultMessage: 'Invoices'
  }
})

const UserInvoicesView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <InvoiceTable />
    </PageLayout>
  )
}

export default UserInvoicesView
