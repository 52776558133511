import React, { Component } from 'react'
import { Form, Input, Typography, Row, Col, Select } from 'antd'
import personnummer from 'personnummer'

import { FormattedMessage, injectIntl } from 'react-intl'
import withFormDecorator from 'hocs/with-form-decorator'
import messages from './messages'
import countryList from 'country-list'
import ClearingnrInput from '../ClearingnrInput/ClearingnrInput'
import FancyButton from '../FancyButton/FancyButton'

const { Option } = Select
const countryNames = countryList.getNames()
const { Title } = Typography
// const skattetabeller = [29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40]

const clearingFormat = new RegExp('^[0-9]{4,5}$')

class _EmployeeForm extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return
      const input = {
        ...values,
        taxTable: parseInt(values.taxTable, 10),
      }
      this.props.onSubmit(input)
    })
  }

  render() {
    const { form, saving, intl, user = {} } = this.props
    const { getFieldDecorator } = form
    const {
      personalIdentityNumber,
      givenName,
      surname,
      bankIdConfirmed,
      email,
    } = user
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical" hideRequiredMark>
        <Title level={3}>
          <FormattedMessage {...messages.youTitle} />
        </Title>
        <Form.Item
          label={<FormattedMessage {...messages.personalIdentityNumber} />}
        >
          {getFieldDecorator('personalIdentityNumber', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    {...messages.personalIdentityNumberRequired}
                  />
                ),
              },
              { transform: (value) => value && value.trim() },
              {
                validator: (_rule, value) => personnummer.valid(value),
                message: (
                  <FormattedMessage
                    {...messages.personalIdentityNumberInvalid}
                  />
                ),
              },
            ],
            initialValue: personalIdentityNumber || '',
          })(
            <Input
              inputMode="numeric"
              readOnly={Boolean(personalIdentityNumber)}
              placeholder={intl.formatMessage(
                messages.personalIdentityNumberPlaceholder
              )}
              disabled={Boolean(personalIdentityNumber)}
            />
          )}
          {Boolean(personalIdentityNumber) && (
            <Typography.Text type="secondary">
              <FormattedMessage
                {...messages.personalIdentityNumberDisabledText}
              />
            </Typography.Text>
          )}
        </Form.Item>

        <Form.Item label={<FormattedMessage {...messages.email} />}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: <FormattedMessage {...messages.emailRequired} />,
              },
              { transform: (value) => value && value.trim() },
            ],
            initialValue: email || '',
          })(
            <Input
              type="email"
              readOnly={Boolean(email)}
              placeholder={intl.formatMessage(messages.emailPlaceholder)}
              disabled={Boolean(email)}
            />
          )}
          {Boolean(email) && (
            <Typography.Text type="secondary">
              <FormattedMessage {...messages.emailDisabledText} />
            </Typography.Text>
          )}
        </Form.Item>

        <Row gutter={24}>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.firstName} />}>
              {getFieldDecorator('firstName', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage {...messages.firstNameRequired} />
                    ),
                  },
                ],
                initialValue: givenName || '',
              })(
                <Input
                  placeholder={intl.formatMessage(
                    messages.firstNamePlaceholder
                  )}
                  disabled={bankIdConfirmed}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.lastName} />}>
              {getFieldDecorator('lastName', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage {...messages.lastNameRequired} />
                    ),
                  },
                ],
                initialValue: surname || '',
              })(
                <Input
                  placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                  disabled={bankIdConfirmed}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>
          <FormattedMessage {...messages.bankTaxTitle} />
        </Title>

        <Row gutter={24}>
          <Col md={8}>
            <Form.Item label={<FormattedMessage {...messages.clearingNo} />}>
              {getFieldDecorator('clearingNo', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage {...messages.clearingNoRequired} />
                    ),
                  },
                  {
                    validator: (_rule, value) => clearingFormat.test(value),
                    message: (
                      <FormattedMessage {...messages.clearingNoInvalid} />
                    ),
                  },
                ],
              })(
                <ClearingnrInput
                  placeholder={intl.formatMessage(
                    messages.clearingNoPlaceholder
                  )}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={16}>
            <Form.Item label={<FormattedMessage {...messages.bankAccountNo} />}>
              {getFieldDecorator('bankAccountNo', {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage {...messages.bankAccountNoRequired} />
                    ),
                  },
                ],
              })(
                <Input
                  placeholder={intl.formatMessage(
                    messages.bankAccountNoPlaceholder
                  )}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        {/* After some discussion we do only 30% tax for everybody. Leaving this in case we want to go back */}
        {/* <Form.Item label={<FormattedMessage {...messages.taxTable} />}>
          {getFieldDecorator('taxTable', {
            rules: [
              {
                required: true,
                message: <FormattedMessage {...messages.taxTableRequired} />,
              },
            ],
            initialValue: 31,
          })(
            <Select
              placeholder={intl.formatMessage(messages.taxTablePlaceholder)}
            >
              {skattetabeller.map((skattetabell) => (
                <Option key={skattetabell} value={skattetabell}>
                  {skattetabell}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item> */}

        <Title level={3}>
          <FormattedMessage {...messages.contactTitle} />
        </Title>

        <Form.Item label={<FormattedMessage {...messages.phone} />}>
          {getFieldDecorator('phone')(
            <Input
              type="tel"
              placeholder={intl.formatMessage(messages.phonePlaceholder)}
            />
          )}
        </Form.Item>

        <Form.Item label={<FormattedMessage {...messages.address} />}>
          {getFieldDecorator('address', {
            rules: [{ required: true }],
          })(
            <Input
              placeholder={intl.formatMessage(messages.addressPlaceholder)}
            />
          )}
        </Form.Item>

        <Row gutter={24}>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.zipCode} />}>
              {getFieldDecorator('zipCode', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={intl.formatMessage(messages.zipCodePlaceholder)}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item label={<FormattedMessage {...messages.city} />}>
              {getFieldDecorator('city', {
                rules: [{ required: true }],
              })(
                <Input
                  placeholder={intl.formatMessage(messages.cityPlaceholder)}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label={<FormattedMessage {...messages.country} />}>
          {getFieldDecorator('country', {
            initialValue: 'Sweden',
          })(
            <Select
              showSearch
              placeholder={intl.formatMessage(messages.countryPlaceholder)}
              filterOption
            >
              {countryNames.map((country) => (
                <Option key={country} value={country}>
                  {country}
                </Option>
              ))}
            </Select>
          )}
        </Form.Item>

        <Form.Item>
          <FancyButton type="submit" variant="cta" loading={saving}>
            <FormattedMessage {...messages.submit} />
          </FancyButton>
        </Form.Item>
      </Form>
    )
  }
}

const EmployeeFormWithDecorator = withFormDecorator(injectIntl(_EmployeeForm))

export default EmployeeFormWithDecorator
