import React from 'react'

const percentages = {
  _6: '6%',
  _12: '12%',
  _25: '25%',
  _0: '0%',
}

const VatDisplay = ({ value }) => {
  return <span>{percentages[value]}</span>
}

export default VatDisplay
