import React from 'react'
import { useIntl } from 'react-intl'
import InvoiceForm from './form'
import createInvoice from './createInvoice.gql'
import listInvoices from '../InvoiceTable/listInvoices.gql'
import getInvoicesForWidget from 'components/InvoicesWidget/getInvoicesForWidget.gql'
import { Mutation } from 'react-apollo'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import useEmployee from 'hooks/useEmployee'

const InvoiceFormWithMutation = ({ invoice }) => {
  const intl = useIntl()
  const employee = useEmployee()
  const history = useHistory()

  return (
    <Mutation
      mutation={createInvoice}
      awaitRefetchQueries={true}
      refetchQueries={[
        { query: listInvoices },
        { query: getInvoicesForWidget },
      ]}
      onCompleted={(res) => {
        message.info(
          intl.formatMessage({
            id: 'components.InvoiceForm.successMessage',
            defaultMessage: 'Invoice has been created!',
          })
        )
        history.push('/invoices')
      }}
    >
      {(mutate, { loading }) => (
        <InvoiceForm
          readyToInvoice={employee && employee.readyToInvoice}
          invoice={invoice}
          loading={loading}
          onSave={(input) => {
            mutate({ variables: { input, send: false } })
          }}
          onSend={(input) => {
            mutate({ variables: { input, send: true } })
          }}
        />
      )}
    </Mutation>
  )
}

export default InvoiceFormWithMutation
