import React from 'react'
import { Form, Input, InputNumber, DatePicker } from 'antd'
import { FormattedMessage } from 'react-intl'
import FancyButton from '../FancyButton/FancyButton'
import CountrySelect from '../CountrySelect/CountrySelect'
import moment from 'moment'

const { RangePicker } = DatePicker

class TractamentForm extends React.Component {
  handleSubmit = e => {
    const { form, onSubmit } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {
          dates,
          country,
          numberOfBreakfasts,
          numberOfLunches,
          numberOfDinners,
          description
        } = values

        const input = {
          country,
          description,
          numberOfBreakfasts,
          numberOfLunches,
          numberOfDinners,

          dateFrom: moment(dates[0]).toISOString(true),
          dateTo: moment(dates[1]).toISOString(true)
        }
        onSubmit(input)
        form.resetFields()
      }
    })
  }

  state = { maxNumberOfDays: 0, country: 'Sverige' }

  onChangeDates = dateMoments => {
    const [dateFromMoment, dateToMoment] = dateMoments

    const fromHour = moment(dateFromMoment).format('HH')
    const toHour = moment(dateToMoment).format('HH')

    const numberOfHalfDays = (fromHour > 12 ? 1 : 0) + (toHour < 19 ? 1 : 0)

    const numberOfDays =
      moment(dateToMoment).diff(moment(dateFromMoment), 'day') +
      1 -
      numberOfHalfDays
    const maxNumberOfDays = numberOfDays + numberOfHalfDays

    this.props.form.setFieldsValue({
      numberOfDays,
      numberOfHalfDays
    })

    this.setState({ maxNumberOfDays })
  }

  onChangeCountry = country => {
    this.setState({ country })
  }

  render() {
    const { form } = this.props
    const { maxNumberOfDays } = this.state
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.countryLabel"
              defaultMessage="Country"
            />
          }
        >
          {getFieldDecorator('country', {
            initialValue: 'Sverige',
            onChange: this.onChangeCountry,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.countryRequired"
                    defaultMessage="Country is required"
                  />
                )
              }
            ]
          })(<CountrySelect />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.descriptionLabel"
              defaultMessage="Description"
            />
          }
        >
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.descriptionRequired"
                    defaultMessage="Description is required"
                  />
                )
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.datesLabel"
              defaultMessage="Dates"
            />
          }
        >
          {getFieldDecorator('dates', {
            onChange: this.onChangeDates,
            initialValue: [
              moment()
                .subtract(14, 'days')
                .hour(12)
                .minutes(0)
                .seconds(0),
              moment()
                .subtract(7, 'days')
                .hour(12)
                .minutes(0)
                .seconds(0)
            ],
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.datesRequired"
                    defaultMessage="Dates are required"
                  />
                )
              }
            ]
          })(<RangePicker showTime format="YYYY-MM-DD HH:mm" />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.numberOfDaysLabel"
              defaultMessage="Number of days"
            />
          }
        >
          {getFieldDecorator('numberOfDays', {
            initialValue: 7
          })(<InputNumber min={0} readOnly disabled />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.numberOfHalfDaysLabel"
              defaultMessage="Number of half days"
            />
          }
        >
          {getFieldDecorator('numberOfHalfDays', {
            initialValue: 1
          })(<InputNumber min={0} readOnly disabled />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.numberOfDaysBreakfastLabel"
              defaultMessage="Number of breakfasts"
            />
          }
        >
          {getFieldDecorator('numberOfBreakfasts', {
            initialValue: 0,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.numberOfDaysBreakfastRequired"
                    defaultMessage="Number of breakfasts is required"
                  />
                )
              }
            ]
          })(<InputNumber step={1} min={0} max={maxNumberOfDays || 100} />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.numberOfDaysLunchLabel"
              defaultMessage="Number of lunches"
            />
          }
        >
          {getFieldDecorator('numberOfLunches', {
            initialValue: 0,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.numberOfDaysLunchRequired"
                    defaultMessage="Number of lunches is required"
                  />
                )
              }
            ]
          })(<InputNumber step={1} min={0} max={maxNumberOfDays || 100} />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TractamentForm.Form.numberOfDaysDinnerLabel"
              defaultMessage="Number of dinners"
            />
          }
        >
          {getFieldDecorator('numberOfDinners', {
            initialValue: 0,
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TractamentForm.Form.numberOfDaysDinnerRequired"
                    defaultMessage="Number of dinners is required"
                  />
                )
              }
            ]
          })(<InputNumber step={1} min={0} max={maxNumberOfDays || 100} />)}
        </Form.Item>

        <Form.Item style={{ marginTop: 24 }}>
          <FancyButton variant="cta" type="submit">
            <FormattedMessage
              id="TractamentForm.Form.cta"
              defaultMessage="Add Travel Expense"
            />
          </FancyButton>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedTractamentForm = Form.create({ name: 'TractamentForm' })(
  TractamentForm
)

export default WrappedTractamentForm
