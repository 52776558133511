import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Divider } from 'antd'
import InvoiceAddonTwoColumn from '../InvoiceAddonTwoColumn/InvoiceAddonTwoColumn'
import RotTable from '../RotTable/RotTable'
import Form from './form'

const RotForm = ({ invoiceRows, rot, onRotChange, setInvoiceRows }) => {
  const [valid, setValid] = useState(false)
  const rightColumn = () => (
    <>
      <h3>
        <FormattedMessage
          key="RotForm.intoTitle"
          defaultMessage="About ROT/RUT"
        />
      </h3>
      <p>
        <FormattedMessage
          key="RotForm.infoText1"
          defaultMessage="Rotavdraget kan max bli 30% av arbetskostnaden och Rutavdraget kan maximalt bli 50% av arbetskostnaden, oavsett köparens ålder."
        />
      </p>
      <p>
        <FormattedMessage
          key="RotForm.infoText2"
          defaultMessage="Rot- och Rutavdraget är sammanlagt högst 50 000 kronor per person och år."
        />
      </p>
      <p>
        <a
          href="https://konsultfaktura.zendesk.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            key="RotForm.infoTextCta"
            defaultMessage="Read more about ROT/RUT."
          />
        </a>
      </p>
    </>
  )

  return (
    <>
      <InvoiceAddonTwoColumn rightColumn={rightColumn()}>
        <h3>
          <FormattedMessage key="RotForm.title" defaultMessage="ROT" />
        </h3>
        <div>
          <Form rot={rot} onRotChange={onRotChange} setValid={setValid} />
        </div>
      </InvoiceAddonTwoColumn>
      <div style={{ overflowX: 'scroll' }}>
        <RotTable
          disabled={!valid}
          invoiceRows={invoiceRows}
          setInvoiceRows={setInvoiceRows}
        />
      </div>

      <Divider style={{ marginTop: 40, marginBottom: 70 }} />
    </>
  )
}

export default RotForm
