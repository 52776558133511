import React from 'react'
import { Button, Form, Input, Select, Typography } from 'antd'
import styles from './CustomerForm.module.css'
import { defineMessages, FormattedMessage } from 'react-intl'
import FancyButton from '../FancyButton/FancyButton'

const { Option } = Select
const { Title } = Typography

const messages = defineMessages({
  customerDetails: {
    id: 'CustomerForm.customerDetails',
    defaultMessage: 'Customer Details',
  },
  name: {
    id: 'CustomerForm.name',
    defaultMessage: 'Name',
  },
  nameRequired: {
    id: 'CustomerForm.nameRequired',
    defaultMessage: 'Please fill in a name',
  },
  email: {
    id: 'CustomerForm.email',
    defaultMessage: 'E-mail',
  },
  emailRequired: {
    id: 'CustomerForm.emailRequired',
    defaultMessage: 'Please fill in an email',
  },
  emailInvalid: {
    id: 'CustomerForm.emailInvalid',
    defaultMessage: 'The input is not valid E-mail!',
  },
  organizationNumber: {
    id: 'CustomerForm.organizationNumber',
    defaultMessage: 'Organization Number',
  },
  organizationNumberRequired: {
    id: 'CustomerForm.organizationNumberRequired',
    defaultMessage: 'Organization Number is required',
  },
  personalIdentityNumber: {
    id: 'CustomerForm.personalIdentityNumber',
    defaultMessage: 'Personal Identity Number',
  },
  personalIdentityNumberRequired: {
    id: 'CustomerForm.personalIdentityNumberRequired',
    defaultMessage: 'Personal Identity Number is required',
  },
  yourReference: {
    id: 'CustomerForm.yourReference',
    defaultMessage: 'Your reference',
  },
  phone: {
    id: 'CustomerForm.phone',
    defaultMessage: 'Phone',
  },
  type: {
    id: 'CustomerForm.type',
    defaultMessage: 'Type',
  },
  typeRequired: {
    id: 'CustomerForm.typeRequired',
    defaultMessage: 'Please fill in a type',
  },
  company: {
    id: 'CustomerForm.company',
    defaultMessage: 'Company',
  },
  private: {
    id: 'CustomerForm.private',
    defaultMessage: 'Private',
  },
  address: {
    id: 'CustomerForm.address',
    defaultMessage: 'Address',
  },
  zipCode: {
    id: 'CustomerForm.zipCode',
    defaultMessage: 'Zip Code',
  },
  city: {
    id: 'CustomerForm.city',
    defaultMessage: 'City',
  },
  cancel: {
    id: 'CustomerForm.cancel',
    defaultMessage: 'Cancel',
  },

  hideAllFields: {
    id: 'CustomerForm.hideAllFields',
    defaultMessage: 'Hide additional fields',
  },
  save: {
    id: 'CustomerForm.save',
    defaultMessage: 'Save Customer',
  },
})

class CustomerForm extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.props.onSubmit(values)
      }
    })
  }

  componentDidMount() {
    const customer = this.props.customer
    if (customer) {
      console.log(customer)
      this.props.form.setFieldsValue({
        customerType: customer.customerType,
        name: customer.name,
        email: customer.email,
        organisationNumber: customer.organisationNumber,
        yourReference: customer.yourReference,
        phone: customer.phone,
        address: customer.address,
        zipCode: customer.zipCode,
        city: customer.city,
      })
    }
  }

  render() {
    const { onCancel, form, loading } = this.props
    const { getFieldDecorator, getFieldValue } = form

    const isCompany = getFieldValue('customerType') === 'COMPANY'

    return (
      <div className={styles.customerForm}>
        <Title level={4}>
          <FormattedMessage {...messages.customerDetails} />
        </Title>
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label={<FormattedMessage {...messages.type} />}>
            {getFieldDecorator('customerType', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage {...messages.typeRequired} />,
                },
              ],
            })(
              <Select>
                <Option value="COMPANY">
                  <FormattedMessage {...messages.company} />
                </Option>
                <Option value="PRIVATE">
                  <FormattedMessage {...messages.private} />
                </Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label={<FormattedMessage {...messages.name} />}>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: <FormattedMessage {...messages.nameRequired} />,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.email} />}>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: <FormattedMessage {...messages.emailInvalid} />,
                },
                {
                  required: true,
                  message: <FormattedMessage {...messages.emailRequired} />,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item
            label={
              <FormattedMessage
                {...messages[
                  isCompany ? 'organizationNumber' : 'personalIdentityNumber'
                ]}
              />
            }
          >
            {getFieldDecorator('organisationNumber', {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      {...messages[
                        isCompany
                          ? 'organizationNumberRequired'
                          : 'personalIdentityNumberRequired'
                      ]}
                    />
                  ),
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.yourReference} />}>
            {getFieldDecorator('yourReference')(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.phone} />}>
            {getFieldDecorator('phone')(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.address} />}>
            {getFieldDecorator('address')(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.zipCode} />}>
            {getFieldDecorator('zipCode', {
              rules: [
                {
                  max: 5,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item label={<FormattedMessage {...messages.city} />}>
            {getFieldDecorator('city')(<Input />)}
          </Form.Item>

          <Form.Item>
            <FancyButton loading={loading} variant="cta" type="submit" block>
              <FormattedMessage {...messages.save} />
            </FancyButton>
            <Button
              style={{ color: 'black', marginTop: -5 }}
              disabled={loading}
              type="link"
              onClick={onCancel}
              block
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const WrappedCustomerForm = Form.create({ name: 'customerForm' })(CustomerForm)

export default WrappedCustomerForm
