import React from 'react'
import { Query } from 'react-apollo'
import BANKID_COLLECT from './bankid-collect.gql'
import BankidMessage from './BankidMessage'

const BankidPollerWithData = ({ orderRef, onCompleted, onRetry }) => {
  return (
    <Query query={BANKID_COLLECT} variables={{ orderRef }} pollInterval={500}>
      {({ loading, error, data = {}, stopPolling }) => {
        // if (loading) {
        //   console.log('polling')
        // }
        if (error) return `Error! ${error}`

        const { bankidCollect = {} } = data
        const { status = 'pending', hintCode, errorCode, login } = bankidCollect

        if (status !== 'pending') {
          stopPolling()
        }

        if (status === 'complete') {
          onCompleted(login)
        }

        return <BankidMessage status={status} hintCode={hintCode} errorCode={errorCode} handleRetry={onRetry} />
      }}
    </Query>
  )
}

export default BankidPollerWithData
