import React, { useState, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { PropTypes } from 'prop-types'
import fileUploadTokenMutation from './fileUploadTokenMutation.gql'
import GcsUpload from 'gcs-browser-upload'

const FileUpload = React.forwardRef((props, ref) => {
  const apolloClient = useApolloClient()
  const [uploadState, setUploadState] = useState({})
  const [fileList, setFileList] = useState([])
  const initialRender = useRef(true)

  const behaveAsArray = Array.isArray(props.value)
  const filesAsArray = behaveAsArray ? props.value : [props.value]

  // Triggered on first mounted. Sets up the file list for edit.
  useEffect(() => {
    // Only run on first render
    if (!initialRender.current) return 

    initialRender.current = false

    const defaultFileList = filesAsArray
      .map(file => {
        if (!file) {
          return null
        }
        return {
          key: file.id,
          uid: file.id,
          name: file.id,
          status: 'done',
          url: file.thumbnailUrl
        }
      })
      .filter(Boolean)
    setFileList(defaultFileList)
  }, [fileList, filesAsArray])
  

  // Whenever value changes propagate the correct value for the form to save
  useEffect(() => {
    if (props.value === undefined) {
      setFileList([])
    }
    if (props.value && props.value.id) {
      // Propagate the uuid as value for this field
      props.onChange(props.value.id)
    }
    if (props.value && Array.isArray(props.value) && props.value.some(val => val.id)) {
      // Propagate the uuid as value for this field
      props.onChange(props.value.map(value => value.id))
    }
  }, [props, props.value, uploadState])

  const uploadFile = async ({ file, onProgress, onSuccess, onError }) => {
    const input = {
      lastModified: parseInt(file.lastModified),
      name: file.name,
      size: file.size,
      type: file.type
    }

    const {
      data: {
        fileUploadToken: { uploadUrl, jwtToken }
      }
    } = await apolloClient.mutate({
      mutation: fileUploadTokenMutation,
      variables: { input }
    })

    const upload = new GcsUpload({
      id: file.uid,
      url: uploadUrl,
      file: file,
      onChunkUpload: info => {
        onProgress({ percent: (info.uploadedBytes / info.totalBytes) * 100 })
      }
    })

    let newUploadState = uploadState
    newUploadState[file.uid] = upload
    setUploadState(newUploadState)

    try {
      await upload.start()
    } catch (e) {
      onError()
    } finally {
      onSuccess(jwtToken)
    }
  }

  const onChange = ({ fileList }) => {
    // [...fileList] creates a new array so react sees it as a change to rerender
    setFileList(props.multiple ? [...fileList] : [fileList[fileList.length -1]])
    const changeValues = fileList.map(file => {
      if (file && file.response) {
        // Set jwt token if newly uploaded
        return file.response
      } else if (file && file.status === 'done') {
        // Set uuid of file if old attachment
        return file.uid
      } else if (file && file.status === 'uploading') {
        return 'uploading'
      } else if (file && file.status === 'error') {
        return 'error'
      } else {
        return null
      }
    })
    
    const newValue = behaveAsArray ? changeValues : changeValues[0]
    if (newValue !== props.value) {
      props.onChange(newValue)
    }
  }

  const customRequest = ({ file, onError, onSuccess, onProgress }) => {
    uploadFile({ file, onError, onSuccess, onProgress })

    return {
      abort() {
        uploadState[file.uid] && uploadState[file.uid].cancel()
      }
    }
  }
  
  return (
    <div style={{ marginBottom: '20px' }}>
      {props.children({
        multiple: props.multiple,
        customRequest,
        fileList,
        onChange
      })}
    </div>
  )
})

FileUpload.propTypes = {
  multiple: PropTypes.bool
}

FileUpload.defaultProps = {
  multiple: false
}

export default FileUpload
