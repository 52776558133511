import React from 'react'
import { Select } from 'antd'
import { FormattedMessage } from 'react-intl'

import isRotOrRut from '../../lib/isRotOrRut'
const { Option } = Select

const RotOrRut = ({ type }) => {
  const _type = isRotOrRut(type)
  if (_type === 'RUT') return <span style={{ paddingLeft: 5 }}>(RUT)</span>

  return <span style={{ paddingLeft: 5 }}>(ROT)</span>
}

const RotVariantSelect = ({
  defaultValue = 'CONSTRUCTION',
  style = { width: 210, fontSize: 14 },
  ...extraProps
}) => (
  <Select defaultValue={defaultValue} style={style} {...extraProps}>
    <Option key="CONSTRUCTION" value="CONSTRUCTION">
      <FormattedMessage
        id="RotVariantSelect.construction"
        defaultMessage="Construction"
      />
      <RotOrRut type="CONSTRUCTION" />
    </Option>
    <Option key="ELECTRICITY" value="ELECTRICITY">
      <FormattedMessage
        id="RotVariantSelect.electricity"
        defaultMessage="Electricity"
      />
      <RotOrRut type="ELECTRICITY" />
    </Option>
    <Option key="GLASSMETALWORK" value="GLASSMETALWORK">
      <FormattedMessage
        id="RotVariantSelect.glassmetalwork"
        defaultMessage="Glass / metalwork"
      />
      <RotOrRut type="GLASSMETALWORK" />
    </Option>
    <Option key="GROUNDDRAINAGEWORK" value="GROUNDDRAINAGEWORK">
      <FormattedMessage
        id="RotVariantSelect.grounddrainagework"
        defaultMessage="Ground / drainage work"
      />
      <RotOrRut type="GROUNDDRAINAGEWORK" />
    </Option>
    <Option key="MASONRY" value="MASONRY">
      <FormattedMessage
        id="RotVariantSelect.masonry"
        defaultMessage="Masonry"
      />
      <RotOrRut type="MASONRY" />
    </Option>
    <Option key="PAINTINGWALLPAPERING" value="PAINTINGWALLPAPERING">
      <FormattedMessage
        id="RotVariantSelect.paintingwallpapering"
        defaultMessage="Painting / wallpapering"
      />
      <RotOrRut type="PAINTINGWALLPAPERING" />
    </Option>
    <Option key="HVAC" value="HVAC">
      <FormattedMessage id="RotVariantSelect.hvac" defaultMessage="Hvac" />
      <RotOrRut type="HVAC" />
    </Option>
    <Option key="MAJORAPPLIANCEREPAIR" value="MAJORAPPLIANCEREPAIR">
      <FormattedMessage
        id="RotVariantSelect.majorappliancerepair"
        defaultMessage="Major appliance repair"
      />
      <RotOrRut type="MAJORAPPLIANCEREPAIR" />
    </Option>
    <Option key="MOVINGSERVICES" value="MOVINGSERVICES">
      <FormattedMessage
        id="RotVariantSelect.movingservices"
        defaultMessage="Moving services"
      />
      <RotOrRut type="MOVINGSERVICES" />
    </Option>
    <Option key="ITSERVICES" value="ITSERVICES">
      <FormattedMessage
        id="RotVariantSelect.itservices"
        defaultMessage="It-services"
      />
      <RotOrRut type="ITSERVICES" />
    </Option>
    <Option key="CLEANING" value="CLEANING">
      <FormattedMessage
        id="RotVariantSelect.cleaning"
        defaultMessage="Cleaning"
      />
      <RotOrRut type="CLEANING" />
    </Option>
    <Option key="TEXTILECLOTHING" value="TEXTILECLOTHING">
      <FormattedMessage
        id="RotVariantSelect.textileclothing"
        defaultMessage="Textile / clothing"
      />
      <RotOrRut type="TEXTILECLOTHING" />
    </Option>
    <Option key="SNOWPLOWING" value="SNOWPLOWING">
      <FormattedMessage
        id="RotVariantSelect.snowplowing"
        defaultMessage="Snowplowing"
      />
      <RotOrRut type="SNOWPLOWING" />
    </Option>
    <Option key="GARDENING" value="GARDENING">
      <FormattedMessage
        id="RotVariantSelect.gardening"
        defaultMessage="Gardening"
      />
      <RotOrRut type="GARDENING" />
    </Option>
    <Option key="BABYSITTING" value="BABYSITTING">
      <FormattedMessage
        id="RotVariantSelect.babysitting"
        defaultMessage="Babysitting"
      />
      <RotOrRut type="BABYSITTING" />
    </Option>
    <Option key="OTHERCARE" value="OTHERCARE">
      <FormattedMessage
        id="RotVariantSelect.othercare"
        defaultMessage="Other care"
      />
      <RotOrRut type="OTHERCARE" />
    </Option>
    <Option key="OTHERCOSTS" value="OTHERCOSTS">
      <FormattedMessage
        id="RotVariantSelect.othercosts"
        defaultMessage="Other costs"
      />
      <RotOrRut type="OTHERCOSTS" />
    </Option>
  </Select>
)

export default RotVariantSelect
