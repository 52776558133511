import React from 'react'
import { defineMessages } from 'react-intl'

import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'

import EmployeeForm from '../../../components/EmployeeForm/EmployeeForm'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsEmployeeView.pageTitle',
    defaultMessage: 'Settings'
  }
})

const UserEmployeeSettingsView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="employee-settings">
        <EmployeeForm />
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserEmployeeSettingsView
