import React, { useState } from 'react'
import { Button, Icon, Divider } from 'antd'
import { FormattedAmount } from 'lib/locale'
import { calculate } from 'lib/shared/salary-calculator'
import './InvoiceSummary.css'
import { FormattedMessage } from 'react-intl'

const taxYear = new Date().getFullYear()

const InvoiceSummarySalaryRow = ({ desc, value }) => (
  <div className="invoice-summary-salary__row">
    <span className="invoice-summary-salary__desc">{desc}</span>
    <span className="invoice-summary-salary__value">{value}</span>
  </div>
)

const InvoiceSummary = ({
  totalAmountExVat,
  totalRotAmount,
  totalVatAmount,
  reimbursementsSum,
  tractamentsSum,
  travelExpensesSum,
  serviceFeePercentage,
}) => {
  const [showDetails, setShowDetails] = useState(false)
  const amounts = calculate(
    totalAmountExVat,
    { taxYear, serviceFeePercentage },
    {
      reimbursementsSum,
      tractamentsSum,
      travelExpensesSum,
    }
  )

  return (
    <>
      <div className="invoice-summary-amounts-wrapper">
        <div className="invoice-summary-amounts">
          <span className="invoice-summary-amount__desc">
            <FormattedMessage
              id="InvoiceSummary.totalInvoiceAmount"
              defaultMessage="Total invoice amount (excl VAT)"
            />
          </span>
          <span className="invoice-summary-amount__value">
            <FormattedAmount valueInCent={totalAmountExVat} currency="SEK" />
          </span>
        </div>
        {totalRotAmount > 0 && (
          <div className="invoice-summary-amounts">
            <span className="invoice-summary-amount__desc">
              <FormattedMessage
                id="InvoiceSummary.totalRotAmount"
                defaultMessage="ROT/RUT-deductible"
              />
            </span>
            <span className="invoice-summary-amount__value">
              <FormattedAmount
                valueInCent={totalRotAmount * -1}
                currency="SEK"
              />
            </span>
          </div>
        )}

        <div className="invoice-summary-amounts">
          <span className="invoice-summary-amount__desc">
            <FormattedMessage
              id="InvoiceSummary.totalVatAmount"
              defaultMessage="VAT"
            />
          </span>
          <span className="invoice-summary-amount__value">
            <FormattedAmount valueInCent={totalVatAmount} currency="SEK" />
          </span>
        </div>
        <div className="invoice-summary-amounts">
          <span className="invoice-summary-amount__desc">
            <strong>
              <FormattedMessage
                id="InvoiceSummary.totalInvoiceAmountInclVat"
                defaultMessage="Total invoice amount (incl VAT)"
              />
            </strong>
          </span>
          <span className="invoice-summary-amount__value">
            <strong>
              <FormattedAmount
                valueInCent={totalVatAmount + totalAmountExVat - totalRotAmount}
                currency="SEK"
              />
            </strong>
          </span>
        </div>
      </div>

      <div className="invoice-summary__show-salary-details">
        <Button
          type="link"
          onClick={() => {
            setShowDetails(!showDetails)
          }}
        >
          {!showDetails ? (
            <FormattedMessage
              id="InvoiceSummary.showDetails"
              defaultMessage="Show details"
            />
          ) : (
            <FormattedMessage
              id="InvoiceSummary.hideDetails"
              defaultMessage="Hide details"
            />
          )}
          <Icon type="down" />
        </Button>
      </div>

      <div className="invoice-summary-salary">
        {showDetails && (
          <>
            <InvoiceSummarySalaryRow
              desc={
                <FormattedMessage
                  id="InvoiceSummary.invoiceAmountExVat"
                  defaultMessage="Invoice amount (excl VAT)"
                />
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.invoiceAmountExVat}
                  currency="SEK"
                />
              }
            />
            <InvoiceSummarySalaryRow
              desc={
                <span>
                  <FormattedMessage
                    id="InvoiceSummary.serviceFee"
                    defaultMessage="Our fee"
                  />
                  {` (${amounts.serviceFeePercentage}%)`}
                </span>
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.serviceFee}
                  currency="SEK"
                />
              }
            />
            <InvoiceSummarySalaryRow
              desc={
                <span>
                  <FormattedMessage
                    id="InvoiceSummary.amountWithoutTaxFreeItems"
                    defaultMessage="Salary before fees & taxes"
                  />
                </span>
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.amountWithoutTaxFreeItems}
                  currency="SEK"
                />
              }
            />
            <InvoiceSummarySalaryRow
              desc={
                <FormattedMessage
                  id="InvoiceSummary.socialFees"
                  defaultMessage="Social fees"
                />
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.socialFees}
                  currency="SEK"
                />
              }
            />
            <InvoiceSummarySalaryRow
              desc={
                <FormattedMessage
                  id="InvoiceSummary.grossSalary"
                  defaultMessage="Gross salary"
                />
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.grossSalary}
                  currency="SEK"
                />
              }
            />
            <InvoiceSummarySalaryRow
              desc={
                <FormattedMessage
                  id="InvoiceSummary.tax"
                  defaultMessage="Taxes"
                />
              }
              value={
                <FormattedAmount valueInCent={amounts.tax} currency="SEK" />
              }
            />

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <InvoiceSummarySalaryRow
              desc={
                <FormattedMessage
                  id="InvoiceSummary.netSalary"
                  defaultMessage="Net salary"
                />
              }
              value={
                <FormattedAmount
                  valueInCent={amounts.netSalary}
                  currency="SEK"
                />
              }
            />
            {Boolean(
              amounts.reimbursementsSum && amounts.reimbursementsSum > 0
            ) && (
              <InvoiceSummarySalaryRow
                desc={
                  <FormattedMessage
                    id="InvoiceSummary.reimbursementsSum"
                    defaultMessage="Reimbursements"
                  />
                }
                value={
                  <FormattedAmount
                    valueInCent={amounts.reimbursementsSum}
                    currency="SEK"
                  />
                }
              />
            )}
            {Boolean(amounts.tractamentsSum && amounts.tractamentsSum > 0) && (
              <InvoiceSummarySalaryRow
                desc={
                  <FormattedMessage
                    id="InvoiceSummary.tractamentsSum"
                    defaultMessage="Tractaments"
                  />
                }
                value={
                  <FormattedAmount
                    valueInCent={amounts.tractamentsSum}
                    currency="SEK"
                  />
                }
              />
            )}
            {Boolean(
              amounts.travelExpensesSum && amounts.travelExpensesSum > 0
            ) && (
              <InvoiceSummarySalaryRow
                desc={
                  <FormattedMessage
                    id="InvoiceSummary.travelExpensesSum"
                    defaultMessage="Travel Expenses"
                  />
                }
                value={
                  <FormattedAmount
                    valueInCent={amounts.travelExpensesSum}
                    currency="SEK"
                  />
                }
              />
            )}
            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          </>
        )}

        <div className="invoice-summary-salary__row">
          <span className="invoice-summary-salary__desc invoice-summary-salary__desc--large">
            <FormattedMessage
              id="InvoiceSummary.totalSalaryAmount"
              defaultMessage="Your total salary"
            />
          </span>
          <span className="invoice-summary-salary__value invoice-summary-salary__value--large">
            <FormattedAmount valueInCent={amounts.totalSalary} currency="SEK" />
          </span>
        </div>
      </div>
    </>
  )
}

export default InvoiceSummary
