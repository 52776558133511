import React from 'react'
import sv_SE_ant from 'antd/lib/locale-provider/sv_SE'
import en_US_ant from 'antd/lib/locale-provider/en_US'
import { FormattedNumber } from 'react-intl'
import { Mutation } from 'react-apollo'
import LocaleSelect from '../components/LocaleSelect/LocaleSelect'
import sv_SE from '../locales/sv.json'
import changeLanguageMutation from './changeLanguageMutation.gql'
import getUserActivityForWidget from 'components/ActivityWidget/getUserActivityForWidget.gql'
import userListActivities from 'components/ActivityList/userListActivities.gql'

import moment from 'moment'
import 'moment/locale/sv'
import { withAuth } from 'services/auth'

const availableLocales = {
  sv: {
    antd: sv_SE_ant,
    locale: 'sv',
    messages: sv_SE,
  },
  en: {
    antd: en_US_ant,
    locale: 'en',
    messages: {},
  },
}

export const getCurrentLocale = (init) => {
  const currentLocale = window.localStorage.getItem('locale')
  const currentAvalableLocale =
    availableLocales[currentLocale] || availableLocales.sv
  if (init) {
    moment.locale(currentAvalableLocale.locale)
  }
  return currentAvalableLocale
}

export const setLocale = (locale) => {
  window.localStorage.setItem('locale', locale)

  const newLocale = getCurrentLocale()

  moment.locale(newLocale.locale)

  // There is an issue with events not firing for localstorage in the same tab (it fires in other open tabs)
  // This is a workaround that forces the event to fire in the currently active tab as well
  // https://gist.github.com/TakashiSasaki/4282903
  window.dispatchEvent(new Event('storage'))
}

class LocalePickerWithoutAuth extends React.Component {
  constructor(props) {
    super(props)
    const l = getCurrentLocale()
    const defaultValue = l.locale
    this.state = { defaultValue }
  }

  render() {
    const { isAuthenticated } = this.props
    const { defaultValue } = this.state
    return (
      <div className="change-locale">
        <Mutation
          mutation={changeLanguageMutation}
          refetchQueries={[
            { query: userListActivities },
            { query: getUserActivityForWidget },
          ]}
        >
          {(changeLanguage, { loading }) => (
            <LocaleSelect
              loading={loading}
              defaultValue={defaultValue}
              onChange={(locale) => {
                setLocale(locale)
                isAuthenticated &&
                  changeLanguage({ variables: { language: locale } })
              }}
            />
          )}
        </Mutation>
      </div>
    )
  }
}
export const LocalePicker = withAuth(LocalePickerWithoutAuth)

export const FormattedAmount = ({
  valueInCent,
  currency = 'SEK',
  precision = 2,
}) => (
  <FormattedNumber
    value={(valueInCent / 100).toFixed(precision)}
    minimumFractionDigits={0}
    style="currency"
    currency={currency}
  />
)
