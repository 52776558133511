import React, { Component } from 'react'
import { Select, Icon, Empty } from 'antd'
import CustomerForm from '../CustomerForm/CustomerForm'
import { Query } from 'react-apollo'
import getCurrentCustomers from './getCurrentCustomers.gql'
import styles from './CustomerSelector.module.css'
import FancyButton from '../FancyButton/FancyButton'
import { defineMessages, FormattedMessage } from 'react-intl'

const { Option } = Select

const messages = defineMessages({
  or: {
    id: 'CustomerSelector.or',
    defaultMessage: 'or',
  },
  addNewCustomer: {
    id: 'CustomerSelector.addNewCustomer',
    defaultMessage: 'Add new customer',
  },
})

class CustomerSelector extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showAddNewForm: false,
    }
  }

  toggleNewForm = () => {
    const { showAddNewForm } = this.state
    this.setState({ showAddNewForm: !showAddNewForm })
  }
  render() {
    const { onChange, value, customers, loading } = this.props
    const { showAddNewForm } = this.state

    return (
      <div>
        {!showAddNewForm ? (
          <div className={styles.selectOrCreate}>
            <Select
              loading={loading}
              showSearch
              className={styles.select}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={
                    <FormattedMessage
                      key="emptySelectDescription"
                      defaultMessage="You need to create a customer"
                    />
                  }
                />
              }
              placeholder={
                <FormattedMessage
                  key="selectPlaceholder"
                  defaultMessage="Select a customer"
                />
              }
              optionFilterProp="children"
              value={value}
              onChange={(id) => {
                const customer = customers.find((c) => c.id === id)
                onChange(customer)
              }}
              filterOption={(input, option) =>
                option.props.children &&
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {customers.map((customer) => (
                <Option key={customer.id} value={customer.id}>
                  <Icon
                    type={customer.customerType === 'COMPANY' ? 'bank' : 'user'}
                  />{' '}
                  {customer.name}
                </Option>
              ))}
            </Select>
            <span className={styles.or}>
              <FormattedMessage {...messages.or} />
            </span>
            <FancyButton
              onClick={this.toggleNewForm}
              variant="secondary"
              className={styles.button}
            >
              <FormattedMessage {...messages.addNewCustomer} />
            </FancyButton>
          </div>
        ) : (
          <CustomerForm
            onCancel={this.toggleNewForm}
            onCustomerAdded={onChange}
          />
        )}
      </div>
    )
  }
}

const CustomerSelectorQuery = (props) => {
  return (
    <Query query={getCurrentCustomers}>
      {({ loading, error, data = {} }) => {
        if (error) return `Error! ${error.message}`

        const customers =
          data && data.currentUser
            ? data.currentUser.customers.edges.map((edge) => edge.node)
            : []

        return (
          <CustomerSelector
            loading={loading}
            customers={customers}
            {...props}
          />
        )
      }}
    </Query>
  )
}

export default CustomerSelectorQuery
