import React from 'react'
import { Divider } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@apollo/react-hooks'
import calculateTractamentMutation from './calculateTractament.gql'

import InvoiceAddonTwoColumn from '../InvoiceAddonTwoColumn/InvoiceAddonTwoColumn'
import TractamentTable from '../TractamentTable/TractamentTable'
import Form from './form'

const TractementForm = ({ onAddTractament }) => {
  const [calculateTractament] = useMutation(calculateTractamentMutation, {
    onCompleted: (res) => {
      const {
        dailyTractament,
        amount,
        country,
        numberOfHalfDays,
        numberOfDays,
        numberOfBreakfasts,
        numberOfLunches,
        numberOfDinners,
        description,
        dateFrom,
        dateTo,
      } = res.calculateTractament

      onAddTractament({
        dailyTractament: dailyTractament.valueInCent,
        amount: amount.valueInCent,
        country,
        numberOfHalfDays,
        numberOfDays,
        numberOfBreakfasts,
        numberOfLunches,
        numberOfDinners,
        description,
        dateFrom,
        dateTo,
      })
    },
  })

  return (
    <Form
      onSubmit={(input) => {
        calculateTractament({ variables: { input } })
      }}
    />
  )
}

const TractamentForm = ({
  tractaments,
  onAddTractament,
  onRemoveTractament,
}) => {
  const rightColumn = () => (
    <>
      <h3>
        <FormattedMessage
          key="TractamentForm.intoTitle"
          defaultMessage="About Tractament"
        />
      </h3>
      <p>
        <FormattedMessage
          key="TractamentForm.infoText1"
          defaultMessage="För att Skatteverket ska godkänna traktamente krävs att två villkor är uppfyllda:"
        />
      </p>
      <p>
        <FormattedMessage
          key="TractamentForm.infoText2"
          defaultMessage="1. Du måste alltid övernatta minst en natt för att kunna få skattefritt traktamente utbetalt."
        />
      </p>
      <p>
        <FormattedMessage
          key="TractamentForm.infoText3"
          defaultMessage="2. Det krävs också att du reser till en plats som ligger mer än 50 kilometer från din vanliga arbetsplats och mer än 50 kilometer från din bostad."
        />
      </p>
      <p>
        <a
          href="https://konsultfaktura.zendesk.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            key="TractamentForm.infoTextCta"
            defaultMessage="Read more about Tractament."
          />
        </a>
      </p>
    </>
  )

  return (
    <>
      <InvoiceAddonTwoColumn rightColumn={rightColumn()}>
        <h3>
          <FormattedMessage
            key="TractamentForm.title"
            defaultMessage="Tractament"
          />
        </h3>

        <TractementForm onAddTractament={onAddTractament} />
      </InvoiceAddonTwoColumn>

      {tractaments && tractaments.length > 0 && (
        <TractamentTable
          tractaments={tractaments}
          onRemoveTractament={onRemoveTractament}
        />
      )}

      <Divider style={{ marginTop: 40, marginBottom: 70 }} />
    </>
  )
}

export default TractamentForm
