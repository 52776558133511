import { green, red } from '@ant-design/colors'
import { Icon } from 'antd'
import FancyButton from 'components/FancyButton/FancyButton'
import React from 'react'
import { Mutation } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import { withAuth } from 'services/auth'
import sendUserConfirmationMutation from './sendUserConfirmationMutation.gql'

const ResultMessage = ({ result }) => {
  const { errors } = result

  if (errors) {
    return <span style={{ color: red[6] }}>{errors.map(({ message }) => message).join(', ')}</span>
  } else {
    return (
      <span style={{ color: green[6] }}>
        <FormattedMessage key="successMessage" defaultMessage="Check your email inbox for your new confirmation link" /> <Icon type="check" />
      </span>
    )
  }
}

const UserConfirmationButton = ({ user }) => {
  if (user.confirmedAt || !user.email) {
    return null
  }

  return (
    <Mutation mutation={sendUserConfirmationMutation} variables={{ userId: user.id }}>
      {(mutate, { loading, data }) =>
        data ? (
          <ResultMessage result={data.sendUserConfirmation} />
        ) : (
          <FancyButton variant="cta" loading={loading} onClick={mutate} size="large">
            <FormattedMessage key="resendLink" defaultMessage="Resend confirmation email" />
          </FancyButton>
        )
      }
    </Mutation>
  )
}

export default withAuth(UserConfirmationButton)
