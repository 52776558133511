import React from 'react'
import CustomerForm from './form'
import createCustomerMutation from './createCustomerMutation.gql'
import { Mutation } from 'react-apollo'
import useIsMobile from 'hooks/useIsMobile'

const CustomerFormMutation = ({ onCustomerAdded, onCancel }) => {
  const isMobile = useIsMobile()
  return (
    <Mutation
      mutation={createCustomerMutation}
      onCompleted={({ currentUser }) => {
        onCustomerAdded(currentUser.createCustomer.id)
        if (isMobile) {
          window.scrollTo({ top: 0 })
        }
        onCancel()
      }}
    >
      {(mutate, { loading }) => (
        <CustomerForm
          onCancel={onCancel}
          loading={loading}
          fields={{
            customerType: 'COMPANY',
          }}
          onSubmit={(values) => {
            mutate({
              variables: { input: values },
              refetchQueries: ['CustomerSelectorListCustomers'],
            })
          }}
        />
      )}
    </Mutation>
  )
}

export default CustomerFormMutation
