import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { withAuth } from 'services/auth'
import PageLayout from '../../components/PageLayout/PageLayout'
import { Row, Col, Card, List } from 'antd'
import FaqWidget from '../../components/FaqWidget/FaqWidget'
import FancyButton from '../../components/FancyButton/FancyButton'

import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.Support.pageTitle',
    defaultMessage: 'Support',
  },
  documentsTitle: {
    id: 'supportDocuments',
    defaultMessage: 'Documents',
  },
  behandlingAvPersonuppgifter: {
    id: 'App.Support.behandlingAvPersonuppgifter',
    defaultMessage: 'Behandling av personuppgifter (pdf)',
  },
  allmannavillkorEgenanstalld: {
    id: 'App.Support.allmannavillkorEgenanstalld',
    defaultMessage: 'Allmänna villkor Egenanställd (pdf)',
  },
  allmanaVillkorKund: {
    id: 'App.Support.allmanaVillkorKund',
    defaultMessage: 'Allmänna villkor Kund/Uppdragsgivare (pdf)',
  },
  avdragsguide: {
    id: 'App.Support.avdragsguide',
    defaultMessage: 'Avdragsguide (pdf)',
  },
  anstallningsavtal: {
    id: 'App.Support.anstallningsavtal',
    defaultMessage: 'Anställningsavtal (pdf)',
  },
  uppdragsavtal: {
    id: 'App.Support.uppdragsavtal',
    defaultMessage: 'Uppdragsavtal (pdf)',
  },
  uppdragsavtalMedInteraktivaFalt: {
    id: 'App.Support.uppdragsavtalMedInteraktivaFalt',
    defaultMessage: 'Uppdragsavtal med interaktiva fält (pdf)',
  },
})

const documents = [
  {
    msg: messages.behandlingAvPersonuppgifter,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Behandling-av-Personuppgifter.pdf',
  },
  {
    msg: messages.allmannavillkorEgenanstalld,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Allmanna-Villkor-Egenanstallning.pdf',
  },
  {
    msg: messages.allmanaVillkorKund,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Allmanna-Villkor-Kund.pdf',
  },
  {
    msg: messages.avdragsguide,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Avdragsguide.pdf',
  },
  {
    msg: messages.anstallningsavtal,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Anstallningsavtal.pdf',
  },
  {
    msg: messages.uppdragsavtal,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Uppdragsavtal.pdf',
  },
  {
    msg: messages.uppdragsavtalMedInteraktivaFalt,
    href:
      'http://konsultfaktura.se/wp-content/uploads/KF-Uppdragsavtal-falt.pdf',
  },
]

class SupportView extends Component {
  render() {
    return (
      <PageLayout pageTitleKey={messages.pageTitle}>
        <Row gutter={32}>
          <Col sm={24} md={12}>
            <Card style={{ marginBottom: 24 }}>
              <h2>
                <FormattedMessage
                  key="contactTitle"
                  defaultMessage="Contact us!"
                />
              </h2>
              <p>
                <FormattedMessage
                  key="contactCaption"
                  defaultMessage="You can reach us, at all hours, just call Dawid at any time and he will be super happy to answer all your questions."
                />
              </p>

              <p style={{ marginBottom: 30 }}>
                Behöver du fortfarande hjälp så får du alltid personlig service
                av oss! Chatta, maila eller ring så får du hjälp av någon av
                våra duktiga kollegor!
              </p>

              <a
                href={'https://konsultfaktura.zendesk.com/hc/sv-se'}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FancyButton>
                  <FormattedMessage
                    key="toHelpdesk"
                    defaultMessage="To Helpdesk"
                  />
                </FancyButton>
              </a>

              <p style={{ fontSize: 20 }}>
                {'E-mail: '}
                <a href="mailto:support@konsultfaktura.se">
                  support@konsultfaktura.se
                </a>
              </p>

              <p style={{ fontSize: 20 }}>Tel: 08 - 96 66 66 (09-17)</p>
            </Card>
          </Col>

          <Col sm={24} md={12}>
            <Card style={{ marginBottom: 24 }}>
              <h2>
                <FormattedMessage
                  key="faqTitle"
                  defaultMessage="Questions and answers"
                />
              </h2>
              <FaqWidget />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <Card style={{ marginBottom: 24 }}>
              <h2>
                <FormattedMessage {...messages.documentsTitle} />
              </h2>
              <List
                size="large"
                dataSource={documents.map((d) => (
                  <>
                    <a href={d.href}>
                      <FormattedMessage {...d.msg} />
                    </a>
                  </>
                ))}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Card>
          </Col>
        </Row>
      </PageLayout>
    )
  }
}

export default withAuth(SupportView)
