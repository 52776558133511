import React from 'react'
import { List } from 'antd'
import getUserActivityForWidget from './getUserActivityForWidget.gql'
import ActivityListItem from '../ActivityListItem/ActivityListItem'
import { Query } from 'react-apollo'
import { Link } from 'react-router-dom'
import { defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  showEverything: {
    id: 'ActivityWidget.showEverything',
    defaultMessage: 'Show all activity'
  }
})

const ActivityList = ({ limit }) => {
  return (
    <Query query={getUserActivityForWidget}>
      {({ loading, error, data = {} }) => {
        if (error) return <div>Error! ${error.message}</div>

        const dataSource = loading ? [] : data.currentUser.activityLog.edges.map(edge => edge.node)
        return (
          <List
            itemLayout="horizontal"
            loading={loading}
            dataSource={dataSource}
            renderItem={item => <ActivityListItem item={item} />}
            footer={
              <div style={{ textAlign: 'center' }}>
                <Link to={'/all-activities'}>
                  <FormattedMessage {...messages.showEverything} />
                </Link>
              </div>
            }
          />
        )
      }}
    </Query>
  )
}

export default ActivityList
