import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Tabs } from 'antd'
const { TabPane } = Tabs

const messages = defineMessages({
  bankidTitle: {
    id: 'TabsBankidEmail.bankidTitle',
    defaultMessage: 'BankID'
  },
  emailTitle: {
    id: 'TabsBankidEmail.emailTitle',
    defaultMessage: 'Email'
  }
})

const TabsBankidEmail = ({ bankidPane, emailPane}) => (
  <Tabs size={'small'} defaultActiveKey="bankid">
    <TabPane tab={<FormattedMessage {...messages.bankidTitle} />} key="bankid">
      {bankidPane}
    </TabPane>
    <TabPane tab={<FormattedMessage {...messages.emailTitle} />} key="email">
      {emailPane}
    </TabPane>
  </Tabs>
)

export default TabsBankidEmail