import React from 'react'
import { Button } from 'antd'
import { defineMessages, FormattedMessage } from 'react-intl'

// Recommended user messages from
// https://www.bankid.com/assets/bankid/rp/bankid-relying-party-guidelines-v3.2.2.pdf
// With the same short names as in the documentation
const messages = defineMessages({
  retry: {
    id: 'BankidMessage.retry',
    defaultMessage: 'Retry'
  },
  rfa1: {
    id: 'BankidMessage.rfa1',
    defaultMessage: 'Start your BankID app.'
  },
  rfa3: {
    id: 'BankidMessage.rfa3',
    defaultMessage: 'Action cancelled. Please try again.'
  },
  rfa4: {
    id: 'BankidMessage.rfa4',
    defaultMessage: 'An identification or signing for this personal number is already started. Please try again.'
  },
  rfa5: {
    id: 'BankidMessage.rfa5',
    defaultMessage: 'Internal error. Please try again.'
  },
  rfa6: {
    id: 'BankidMessage.rfa6',
    defaultMessage: 'Action cancelled.'
  },
  rfa8: {
    id: 'BankidMessage.rfa8',
    defaultMessage:
      'The BankID app is not responding. Please check that the program is started and that you have internet access. If you don’t have a valid BankID you can get one from your bank. Try again.'
  },
  rfa9: {
    id: 'BankidMessage.rfa9',
    defaultMessage: 'Enter your security code in the BankID app and select Identify or Sign.'
  },
  rfa16: {
    id: 'BankidMessage.rfa16',
    defaultMessage: 'The BankID you are trying to use is revoked or too old. Please use another BankID or order a new one from your internet bank.'
  },
  rfa21: {
    id: 'BankidMessage.rfa21',
    defaultMessage: 'Identification or signing in progress.'
  },
  rfa22: {
    id: 'BankidMessage.rfa22',
    defaultMessage: 'Unknown error. Please try again.'
  }
})

const BankidMessage = ({ status, hintCode, errorCode, handleRetry }) => {
  const bankidError = text => {
    return (
      <>
        {text}
        <Button type="link" onClick={handleRetry}>
          <FormattedMessage {...messages.retry} />
        </Button>
      </>
    )
  }

  if (status === 'pending' && (hintCode === 'outstandingTransaction' || hintCode === 'noClient')) return <FormattedMessage {...messages.rfa1} />

  if (errorCode === 'cancelled') return bankidError(<FormattedMessage {...messages.rfa3} />)
  if (errorCode === 'alreadyInProgress') return bankidError(<FormattedMessage {...messages.rfa4} />)
  if (['requestTimeout', 'maintenance', 'internalError'].includes(errorCode)) return bankidError(<FormattedMessage {...messages.rfa5} />)

  if (status === 'failed' && hintCode === 'userCancel') return bankidError(<FormattedMessage {...messages.rfa6} />)
  if (status === 'failed' && hintCode === 'expiredTransaction') return bankidError(<FormattedMessage {...messages.rfa8} />)
  if (status === 'failed' && hintCode === 'certificateErr') return bankidError(<FormattedMessage {...messages.rfa16} />)

  if (status === 'pending' && hintCode === 'userSign') return <FormattedMessage {...messages.rfa9} />

  if (status === 'failed') return bankidError(<FormattedMessage {...messages.rfa22} />)
  if (status === 'pending') return <FormattedMessage {...messages.rfa21} />

  return (
    <>
      {status}
      {hintCode && `Hint: ${hintCode}`}
      {errorCode && `Error: ${errorCode}`}
    </>
  )
}

export default BankidMessage
