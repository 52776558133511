import React from 'react'
import ChangeEmail from '../../../components/ChangeEmail/ChangeEmail'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsChangeEmail.pageTitle',
    defaultMessage: 'Settings - Change email'
  }
})

const UserSettingsView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="email">
        <ChangeEmail />
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
