import React from 'react'
import { Form, Input, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { compareToFirstPassword, validateToNextPassword } from 'lib/validators'
import { withAuth } from 'services/auth'
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl'
import withFormDecorator from 'hocs/with-form-decorator'
import FancyButton from '../FancyButton/FancyButton'
import PasswordInput from 'components/PasswordInput/PasswordInput'

const messages = defineMessages({
  passwordPlaceholder: 'Password',
  newPasswordLabel: 'New password',
  newPasswordRuleRequired: 'Please input your password!',
  repeatPasswordLabel: 'Repeat password',
  repeatPasswordRuleRequired: 'Please input your password!',
  previousPasswordLabel: 'Previous password',
  previousPasswordRuleRequired: 'Please input your password!',
  submit: 'Change password'
})

const PasswordForm = ({ form, onSubmit, saving, intl, user }) => {
  const { getFieldDecorator, validateFieldsAndScroll } = form

  const handleSubmit = e => {
    e.preventDefault()

    validateFieldsAndScroll((err, values) => {
      if (err) return
      onSubmit(values)
    })
  }

  return (
    <Form onSubmit={handleSubmit} layout="vertical" hideRequiredMark>
      <Form.Item
        label={<FormattedMessage {...messages.previousPasswordLabel} />}
        extra={
          <Link to={{ pathname: '/forgot-password', state: { email: user.email } }}>
            <FormattedMessage key="forgotPasswordLink" defaultMessage="Don't know your password?" />
          </Link>
        }
      >
        {getFieldDecorator('previousPassword', {
          rules: [
            {
              required: true,
              message: <FormattedMessage {...messages.previousPasswordRuleRequired} />
            }
          ]
        })(<Input.Password autoComplete="current-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} prefix={<Icon type="lock" />} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage {...messages.newPasswordLabel} />}>
        {getFieldDecorator('password', {
          rules: [
            {
              required: true,
              message: <FormattedMessage {...messages.newPasswordRuleRequired} />
            },
            {
              validator: validateToNextPassword(form, 'passwordConfirm')
            }
          ]
        })(<PasswordInput autoComplete="new-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} prefix={<Icon type="lock" />} />)}
      </Form.Item>
      <Form.Item label={<FormattedMessage {...messages.repeatPasswordLabel} />}>
        {getFieldDecorator('passwordConfirm', {
          rules: [
            {
              required: true,
              message: <FormattedMessage {...messages.repeatPasswordRuleRequired} />
            },
            {
              validator: compareToFirstPassword(form, 'password')
            }
          ]
        })(<PasswordInput autoComplete="new-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} prefix={<Icon type="lock" />} />)}
      </Form.Item>
      <Form.Item>
        <FancyButton type="submit" loading={saving}>
          <FormattedMessage {...messages.submit} />
        </FancyButton>
      </Form.Item>
    </Form>
  )
}

export default withAuth(withFormDecorator(injectIntl(PasswordForm)))
