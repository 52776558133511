import { Typography } from 'antd'
import CenteredLoader from 'components/CenteredLoader/CenteredLoader'
import FancyButton from 'components/FancyButton/FancyButton'
import { useQuery } from '@apollo/react-hooks'

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import GenericNotFoundView from 'views/not-found'
import CreditInvoiceButton from '../CreditInvoiceButton/CreditInvoiceButton'
import DeleteInvoiceDraftButton from '../DeleteInvoiceDraftButton/DeleteInvoiceDraftButton'
import InvoiceRows from '../InvoiceRows/InvoiceRows'
import InvoiceSummary from '../InvoiceSummary/InvoiceSummary'
import InvoiceTimeline from '../InvoiceTimeline/InvoiceTimeline'
import SectionHeader from '../SectionHeader/SectionHeader'
import SendInvoiceButton from '../SendInvoiceButton/SendInvoiceButton'
import DraftInvoiceButton from '../DraftInvoiceButton/DraftInvoiceButton'
import getInvoice from './getInvoice.gql'
import ReimbursementsTable from 'components/ReimbursementsTable/ReimbursementsTable'
import TravelExpensesTable from 'components/TravelExpensesTable/TravelExpensesTable'
import TractamentTable from 'components/TractamentTable/TractamentTable'
import useIsMobile from 'hooks/useIsMobile'
import { withAuth } from 'services/auth'

const { Title } = Typography

export default withAuth(function InvoiceView({ id, user }) {
  const { data = {}, error, loading } = useQuery(getInvoice, {
    variables: { id },
  })
  const { invoice } = data
  const isMobile = useIsMobile()

  if (error) return <div style={{ color: 'red' }}>Error loading invoice.</div>
  if (invoice === null) return <GenericNotFoundView />
  if (loading) return <CenteredLoader />

  const { customerName, description } = invoice

  return (
    <div>
      <section style={{ marginTop: 48, marginBottom: 48 }}>
        <Title level={4} style={{ padding: 0, margin: 0 }}>
          <FormattedMessage
            key="InvoiceView.customerTitle"
            defaultMessage="Customer"
          />
        </Title>
        <Title level={3} style={{ padding: 0, margin: 0 }}>
          {customerName}
        </Title>
      </section>

      <InvoiceTimeline invoice={invoice} />

      <section style={{ paddingBottom: 24 }}>
        <SectionHeader help={false} border title={'Beskrivning'} />
        <p>
          {description || (
            <span style={{ color: '#cacaca' }}>
              <FormattedMessage
                key="InvoiceView.noDescriptionMessage"
                defaultMessage="No description"
              />
            </span>
          )}
        </p>
      </section>

      <InvoiceRows invoiceRows={invoice.rows} readOnly />

      {invoice.reimbursements && invoice.reimbursements.length > 0 && (
        <div style={{ paddingTop: 36 }}>
          <Title level={4}>
            <FormattedMessage
              key="InvoiceView.reimbursementsTitle"
              defaultMessage="Reimbursements"
            />
          </Title>
          <ReimbursementsTable
            reimbursements={invoice.reimbursements}
            readOnly
          />
        </div>
      )}

      {invoice.travelExpenses && invoice.travelExpenses.length > 0 && (
        <div style={{ paddingTop: 36 }}>
          <Title level={4}>
            <FormattedMessage
              key="InvoiceView.travelExpensesTitle"
              defaultMessage="Travel Expenses"
            />
          </Title>
          <TravelExpensesTable
            travelExpenses={invoice.travelExpenses}
            readOnly
          />
        </div>
      )}

      {invoice.tractaments && invoice.tractaments.length > 0 && (
        <div style={{ paddingTop: 36 }}>
          <Title level={4}>
            <FormattedMessage
              key="InvoiceView.tractamentsTitle"
              defaultMessage="Tractament"
            />
          </Title>
          <TractamentTable tractaments={invoice.tractaments} readOnly />
        </div>
      )}

      <div style={{ height: 48 }} />

      <InvoiceSummary
        totalAmountExVat={invoice.totalExVat.valueInCent}
        totalRotAmount={invoice.totalRot.valueInCent}
        totalVatAmount={invoice.totalVat.valueInCent}
        reimbursementsSum={
          invoice.reimbursementAmount && invoice.reimbursementAmount.valueInCent
        }
        tractamentsSum={
          invoice.tractamentAmount && invoice.tractamentAmount.valueInCent
        }
        travelExpensesSum={
          invoice.travelExpenseAmount && invoice.travelExpenseAmount.valueInCent
        }
        serviceFeePercentage={
          invoice.serviceFeePercentage || user.serviceFeePercentage
        }
      />

      <div
        style={{
          marginTop: 24,
          display: isMobile ? 'block' : 'flex',
          justifyContent: 'flex-end',
          paddingBottom: 48,
        }}
      >
        {invoice.status === 'draft' && <SendInvoiceButton invoice={invoice} />}
        <DraftInvoiceButton invoice={invoice} />

        {invoice.status === 'draft' && (
          <Link to={`/invoices/${invoice.id}/edit`}>
            <FancyButton variant="secondary" style={{ marginRight: 10 }}>
              <FormattedMessage
                key="InvoiceView.editButton"
                defaultMessage="Edit"
              />
            </FancyButton>
          </Link>
        )}

        <a href={invoice.previewUrl} rel="noopener noreferrer" target="_blank">
          <FancyButton variant="secondary" style={{ marginRight: 10 }}>
            <FormattedMessage
              key="InvoiceView.previewButton"
              defaultMessage="Preview"
            />
          </FancyButton>
        </a>

        {invoice.status === 'draft' && (
          <DeleteInvoiceDraftButton invoice={invoice} />
        )}
        {invoice.status !== 'draft' && (
          <CreditInvoiceButton invoice={invoice} />
        )}
      </div>
    </div>
  )
})
