import React, { useEffect, useState } from 'react'
import { setLocale } from '../../lib/locale'
import { useLocation } from 'react-router-dom'

export default function EmbedLayout({ children }) {
  const location = useLocation()
  const [localeSet, setLocaleSet] = useState(false)

  const params = new URLSearchParams(location.search)

  useEffect(() => {
    if (localeSet) return
    setLocaleSet(true)
    params.get('locale') && setLocale(params.get('locale'))
  }, [localeSet, params])

  return (
    <div
      style={{
        height: '100vh',
        backgroundColor: `#${params.get('backgroundColor')}` || 'white',
      }}
    >
      {children}
    </div>
  )
}
