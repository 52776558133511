import React, { Component } from 'react'
import { Typography } from 'antd'
import { Redirect, Link } from 'react-router-dom'
import { withAuth } from 'services/auth'
import SignupForm from 'components/SignupForm/SignupForm'
import LoginFineprint from 'components/LoginFineprint/LoginFineprint'
import BankidSign from 'components/BankidSign/BankidSign'
import TabsBankidEmail from 'components/TabsBankidEmail/TabsBankidEmail'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'SignupView.title',
    defaultMessage: 'Create account',
  },
  loginInfo: {
    id: 'SignupView.loginInfo',
    defaultMessage: 'Already got an account?',
  },
  loginAction: {
    id: 'SignupView.loginAction',
    defaultMessage: 'Log in instead',
  },
  successMessage: {
    id: 'SignupView.successMessage',
    defaultMessage: 'Welcome {email}',
  },
})

const { Title } = Typography

class SignupEmailView extends Component {
  state = {
    fieldErrors: {},
    saving: false,
  }
  saveFormRef = (formRef) => {
    this.formRef = formRef
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = this.formRef.props.form

    form.validateFieldsAndScroll(async (err, values) => {
      if (err) return
      this.setState({ saving: true })
      const { errors } = await this.props.signupAndLogin(values)
      this.setState({ saving: false })
      if (errors) {
        this.setState({ fieldErrors: errors })
      }
    })
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />
    }

    const { handleLoginRes } = this.props

    return (
      <>
        <Title level={2}>
          <FormattedMessage {...messages.title} />
        </Title>

        <TabsBankidEmail
          bankidPane={<BankidSign onCompleted={handleLoginRes} />}
          emailPane={
            <SignupForm
              wrappedComponentRef={this.saveFormRef}
              fieldErrors={this.state.fieldErrors}
              onSubmit={this.handleSubmit}
              saving={this.state.saving}
            />
          }
        />

        <p style={{ marginTop: 50 }}>
          <FormattedMessage {...messages.loginInfo} />{' '}
          <Link to={'/login'}>
            <FormattedMessage {...messages.loginAction} />
          </Link>
        </p>
        <LoginFineprint />
      </>
    )
  }
}

export default injectIntl(withAuth(SignupEmailView))
