import React, { Component } from 'react'
import { Form, Input } from 'antd'
import withRouterAndRef from 'hocs/withRouterAndRef'
import withFormDecorator from '.../../hocs/with-form-decorator'
import FancyButton from '../FancyButton/FancyButton'

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

const messages = defineMessages({
  emailPlaceholder: {
    id: 'LoginForm.emailPlaceholder',
    defaultMessage: 'Email'
  },
  passwordPlaceholder: {
    id: 'LoginForm.passwordPlaceholder',
    defaultMessage: 'Password'
  },
  emailErrorMessage: {
    id: 'LoginForm.emailErrorMessage',
    defaultMessage: 'Please input your email!'
  },
  passwordErrorMessage: {
    id: 'LoginForm.passwordErrorMessage',
    defaultMessage: 'Please input your Password!'
  },
  cta: {
    id: 'LoginForm.cta',
    defaultMessage: 'Log in'
  }
})

class LoginForm extends Component {
  render() {
    const { form, onSubmit, saving, intl, history } = this.props
    const { getFieldDecorator } = form

    return (
      <>
        <Form onSubmit={onSubmit} layout="vertical">
          <Form.Item style={{ marginBottom: 8 }}>
            {getFieldDecorator('email', {
              initialValue: history.location.state && history.location.state.email,
              rules: [{ required: true, message: intl.formatMessage(messages.emailErrorMessage) }]
            })(<Input type="email" placeholder={intl.formatMessage(messages.emailPlaceholder)} autoComplete="username" size="large" />)}
          </Form.Item>
          <Form.Item style={{ marginBottom: 8 }}>
            {getFieldDecorator('password', {
              rules: [{ required: true, message: intl.formatMessage(messages.passwordErrorMessage) }]
            })(<Input.Password autoComplete="current-password" placeholder={intl.formatMessage(messages.passwordPlaceholder)} size="large" />)}
          </Form.Item>
          <Form.Item style={{ marginBottom: 0 }}>
            <FancyButton size="large" type="submit" block loading={saving}>
              <FormattedMessage {...messages.cta} />
            </FancyButton>
          </Form.Item>
        </Form>
      </>
    )
  }
}

export default injectIntl(withFormDecorator(withRouterAndRef(LoginForm)))
