import { message } from 'antd'
import InvoiceForm from 'components/InvoiceForm/form'
import listInvoices from 'components/InvoiceTable/listInvoices.gql'
import useEmployee from 'hooks/useEmployee'
import getInvoicesForWidget from 'components/InvoicesWidget/getInvoicesForWidget.gql'
import React from 'react'
import { Mutation } from 'react-apollo'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import editInvoiceMutation from './editInvoiceMutation.gql'
import { useHistory } from 'react-router-dom'

const InvoiceFormWithMutation = ({ invoice }) => {
  const intl = useIntl()
  const employee = useEmployee()
  const { id } = useParams()
  const history = useHistory()

  return (
    <Mutation
      mutation={editInvoiceMutation}
      awaitRefetchQueries={true}
      refetchQueries={[
        { query: listInvoices },
        { query: getInvoicesForWidget }
      ]}
      onCompleted={({ editInvoice: { errors } }) => {
        if (errors) {
          message.error(errors.map(({ message }) => message).join(', '))
        } else {
          message.success(
            intl.formatMessage({
              id: 'views.user.invoices.edit.successMessage',
              defaultMessage: 'Your invoice has been updated'
            })
          )
          history.push(`/invoices/${invoice.id}`)
        }
      }}
    >
      {(mutate, { loading }) => (
        <InvoiceForm
          readyToInvoice={employee && employee.readyToInvoice}
          invoice={invoice}
          loading={loading}
          onSave={input => {
            mutate({ variables: { input, id, send: false } })
          }}
          onSend={input => {
            mutate({ variables: { input, id, send: true } })
          }}
        />
      )}
    </Mutation>
  )
}

export default InvoiceFormWithMutation
