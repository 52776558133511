const taxYearConfigs = {
  2016: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2017: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2018: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2019: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2020: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2021: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2022: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2023: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2024: { socialFeePercentage: 31.42, taxPercentage: 30 },
  2025: { socialFeePercentage: 31.42, taxPercentage: 30 },
}

function calculate(
  invoiceAmountExVat,
  { serviceFeePercentage = 3.49, taxYear } = {},
  { reimbursementsSum = 0, tractamentsSum = 0, travelExpensesSum = 0 } = {}
) {
  if (!taxYearConfigs[taxYear]) {
    throw new Error(
      'Invalid tax year specified, allowed values are ' +
        Object.keys(taxYearConfigs).join(', ')
    )
  }

  const { socialFeePercentage, taxPercentage } = taxYearConfigs[taxYear]

  const serviceFee =
    -1 * Math.round(invoiceAmountExVat * (serviceFeePercentage / 100))

  const amountAfterFee = invoiceAmountExVat + serviceFee // Since fee is already a negative number!

  const amountWithoutTaxFreeItems =
    amountAfterFee - (reimbursementsSum + tractamentsSum + travelExpensesSum)

  const socialFeeDivider = ((socialFeePercentage + 100) / 100).toFixed(4)

  const grossSalary =
    Math.round(amountWithoutTaxFreeItems / 100 / socialFeeDivider) * 100 // Divide by 100 and then multiply to get rid of

  const socialFees = (amountWithoutTaxFreeItems - grossSalary) * -1

  const taxMultiplier = (taxPercentage / 100).toFixed(4)

  const tax = Math.floor((grossSalary / 100) * taxMultiplier) * -100
  const netSalary = grossSalary + tax // Tax is already a negative number

  const totalSalary =
    netSalary + reimbursementsSum + tractamentsSum + travelExpensesSum

  return {
    invoiceAmountExVat,
    serviceFeePercentage,
    amountWithoutTaxFreeItems,

    serviceFee,
    socialFees,
    grossSalary,

    // holidayAllowance,
    tax,
    netSalary,

    reimbursementsSum,
    tractamentsSum,
    travelExpensesSum,

    totalSalary,
  }
}

module.exports = {
  calculate,
}
