import React, { useState, useEffect } from 'react'
import { Form, Input, Select } from 'antd'
import { FormattedMessage } from 'react-intl'
const { Option } = Select

function RotForm({
  form,
  onAddReimbursement,
  onRotChange,
  rot = {},
  setValid,
}) {
  const [propertyType, setPropertyType] = useState('apartment')
  const { getFieldDecorator, getFieldValue } = form
  const propertyDesignation = getFieldValue('propertyDesignation')
  const residenceAssociationOrgNo = getFieldValue('residenceAssociationOrgNo')

  useEffect(() => {
    if (propertyType === 'apartment') {
      setValid(propertyDesignation && residenceAssociationOrgNo)
    }
    if (propertyType === 'house') {
      setValid(propertyDesignation)
    }
  }, [propertyType, propertyDesignation, residenceAssociationOrgNo, setValid])

  const handleSubmit = (e) => {
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onAddReimbursement(values)
        form.resetFields()
      }
    })
  }

  const onChangePropertyType = (propertyType) => {
    setPropertyType(propertyType)
    if (propertyType === 'house') {
      form.setFieldsValue({
        residenceAssociationOrgNo: '',
      })
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      onChange={(e) => {
        onRotChange(e.target.name, e.target.value)
      }}
    >
      <label className="ant-form-item-label">
        <FormattedMessage
          id="RotForm.Form.propertyTypeLabel"
          defaultMessage="Property type"
        />
      </label>
      <Select
        onChange={onChangePropertyType}
        value={propertyType}
        style={{ marginBottom: 24, marginTop: 4 }}
      >
        <Option value="house">
          <FormattedMessage
            id="RotForm.Form.propertyTypeHouse"
            defaultMessage="House"
          />
        </Option>
        <Option value="apartment">
          <FormattedMessage
            id="RotForm.Form.propertyTypeApartment"
            defaultMessage="Apartment"
          />
        </Option>
      </Select>

      <Form.Item
        label={
          propertyType === 'apartment' ? (
            <FormattedMessage
              id="RotForm.Form.propertyDesignationApartmentLabel"
              defaultMessage="Apartment number"
            />
          ) : (
            <FormattedMessage
              id="RotForm.Form.propertyDesignationHouseLabel"
              defaultMessage="House identification"
            />
          )
        }
      >
        {getFieldDecorator('propertyDesignation', {
          value: (rot && rot.propertyDesignation) || '',
          rules: [
            {
              required: true,
              message: (
                <FormattedMessage
                  id="RotForm.Form.propertyDesignationRequired"
                  defaultMessage="This is required"
                />
              ),
            },
          ],
        })(<Input name="propertyDesignation" />)}
      </Form.Item>

      {propertyType === 'apartment' && (
        <Form.Item
          label={
            <FormattedMessage
              id="RotForm.Form.brfOrgNoLabel"
              defaultMessage="Organization number of BRF"
            />
          }
        >
          {getFieldDecorator('residenceAssociationOrgNo', {
            value: (rot && rot.residenceAssociationOrgNo) || '',
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="RotForm.Form.brfOrgNoRequired"
                    defaultMessage="Organization number of BRF is required"
                  />
                ),
              },
            ],
          })(<Input name="residenceAssociationOrgNo" />)}
        </Form.Item>
      )}
    </Form>
  )
}

const WrappedRotForm = Form.create({ name: 'RotForm' })(RotForm)

export default WrappedRotForm
