import React, { Component } from 'react'
import styles from './auth-layout.module.css'
import { LocalePicker } from '../../lib/locale'
import bgImage from './signup-img.jpg'
import { BrandLogo, tagLine } from 'components/Brand/Brand'
import Zendesk from 'components/Zendesk/Zendesk'

class AuthLayout extends Component {
  render() {
    return (
      <>
        <div className={styles.wrapper}>
          <div className={styles.main}>
            <header className={styles.header}>
              <LocalePicker />
            </header>
            <main className={styles.content}>{this.props.children}</main>
            <footer className={styles.footer}>
              <BrandLogo className={styles.footerLogo} />
            </footer>
          </div>
          <aside className={styles.secondary}>
            <div className={styles.bg}>
              <div className={styles.bgColor} />
              <img src={bgImage} alt="" className={styles.bgImage} />
            </div>
            <div className={styles.tagLine}>{tagLine}</div>
          </aside>
        </div>
        <Zendesk />
      </>
    )
  }
}

export default AuthLayout
