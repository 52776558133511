import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import './SalaryCalculator.css'
import { Card, Button, Descriptions, Icon, Slider } from 'antd'
import { calculate } from '../../lib/shared/salary-calculator'
import { FormattedAmount } from 'lib/locale'
import FancyButton from '../FancyButton/FancyButton'

const lowerLimit = 50000
const upperLimit = 10000000
const defaultValue = 1000000
const taxYear = new Date().getFullYear()

const SalaryPreview = ({ values }) => {
  const { invoiceAmountExVat, netSalary } = values
  return (
    <div style={{ textAlign: 'center' }}>
      <span>
        <FormattedMessage key="summary1" defaultMessage="Invoiced amount of" />{' '}
      </span>
      <strong className="salary-calculator__large-amount">
        <FormattedAmount valueInCent={invoiceAmountExVat} currency={'SEK'} />{' '}
      </strong>
      <span>
        <FormattedMessage key="summary2" defaultMessage="gives you" />{' '}
      </span>
      <strong className="salary-calculator__large-amount">
        <FormattedAmount valueInCent={netSalary} currency={'SEK'} />{' '}
      </strong>
      <span>
        <FormattedMessage key="summary3" defaultMessage="in salary" />
      </span>
    </div>
  )
}

const DetailedSalaryPreview = ({ values, onClose }) => {
  const {
    grossSalary,
    invoiceAmountExVat,
    netSalary,
    serviceFee,
    serviceFeePercentage,
    socialFees,
    tax,
  } = values
  return (
    <>
      <Descriptions column={1} size={'middle'}>
        <Descriptions.Item
          label={
            <FormattedMessage
              key="invoiceAmountExVat"
              defaultMessage="Invoice amount (ex VAT)"
            />
          }
        >
          <FormattedAmount valueInCent={invoiceAmountExVat} currency={'SEK'} />
        </Descriptions.Item>
        <Descriptions.Item
          label={<FormattedMessage key="serviceFee" defaultMessage="Our fee" />}
        >
          <FormattedAmount valueInCent={serviceFee} currency={'SEK'} />
          {` (${serviceFeePercentage}%)`}
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <FormattedMessage key="socialFees" defaultMessage="Social fees" />
          }
        >
          <FormattedAmount valueInCent={socialFees} currency={'SEK'} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <FormattedMessage key="grossSalary" defaultMessage="Gross salary" />
          }
        >
          <FormattedAmount valueInCent={grossSalary} currency={'SEK'} />
        </Descriptions.Item>
        <Descriptions.Item
          label={<FormattedMessage key="tax" defaultMessage="Tax" />}
        >
          <FormattedAmount valueInCent={tax} currency={'SEK'} />
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <FormattedMessage key="netSalary" defaultMessage="Net salary" />
          }
        >
          <FormattedAmount valueInCent={netSalary} currency={'SEK'} />
        </Descriptions.Item>
      </Descriptions>
      <div style={{ textAlign: 'center' }}>
        <FancyButton variant="secondary" onClick={onClose}>
          <FormattedMessage key="hide" defaultMessage="Hide" />
        </FancyButton>
      </div>
    </>
  )
}

class SalaryCalculator extends Component {
  state = {
    invoiceAmount: defaultValue,
    expanded: false,
    values: calculate(defaultValue, {
      taxYear,
      serviceFeePercentage: this.props.user
        ? this.props.user.serviceFeePercentage
        : undefined,
    }),
  }

  updateInvoiceAmount = (invoiceAmount) => {
    const serviceFeePercentage = this.props.user
      ? this.props.user.serviceFeePercentage
      : undefined
    this.setState({
      invoiceAmount,
      values: calculate(invoiceAmount, { taxYear, serviceFeePercentage }),
    })
  }

  onToggleDetails = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  render() {
    const { invoiceAmount, expanded, values } = this.state

    return (
      <>
        {!this.props.hideTitle && (
          <h2 style={{ fontWeight: 700 }}>
            <FormattedMessage
              key="title"
              defaultMessage="Calculate your salary"
            />
          </h2>
        )}
        <Card
          bordered={false}
          className="salary-calculator"
          style={{
            marginBottom: 42,
            backgroundColor: this.props.backgroundColor || '#e5f5ef',
          }}
        >
          <SalaryPreview values={values} />
          <Slider
            defaultValue={defaultValue}
            min={lowerLimit}
            max={upperLimit}
            value={invoiceAmount}
            onChange={this.updateInvoiceAmount}
            step={50000}
            tooltipVisible={false}
            tipFormatter={(val) => Math.round(val / 100)}
          />
          <div>
            {expanded ? (
              <div className="salary-calculator__details">
                <DetailedSalaryPreview
                  values={values}
                  onClose={this.onToggleDetails}
                />
              </div>
            ) : (
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="link"
                  onClick={this.onToggleDetails}
                  className="salary-calculator__more-button"
                >
                  <FormattedMessage
                    key="buttonSeeHow"
                    defaultMessage="See our calculation"
                  />{' '}
                  <Icon type="down" style={{ fontSize: 12 }} />
                </Button>
              </div>
            )}
          </div>
        </Card>
      </>
    )
  }
}

export default SalaryCalculator
