import { List } from 'antd'
import { FormattedAmount } from 'lib/locale'
import React from 'react'
import { Query } from 'react-apollo'
import { defineMessages, FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import InvoiceStatusIcon from '../InvoiceStatusIcon/InvoiceStatusIcon'
import InvoiceStatusText from '../InvoiceStatusText/InvoiceStatusText'
import getInvoicesForWidget from './getInvoicesForWidget.gql'

const messages = defineMessages({
  showEverything: {
    id: 'InvoicesWidget.showEverything',
    defaultMessage: 'Show all invoices'
  }
})

const InvoiceItem = ({ invoice }) => (
  <List.Item
    actions={[
      <Link to={`/invoices/${invoice.id}`} style={{ color: '#333' }}>
        <FormattedAmount {...invoice.totalIncVat} />
      </Link>
    ]}
  >
    <List.Item.Meta
      avatar={
        <Link to={`/invoices/${invoice.id}`}>
          <InvoiceStatusIcon status={invoice.status} />
        </Link>
      }
      title={
        <Link to={`/invoices/${invoice.id}`} style={{ color: '#333' }}>
          {invoice.customerName}
        </Link>
      }
      description={
        <Link to={`/invoices/${invoice.id}`} style={{ color: '#333' }}>
          <InvoiceStatusText invoice={invoice} />
        </Link>
      }
    />
  </List.Item>
)

const InvoicesWidget = ({ invoices, loading }) => {
  const data = loading ? [] : invoices.edges.map(edge => edge.node)

  return (
    <List
      itemLayout="horizontal"
      locale={{ emptyText: <FormattedMessage key="emptyText" defaultMessage="Once you have created your first invoice it will show up here" /> }}
      dataSource={data}
      loading={loading}
      renderItem={invoice => <InvoiceItem invoice={invoice} />}
      footer={
        data.length > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <Link to={'/invoices'}>
              <FormattedMessage {...messages.showEverything} />
            </Link>
          </div>
        ) : null
      }
    />
  )
}

const InvoicesWidgetWithData = () => (
  <Query query={getInvoicesForWidget}>
    {({ loading, error, data = {} }) => {
      if (error) return <div style={{ color: 'red' }}>Error loading invoices.</div>
      return <InvoicesWidget invoices={data && data.currentUser && data.currentUser.widgetInvoices} loading={loading} />
    }}
  </Query>
)

export default InvoicesWidgetWithData
