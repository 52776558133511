import React, { Component } from 'react'
import { withAuth } from 'services/auth'
import PageLayout from '../../components/PageLayout/PageLayout'
import ActivityList from '../../components/ActivityList/ActivityList'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.Activities.pageTitle',
    defaultMessage: 'All Activity'
  }
})

class ActivitiesView extends Component {
  render() {
    return (
      <PageLayout pageTitleKey={messages.pageTitle}>
        <ActivityList />
      </PageLayout>
    )
  }
}

export default withAuth(ActivitiesView)
