import React, { Component } from 'react'
import { Input, Icon } from 'antd'
import { bankName } from 'clearingnummer'

class ClearingnrInput extends Component {
  render () {
    const { value, ...props} = this.props
    const name = value && bankName(value)
  
    return  (
      <div>
        <Input {...props} value={value} />
        {name && (
          <div style={{ paddingLeft: 2, fontSize: 14 }}>
            <span role="img" aria-label="Checked icon"><Icon type="check" /></span>
            {' '}
            {name}
          </div>
        )}      
      </div>
    ) 
  }
}

export default ClearingnrInput