import React from 'react'
import { FormattedMessage } from 'react-intl'
import Form from './form'
import { Divider } from 'antd'
import InvoiceAddonTwoColumn from '../InvoiceAddonTwoColumn/InvoiceAddonTwoColumn'
import TravelExpensesTable from '../TravelExpensesTable/TravelExpensesTable'

const TravelExpensesForm = ({
  travelExpenses,
  onAddTravelExpense,
  onRemoveTravelExpense,
}) => {
  const rightColumn = () => (
    <>
      <h3>
        <FormattedMessage
          key="TravelExpensesForm.intoTitle"
          defaultMessage="Om Resersättning"
        />
      </h3>
      <p>
        <FormattedMessage
          key="TravelExpensesForm.infoText1"
          defaultMessage="För att Skatteverket ska godkänna reseersättning krävs att två villkor är uppfyllda:"
        />
      </p>
      <p>
        <FormattedMessage
          key="TravelExpensesForm.infoText2"
          defaultMessage="1. Avståndet mellan bostaden och arbetsplatsen ska vara minst 5 kilometer."
        />
      </p>
      <p>
        <FormattedMessage
          key="TravelExpensesForm.infoText3"
          defaultMessage="2. Tidsvinsten av att använda bil ska vara minst två timmar per dag, för tur och retur, jämfört med om kollektiva färdmedel hade använts."
        />
      </p>
      <p>
        <a
          href="https://konsultfaktura.zendesk.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage
            key="TravelExpensesForm.infoTextCta"
            defaultMessage="Läs med om reseersättning här."
          />
        </a>
      </p>
    </>
  )

  return (
    <>
      <InvoiceAddonTwoColumn rightColumn={rightColumn()}>
        <h3>
          <FormattedMessage
            key="TravelExpensesForm.title"
            defaultMessage="Resersättning"
          />
        </h3>
        <Form onAddTravelExpense={onAddTravelExpense} />
      </InvoiceAddonTwoColumn>

      {travelExpenses && travelExpenses.length > 0 && (
        <div style={{ overflowX: 'scroll' }}>
          <TravelExpensesTable
            travelExpenses={travelExpenses}
            onRemoveTravelExpense={onRemoveTravelExpense}
          />
        </div>
      )}

      <Divider style={{ marginTop: 40, marginBottom: 70 }} />
    </>
  )
}

export default TravelExpensesForm
