import React from 'react'
import { List } from 'antd'
import userListActivities from './userListActivities.gql'
import ApolloQueryLoadMore from '../ApolloQueryLoadMore'
import ActivityListItem from '../ActivityListItem/ActivityListItem'

const ActivityList = () => {
  return (
    <ApolloQueryLoadMore queryProps={{ query: userListActivities }} dataRef="currentUser.activityLog" showEmpty={false}>
      {({ dataSource, loading }) => <List itemLayout="horizontal" loading={loading} dataSource={dataSource} renderItem={item => <ActivityListItem item={item} />} />}
    </ApolloQueryLoadMore>
  )
}

export default ActivityList
