import { message, Popconfirm } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import React from 'react'
import { Mutation } from 'react-apollo'
import listInvoices from '../InvoiceTable/listInvoices.gql'
import getInvoice from '../InvoiceView/getInvoice.gql'
import FancyButton from '../FancyButton/FancyButton'
import creditInvoice from './creditInvoice.gql'

const CreditInvoiceButton = ({ invoice }) => {
  const intl = useIntl()

  if (!invoice) return null

  const { id, status } = invoice

  if (!['approved', 'readyForApproval'].includes(status)) {
    return null
  }

  return (
    <Mutation
      mutation={creditInvoice}
      variables={{ id }}
      refetchQueries={[
        { query: getInvoice, variables: { id } },
        { query: listInvoices },
      ]}
      awaitRefetchQueries={true}
      onCompleted={({ creditInvoice: { errors } }) => {
        if (errors) {
          message.error(errors.map(({ message }) => message).join(', '))
        } else {
          message.success(
            intl.formatMessage({
              id: 'components.CreditInvoiceButton.successMessage',
              defaultMessage: 'Invoice was credited',
            })
          )
        }
      }}
    >
      {(mutate, { loading }) => (
        <Popconfirm
          title={
            <FormattedMessage
              key="confirmTitle"
              defaultMessage="Are you sure you want to credit this invoice?"
            />
          }
          onConfirm={() => mutate()}
          okText={intl.formatMessage({
            id: 'components.CreditInvoiceButton.okText',
            defaultMessage: 'Yes',
          })}
          cancelText={intl.formatMessage({
            id: 'components.CreditInvoiceButton.cancelText',
            defaultMessage: 'No',
          })}
        >
          <FancyButton variant="secondary" type="warning" loading={loading}>
            <FormattedMessage
              key="creditButton"
              defaultMessage="Credit invoice"
            />
          </FancyButton>
        </Popconfirm>
      )}
    </Mutation>
  )
}

export default CreditInvoiceButton
