import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  PRIVATE: {
    id: 'CustomerTypeText.private',
    defaultMessage: 'Individual',
  },
  COMPANY: {
    id: 'CustomerTypeText.company',
    defaultMessage: 'Company',
  },
})

const CustomerTypeText = ({ text }) => {
  return <FormattedMessage {...messages[text]} />
}

export default CustomerTypeText
