import React, { Component } from 'react'
import { Query } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import dotProp from 'dot-prop-immutable'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Table, Empty, Icon, Row } from 'antd'
import SyntaxHighlighter from 'components/SyntaxHighlighter/SyntaxHighlighter'
import { tablePagination } from 'lib/pagination-helpers'
import SearchFilter from './SearchFilter'
import CheckboxesFilter from './CheckboxesFilter'
import FilterButtons from './FilterButtons'
import _ from 'lodash'
import FancyButton from 'components/FancyButton/FancyButton'
/* Example usage

1. You must make sure to allow $skip variable on your query. And add ...pagination fragment
#import "../../fragments/pagination.gql"

query ListUsers($skip: Int) {
  admin {
    users(skip: $skip) @connection(key: "adminUsers") { {
      ...pagination

2. Add in your component
// queryProps: react-apollo <Query /> props
// tableProps: antd <Table /> props
// paginationProps: antd <Pagination /> props
// dataRef: dotProp string of where the collectiton is in the graphql response
// emptyText: String when no results are found. Defaults to "No results found"
// debug: Shows json state data for table above table
// reloadButton: Shows a button to reload table data

import ApolloQueryTable from "components/ApolloQueryTable"

<ApolloQueryTable queryProps={{ query: listUsers }} dataRef="admin.users" tableProps={{ columns }} />

Extras:
  - Add sorting possibility
    1. Add to query
      query ListUsers($skip: Int, $sort: [SortInput!]) {
        admin {
          users(skip: $skip, sort: $sort) @connection(key: "adminInvoices", filter: ["sort"]) { {
            ...pagination
    2. For the sortable columns add sorter: true
      {
        title: 'Name',
        ...,
        sorter: true
      }
  - Add checkboxes filter possibility
    1. Add to query
      query ListUsers($skip: Int, $filter: String) {
        admin {
          users(skip: $skip, filter: $filter) @connection(key: "adminInvoices", filter: ["filter"]) { {
            ...pagination
    2. For the filterable columns add graphqlFilters: []
      {
        title: 'Roles',
        ...,
        graphqlFilters: [
          {
            type: 'checkboxes',
            checkboxesOptions: {
              multiple: false,
              operator: '$includes',
              options: [{ text: 'Superadmin', value: 'superadmin' }, { text: 'Admin', value: 'admin' }]
            }
          }
        ]
      }
  - Add search filter possibility
    1. Add to query
      query ListUsers($skip: Int, $filter: String) {
        admin {
          users(skip: $skip, filter: $filter) @connection(key: "adminInvoices", filter: ["filter"]) { {
            ...pagination
    2. For the filterable columns add graphqlFilters: []
      {
        title: 'Name',
        ...,
        graphqlFilters: [{type: 'search'}
        ]
      }

*/

class ApolloQueryTable extends Component {
  state = {
    variables: {},
    mounted: false,
    filter: {},
  }
  constructor(props) {
    super(props)
    if (!this.props.dataRef) {
      throw new Error('ApolloQueryTable requires dataRef prop')
    }

    if (!this.props.queryProps.query) {
      throw new Error(
        'ApolloQueryTable requires queryProps to have a query. Example queryProps={{query: listUsers}}'
      )
    }
    if (!this.props.queryProps.fetchPolicy) {
      this.props.queryProps.fetchPolicy = 'cache-and-network'
    }

    const checkRequiredQueryBodyString = (string) => {
      if (this.props.queryProps.query.loc.source.body.indexOf(string) === -1) {
        throw new Error(
          `ApolloQueryTable requires the query to contain ${string}`
        )
      }
    }

    checkRequiredQueryBodyString('fragments/pagination.gql')
    checkRequiredQueryBodyString('$skip: Int')
  }
  componentDidMount() {
    const { queryProps = {}, location } = this.props
    // Set up params object for modifying get params
    const params = new URLSearchParams(location.search)
    this.setState({ params })

    // Set up a  state for pagination based on the params
    const paginationState = this.getPaginationStateFromParams(params)

    // Set up a  state for sort based on the params
    const sortState = this.getSortStateFromParams(params)

    // Set up a  state for filtering based on the params
    const filter =
      queryProps.variables && queryProps.variables.filter
        ? queryProps.variables.filter
        : '{}'
    const { variableFilter, stateFilter } = this.getFilterStateFromParams(
      filter,
      params
    )

    // Set query variables
    this.setState({
      variables: {
        ...queryProps.variables,
        ...paginationState,
        ...sortState,
        filter: variableFilter,
      },
      filter: stateFilter,
      mounted: true,
    })
  }

  // Gets ?skip=200 and adds it into the pagination state
  getPaginationStateFromParams = (params) => {
    const skip = parseInt(params.get(`${this.props.dataRef}.skip`))
    return { skip }
  }

  // Gets ?sort=documentNumber_ASC and turns it into the sort state
  getSortStateFromParams = (params) => {
    const sortParam = params.get(`${this.props.dataRef}.sort`)

    // Split up between field and order for sorting (documentNumber_ASC => {field: 'documentNumber', order: 'ASC'}
    const sortSplit = sortParam ? sortParam.split('_') : []
    const sort = sortParam ? [{ field: sortSplit[0], order: sortSplit[1] }] : []

    return { sort }
  }

  // Gets ?filter=JSONDATA and turns it into the filter state
  getFilterStateFromParams = (variableFilter, params) => {
    const paramFilter = params.get(`${this.props.dataRef}.filter`)
    let filter
    if (paramFilter) {
      filter = { ...JSON.parse(variableFilter), ...JSON.parse(paramFilter) }
    } else {
      filter = JSON.parse(variableFilter)
    }
    return { variableFilter: JSON.stringify(filter), stateFilter: filter }
  }

  // Triggered when changing pagination, filters or sorting table
  handleTableChange = (_pagination, _filterer, sorter) => {
    // On sort changes
    const sortChangeRes = this.handleSortChange(sorter, this.state.params)
    const sort = sortChangeRes ? sortChangeRes.sort : this.state.variables.sort

    // Do not update history if no changes
    if (_.isEqual(sort, this.state.variables.sort)) {
      return
    }

    // Reset skip when changing filters or sorting
    this.state.params.delete(`${this.props.dataRef}.skip`)

    // Set url params to reflect the filter
    this.props.history.push({ search: this.state.params.toString() })

    // Set state to reflect filter and sort. Reset skip to 0 after changes
    this.setState({ variables: { ...this.state.variables, sort, skip: 0 } })
  }

  // Triggered when changes to field sorting
  handleSortChange = (sorter) => {
    // Take antd tables sot data and modify it into our graphql sort
    const sort =
      sorter && sorter.field
        ? [
            {
              field: sorter.column.key,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC',
            },
          ]
        : []

    if (sort[0]) {
      this.state.params.set(
        `${this.props.dataRef}.sort`,
        `${sort[0].field}_${sort[0].order}`
      )
    } else {
      this.state.params.delete(`${this.props.dataRef}.sort`)
    }

    return { sort }
  }

  // Triggered when changes to field filtering
  handleFilterChange = (confirm) => {
    const jsonFilter = JSON.stringify(this.state.filter)

    if (jsonFilter) {
      this.state.params.set(`${this.props.dataRef}.filter`, jsonFilter)
    } else {
      this.state.params.delete(`${this.props.dataRef}.filter`)
    }
    // Set url params to reflect the filter
    this.props.history.push({ search: this.state.params.toString() })

    // Hide dropdown
    confirm()

    return this.setState({
      variables: { ...this.state.variables, filter: jsonFilter },
    })
  }

  // Resets filtering to default
  clearFilter = (column, clearFilters) => {
    const newFilter = this.state.filter
    delete newFilter[column.key]
    const jsonFilter = JSON.stringify(newFilter)
    this.state.params.set(`${this.props.dataRef}.filter`, jsonFilter)

    // Set url params to reflect the filter
    this.props.history.push({ search: this.state.params.toString() })

    // Hide dropdown
    clearFilters()

    return this.setState({
      variables: { ...this.state.variables, filter: jsonFilter },
      filter: newFilter,
    })
  }

  // Maps through the columns for ant table and adds sorting info
  addSortInfoToColumns(columns) {
    return columns.map((column) => {
      const sort = this.state.variables.sort && this.state.variables.sort[0]

      if (!sort) {
        return { ...column, sortOrder: false }
      }
      if (sort.field === column.key) {
        return {
          ...column,
          sortOrder: sort.order === 'ASC' ? 'ascend' : 'descend',
        }
      }

      return { ...column, sortOrder: false }
    })
  }

  // Maps through the columns for ant table and adds filter info
  addFilterInfoToColumns(columns) {
    return columns.map((column) => {
      return this.addFilterInfoToColumn(column)
    })
  }

  filterTypes = {
    checkboxes: {
      component: CheckboxesFilter,
      optionsKey: 'checkboxesOptions',
    },
    search: {
      component: SearchFilter,
      optionsKey: 'searchOptions',
      filterIcon: (filtered) => (
        <Icon
          type="search"
          style={{ color: filtered ? '#1da57a' : undefined }}
        />
      ),
    },
  }

  // Add filter info to a column
  addFilterInfoToColumn(column) {
    let newColumn = column

    const graphqlFilters = column.graphqlFilters && column.graphqlFilters

    if (!graphqlFilters) {
      return newColumn
    }

    // Add filter dropdown
    newColumn.filterDropdown = ({ confirm, clearFilters }) => {
      const filterDropDownItemsRes = this.filterDropdownItems({
        newColumn,
        column,
        graphqlFilters,
        confirm,
        clearFilters,
      })
      newColumn = filterDropDownItemsRes.newColumn

      return [
        ...filterDropDownItemsRes.filterDropdownItems,
        <FilterButtons
          key="filterButtons"
          ok={() => this.handleFilterChange(confirm)}
          clear={() => this.clearFilter(column, clearFilters)}
        />,
      ]
    }

    // Fix so search inputt field gets focus
    newColumn.onFilterDropdownVisibleChange = (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput && this.searchInput.select())
      }
    }

    // Fix so the icon is highlighted when theree is a filter on column
    const filter = this.state.filter[column.key]
    if (filter && Object.keys(filter).length > 0) {
      newColumn.filteredValue = ['exampleValue'] // Ugly fix since filtered = true does not update icon
    } else {
      newColumn.filteredValue = []
    }

    return newColumn
  }

  // Get dropdown filter items from graphqlFilters array
  filterDropdownItems = ({ newColumn, column, graphqlFilters, confirm }) => {
    let filterDropdownItems = []

    graphqlFilters.forEach((graphqlFilter) => {
      const filterType = this.filterTypes[graphqlFilter.type]
      if (!filterType) {
        throw new Error(
          `Unknown graphqlFilter type ${
            graphqlFilter.type
          }. Available are ${Object.keus(this.filterTypes).join(',')}`
        )
      }

      const { component: FilterComponent, optionsKey, filterIcon } = filterType
      filterDropdownItems.push(
        <FilterComponent
          inputRef={(node) => {
            this.searchInput = node
          }}
          key="search"
          setFilter={(filter) => this.setColumnFilterState(column.key, filter)}
          currentFilter={this.state.filter[column.key] || {}}
          confirm={() => this.handleFilterChange(confirm)}
          options={graphqlFilter[optionsKey]}
        />
      )

      if (!column.filterIcon && filterIcon) {
        newColumn.filterIcon = filterIcon
      }
    })

    return { filterDropdownItems, newColumn }
  }

  // Updates the filter for a column
  setColumnFilterState(column, filter) {
    const newState = this.state.filter || {}

    if (Object.values(filter).filter(Boolean).length > 0) {
      newState[column] = filter
    } else {
      delete newState[column]
    }
    this.setState({ filter: newState })
  }

  render() {
    // Component renders once before mounted. Wait until mount since we need data from theere
    if (!this.state.mounted) {
      return null
    }

    const {
      queryProps = {},
      tableProps = {},
      dataRef,
      emptyText,
      history,
      debug,
      reloadButton,
      intl,
    } = this.props

    // Add sort info to columns. Such as if they should be sorted and in what order
    const columns = this.addFilterInfoToColumns(
      this.addSortInfoToColumns(tableProps.columns)
    )

    return (
      <>
        {debug && (
          <SyntaxHighlighter
            language="json"
            showLineNumbers={true}
            wrapLines={true}
          >
            {JSON.stringify(this.state, null, 2)}
          </SyntaxHighlighter>
        )}

        <Query
          {...{ ...queryProps, variables: this.state.variables }}
          notifyOnNetworkStatusChange
        >
          {({ loading, error, data = {}, fetchMore, refetch }) => {
            const collection = dotProp.get(data, dataRef)

            if (collection && collection.__typename !== 'Collection') {
              throw new Error(
                `ApolloQueryTable dataRef must point to a Graphql Collection type. But it points to ${collection.__typename}`
              )
            }

            const dataSource = (dotProp.get(collection, 'edges') || []).map(
              (edge) => edge.node
            )

            const mergedTableprops = {
              pagination:
                collection && collection.pageInfo
                  ? tablePagination({
                      intl,
                      collection,
                      fetchMore,
                      params: this.state.params,
                      dataRef,
                      history,
                    })
                  : {},
              loading,
              dataSource,
              onChange: this.handleTableChange,
              rowKey: 'id',
              locale: {
                emptyText: error ? (
                  <Empty
                    description={
                      <FormattedMessage
                        id="generic.couldNotLoad"
                        defaultMessage="Could not load data"
                      />
                    }
                  />
                ) : (
                  <Empty
                    description={
                      emptyText || (
                        <FormattedMessage
                          id="generic.emptyDataMessage"
                          defaultMessage="No results found"
                        />
                      )
                    }
                  />
                ),
              },
              ...tableProps,
              columns,
            }

            return (
              <>
                {reloadButton && (
                  <Row style={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <FancyButton
                      onClick={() => refetch()}
                      disabled={loading}
                      loading={loading}
                      style={{ marginBottom: '20px' }}
                    >
                      <FormattedMessage
                        key="reloadButton"
                        defaultMessage="Reload"
                      />
                    </FancyButton>
                  </Row>
                )}
                <Table {...mergedTableprops} />
              </>
            )
          }}
        </Query>
      </>
    )
  }
}
export default injectIntl(withRouter(ApolloQueryTable))
