import React from 'react'
import sendInvoice from './sendInvoice.gql'
import { Mutation } from 'react-apollo'
import { Tooltip, message } from 'antd'
import FancyButton from '../FancyButton/FancyButton'
import { FormattedMessage, useIntl } from 'react-intl'

import getInvoice from '../InvoiceView/getInvoice.gql'
import listInvoices from '../InvoiceTable/listInvoices.gql'

const SendInvoiceButton = ({ invoice }) => {
  const intl = useIntl()

  if (!invoice) return null

  const { id, status } = invoice

  if (status !== 'draft') {
    return (
      <Tooltip
        title={
          <FormattedMessage
            key="incorrectStatusText"
            defaultMessage="This invoice cannot be sent because it is not a draft"
            values={{ status }}
          />
        }
      >
        <FancyButton
          type="primary"
          variant="secondary"
          disabled
          style={{ marginRight: 10 }}
        >
          <FormattedMessage
            key="buttonText"
            defaultMessage="Send"
            values={{ status }}
          />
        </FancyButton>
      </Tooltip>
    )
  }

  return (
    <Mutation
      mutation={sendInvoice}
      variables={{ id }}
      refetchQueries={[
        { query: getInvoice, variables: { id } },
        { query: listInvoices },
      ]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        message.info(
          intl.formatMessage({
            id: 'components.SendInvoiceButton.successMessage',
            defaultMessage: 'Invoice sent',
            values: { status },
          })
        )
      }}
    >
      {(mutate, { loading }) => (
        <FancyButton
          variant="cta"
          type="primary"
          onClick={() => mutate()}
          style={{ marginRight: 10 }}
          loading={loading}
        >
          <FormattedMessage
            key="buttonText"
            defaultMessage="Send"
            values={{ status }}
          />
        </FancyButton>
      )}
    </Mutation>
  )
}

export default SendInvoiceButton
