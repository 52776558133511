import React, { Component } from 'react'
import { Icon, Typography, Popover } from 'antd'
import './SectionHeader.css'
import cn from 'classnames'

const { Title } = Typography

class SectionHeader extends Component {
  render() {
    const { title, help, border = false } = this.props
    return (
      <div
        className={cn('section-header', {
          'section-header--with-border': border,
        })}
      >
        <Title level={4}>{title}</Title>

        {help && (
          <Popover
            content={help}
            title={title}
            className="section-header__help"
          >
            <Icon type="question-circle" />
          </Popover>
        )}
      </div>
    )
  }
}

export default SectionHeader
