import React from 'react'
import { defineMessages, FormattedMessage } from 'react-intl'

const messages = defineMessages({
  draft: {
    id: 'InvoiceStatusText.draftInvoice',
    defaultMessage: 'Draft. Not yet sent'
  },
  readyForApproval: {
    id: 'InvoiceStatusText.readyForApproval',
    defaultMessage: 'Pending approval'
  },
  unpaid: {
    id: 'InvoiceStatusText.unpaidInvoice',
    defaultMessage: 'Unpaid. Due {dueDate}'
  },
  notApproved: {
    id: 'InvoiceStatusText.notApproved',
    defaultMessage: 'Not approved. Contact customer service.'
  },
  paid: {
    id: 'InvoiceStatusText.paidInvoice',
    defaultMessage: 'Paid'
  },
  credited: {
    id: 'InvoiceStatusText.creditedInvoice',
    defaultMessage: 'Credited'
  }
})

const InvoiceStatusText = ({ invoice }) => {
  const { status, dueDate } = invoice
  if (status === 'draft') return <FormattedMessage {...messages.draft} />
  if (status === 'notApproved') return <FormattedMessage {...messages.notApproved} />
  if (status === 'invoicePaid') return <FormattedMessage {...messages.paid} />
  if (status === 'credited') return <FormattedMessage {...messages.credited} />
  if (status === 'readyForApproval') return <FormattedMessage {...messages.readyForApproval} />
  if (status === 'approved') return <FormattedMessage {...messages.unpaid} values={{ dueDate }} />

  return <>{invoice.status}</>
}

export default InvoiceStatusText
