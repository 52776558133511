import React from 'react'
import { Modal, message } from 'antd'

class ModalExampleModal extends React.Component {
  handleCancel = () => {
    this.props.closeModal()
  }
  handleOk = () => {
    message.success('Clicked ok in modal')
    this.props.closeModal()
  }
  render() {
    return (
      <Modal
        title="Modal example"
        visible
        onCancel={this.handleCancel}
        onOk={this.handleCancel}
        maskClosable={true}
      >
        <h1>Modal content</h1>
      </Modal>
    )
  }
}

export default ModalExampleModal
