import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { getCurrentLocale } from './lib/locale'

const uri = process.env.REACT_APP_GRAPHQL_HOST || 'http://localhost:8080/graphql'

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('ghostToken') || localStorage.getItem('token')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'Accept-Language': getCurrentLocale().locale,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const errorLink = onError(({ operation, response, graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const detail = {
      type: 'graphQLError',
      errors: graphQLErrors,
      operation,
      response
    }
    window.dispatchEvent(new CustomEvent('graphqlError', { detail }))
  } else if (networkError) {
    const detail = {
      type: 'networkError',
      errors: [
        {
          message: networkError
        }
      ],
      operation,
      response
    }
    window.dispatchEvent(new CustomEvent('graphqlError', { detail }))
  }
})

const httpLink = new HttpLink({
  uri
})

const link = ApolloLink.from([errorLink, authLink, httpLink])

export const client = new ApolloClient({
  // By default, this client will send queries to the
  //  `/graphql` endpoint on the same host
  // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
  // to a different host
  link,
  cache: new InMemoryCache()
})
