import React from 'react'
import { Form, Input, InputNumber, DatePicker } from 'antd'
import { FormattedMessage } from 'react-intl'
import FancyButton from '../FancyButton/FancyButton'
import moment from 'moment'

const milesToSek = (miles) => Math.round(miles * 25 * 100)

class TravelExpensesForm extends React.Component {
  handleSubmit = (e) => {
    const { form, onAddTravelExpense } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { date, numberOfMiles, ...allValues } = values
        onAddTravelExpense({
          date: moment(date).format('YYYY-MM-DD'),
          amount: milesToSek(numberOfMiles),
          numberOfMiles,
          ...allValues,
        })
        form.resetFields()
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item
          label={
            <FormattedMessage
              id="TravelExpensesForm.Form.descriptionLabel"
              defaultMessage="Description of travel expense"
            />
          }
        >
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TravelExpensesForm.Form.descriptionRequired"
                    defaultMessage="Description is required"
                  />
                ),
              },
            ],
          })(<Input />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TravelExpensesForm.Form.dateLabel"
              defaultMessage="Date of travel expense"
            />
          }
        >
          {getFieldDecorator('date', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TravelExpensesForm.Form.dateRequired"
                    defaultMessage="Date is required"
                  />
                ),
              },
            ],
          })(<DatePicker format="YYYY-MM-DD" showTime={false} />)}
        </Form.Item>

        <Form.Item
          label={
            <FormattedMessage
              id="TravelExpensesForm.Form.numberOfMilesLabel"
              defaultMessage="Number of miles driven"
            />
          }
        >
          {getFieldDecorator('numberOfMiles', {
            rules: [
              {
                required: true,
                message: (
                  <FormattedMessage
                    id="TravelExpensesForm.Form.numberOfMilesRequired"
                    defaultMessage="Number of miles is required"
                  />
                ),
              },
            ],
          })(<InputNumber step={1} min={1} max={10000} />)}
        </Form.Item>

        <Form.Item style={{ marginTop: 24 }}>
          <FancyButton variant="cta" type="submit">
            <FormattedMessage
              id="TravelExpensesForm.Form.cta"
              defaultMessage="Add Travel Expense"
            />
          </FancyButton>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedTravelExpensesForm = Form.create({ name: 'TravelExpensesForm' })(
  TravelExpensesForm
)

export default WrappedTravelExpensesForm
