import React, { Component } from 'react'
import { Result, Button, Collapse, Typography, Card } from 'antd'
import dotProp from 'dot-prop-immutable'
import SyntaxHighlighter from 'components/SyntaxHighlighter/SyntaxHighlighter'
import './GraphqlError.css'

const { Panel } = Collapse
const { Paragraph, Text } = Typography

const Header = ({ children }) => (
  <Text strong style={{ fontSize: 16 }}>
    {children}
  </Text>
)

class GraphqlError extends Component {
  state = { error: null, hasBoundaryError: false, dismissed: false }

  componentDidMount() {
    if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
      window.addEventListener('graphqlError', this.onGraphqlErrorEvent)
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('graphqlError', this.onGraphqlErrorEvent)
    }
  }

  onGraphqlErrorEvent = evt => {
    this.setState({ error: evt.detail })
  }

  static getDerivedStateFromError() {
    // If there is an error causing the page behind not to be possible to render
    return { hasBoundaryError: true }
  }
  dismiss = () => {
    this.setState({ dismissed: true })
  }
  retry = () => {
    this.setState({ hasBoundaryError: false, error: null })
  }

  render() {
    const { error, hasBoundaryError, dismissed } = this.state

    // If dismissed then render children. Unless boundary error because it will
    // Just retrigger error
    if (dismissed && !hasBoundaryError) return this.props.children

    // If no graphql errors or errors in boundary, render children
    if (!error && !hasBoundaryError) return this.props.children

    // After a retry without graphl error render children.
    if (!error && hasBoundaryError) return this.props.children

    const { type, errors, operation, response } = error

    const errorDescription = errors[0].message

    const extra = [
      <Button onClick={this.retry} key="retry" style={{ marginRight: '5px' }} type="primary" icon="reload">
        Retry
      </Button>,
      !hasBoundaryError && (
        <Button onClick={this.dismiss} key="dismiss" type="danger" icon="close">
          Dismiss
        </Button>
      )
    ]

    return (
      <React.Fragment>
        {
          // If there has been an error in graphql but there has not been any
          // errors in boundary then render children so we can check for errors
        }
        {error && !hasBoundaryError && this.props.children}

        <div className="graphql-error">
          <Result
            status="error"
            title={type}
            subTitle={
              <React.Fragment>
                <div style={{ whiteSpace: 'pre' }}>{errorDescription.toString()}</div>
                <br />
                {extra}
              </React.Fragment>
            }
            extra={extra}
          >
            <Collapse bordered={false} defaultActiveKey={['1']} expandIconPosition="right">
              <Panel header={<Header>{`Errors (${errors.length})`}</Header>} key="1">
                {errors.map((error, i) => (
                  <Card key={i} style={{ marginBottom: '10px' }} title={<div style={{ whiteSpace: 'pre', color: 'red' }}>{error.message.toString()}</div>}>
                    {error.path && <div style={{ whiteSpace: 'pre', fontWeight: '800' }}>At {error.path.join('/')}</div>}
                    {error.locations && (
                      <SyntaxHighlighter
                        language="javascript"
                        showLineNumbers={true}
                        wrapLines={true}
                        lineProps={lineNumber => {
                          let style = { display: 'block' }

                          if (error.locations.find(({ line }) => line === lineNumber)) {
                            style.backgroundColor = '#fff1f0'
                          }
                          return { style }
                        }}
                      >
                        {dotProp.get(operation, 'query.loc.source.body')}
                      </SyntaxHighlighter>
                    )}
                    {error.extensions && error.extensions.exception && (
                      <div style={{ whiteSpace: 'pre', overflowY: 'scroll' }}>
                        {error.extensions.exception.stacktrace
                          .join('\n')
                          .replace(error.message, '')
                          .replace(/\((.*)\/api/gm, 'api')}
                      </div>
                    )}
                  </Card>
                ))}
              </Panel>
              <Panel header={<Header>{`Errors JSON (${errors.length})`}</Header>} key="2">
                {errors.map((error, i) => (
                  <Paragraph code key={i}>
                    <pre>{JSON.stringify(error, null, 2)}</pre>
                  </Paragraph>
                ))}
              </Panel>

              {dotProp.get(operation, 'query.loc.source.body') && (
                <Panel header={<Header>Query</Header>} key="3">
                  <SyntaxHighlighter
                    language="javascript"
                    showLineNumbers={true}
                    wrapLines={true}
                    lineProps={lineNumber => {
                      let style = { display: 'block' }
                      const errorLocations = errors
                        .map(({ locations }) => locations || [])
                        .map(({ line }) => line)
                        .flat()
                      if (errorLocations.find(line => line === lineNumber)) {
                        style.backgroundColor = '#fff1f0'
                      }
                      return { style }
                    }}
                  >
                    {dotProp.get(operation, 'query.loc.source.body')}
                  </SyntaxHighlighter>
                </Panel>
              )}

              <Panel header={<Header>{`Response ${!response ? '(empty)' : ''}`}</Header>} key="4">
                <Paragraph code>
                  <pre>{JSON.stringify(response, null, 2)}</pre>
                </Paragraph>
              </Panel>

              <Panel header={<Header>Operation</Header>} key="5">
                <Paragraph code>
                  <pre>{JSON.stringify(operation, null, 2)}</pre>
                </Paragraph>
              </Panel>
            </Collapse>
          </Result>
        </div>
      </React.Fragment>
    )
  }
}

export default GraphqlError
