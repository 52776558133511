import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import getTractamentCountries from './getTractamentCountries.gql'
import { Select } from 'antd'
const { Option } = Select

const CountrySelect = React.forwardRef((props, ref) => {
  const { data = {}, loading, error } = useQuery(getTractamentCountries)

  if (error) {
    <div>{JSON.stringify(error, null, 2)}</div>
  }

  const countries = Array.isArray(data.tractamentCountries) ? data.tractamentCountries : []

  return (
    <Select disabled={loading} ref={ref} {...props}>
      {countries.map(country => (
        <Option key={country} value={country}>{country}</Option>
      ))}
    </Select>
  )
})

export default CountrySelect