import React, { Component } from 'react'
import { Form, Input } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { withAuth } from 'services/auth'
import FancyButton from '../../components/FancyButton/FancyButton'
import withFormDecorator from 'hocs/with-form-decorator'

import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

const messages = defineMessages({
  cta: {
    id: 'ForgotPasswordForm.cta',
    defaultMessage: 'Send new password'
  },
  loginInfo: {
    id: 'ForgotPasswordForm.loginInfo',
    defaultMessage: 'Or'
  },
  loginAction: {
    id: 'ForgotPasswordForm.loginAction',
    defaultMessage: 'log in'
  },
  signupInfo: {
    id: 'ForgotPasswordForm.signupInfo',
    defaultMessage: 'or'
  },
  signupAction: {
    id: 'ForgotPasswordForm.signupAction',
    defaultMessage: 'sign up now!'
  },
  emailRequired: {
    id: 'ForgotPasswordForm.emailRequired',
    defaultMessage: 'Email is required'
  },
  emailPlaceholder: {
    id: 'ForgotPasswordForm.emailPlaceholder',
    defaultMessage: 'Email'
  }
})

class ForgotPasswordForm extends Component {
  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return

      this.props.onSubmit(values)
    })
  }

  render() {
    const { form, saving, intl, history, isAuthenticated } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Form.Item>
          {getFieldDecorator('email', {
            initialValue: history.location.state && history.location.state.email,
            rules: [{ required: true, message: intl.formatMessage(messages.emailRequired) }]
          })(<Input type="email" placeholder={intl.formatMessage(messages.emailPlaceholder)} size="large" />)}
        </Form.Item>
        <Form.Item>
          <FancyButton size="large" type="submit" block loading={saving}>
            <FormattedMessage {...messages.cta} />
          </FancyButton>
        </Form.Item>
        {!isAuthenticated && (
          <p style={{ marginTop: 50 }}>
            <FormattedMessage {...messages.loginInfo} />{' '}
            <Link to={{ pathname: '/login', state: { email: form.getFieldValue('email') } }}>
              <FormattedMessage {...messages.loginAction} />
            </Link>{' '}
            <FormattedMessage {...messages.signupInfo} />{' '}
            <Link to={{ pathname: '/signup', state: { email: form.getFieldValue('email') } }}>
              <FormattedMessage {...messages.signupAction} />
            </Link>
          </p>
        )}
      </Form>
    )
  }
}

export default withRouter(injectIntl(withAuth(withFormDecorator(ForgotPasswordForm))))
