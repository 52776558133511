import React from 'react'
import { Link } from 'react-router-dom'
import { Card } from 'antd'
import { FormattedMessage } from 'react-intl'
import { useAuth } from 'services/auth'
import FancyButton from '../../components/FancyButton/FancyButton'
import useEmployee from '../../hooks/useEmployee'
import UserConfirmationButton from 'components/UserConfirmationButton/UserConfirmationButton'

const WrapperCard = ({ children }) => <Card style={{ marginBottom: 42, textAlign: 'center', minHeight: 250 }}>{children}</Card>

const OnbardingProgress = () => {
  const { user } = useAuth()
  const employee = useEmployee()
  const readyToInvoice = employee && employee.readyToInvoice
  const hasInvoices = employee && employee.hasInvoices

  const needsToConfirmEmail = !user.confirmedAt && user.email

  if (readyToInvoice && hasInvoices && !needsToConfirmEmail) {
    return (
      <WrapperCard>
        <h2 style={{ fontWeight: 700, fontSize: 30 }}>
          <FormattedMessage key="nextInvoiceTitle" defaultMessage="Invoice time!" />
        </h2>

        <p>
          <FormattedMessage key="nextInvoiceText" defaultMessage="It feels like it's time to send another invoice, right." />
        </p>

        <Link to={'/invoices/create'}>
          <FancyButton variant="cta" size="large">
            <FormattedMessage key="nextInvoiceButton" defaultMessage="Create an invoice!" />
          </FancyButton>
        </Link>
      </WrapperCard>
    )
  }

  if (!readyToInvoice) {
    return (
      <WrapperCard>
        <h2 style={{ fontWeight: 700, fontSize: 30 }}>
          <FormattedMessage key="noEmployeeDataTitle" defaultMessage="Almost done!" />
        </h2>

        <p>
          <FormattedMessage key="noEmployeeDataText" defaultMessage="You need to fill in your personal and bank details to invoice. How else will we pay you? :)" />
        </p>

        <Link to={'/settings'}>
          <FancyButton variant="cta" size="large">
            <FormattedMessage key="noEmployeeDataButton" defaultMessage="Finish your profile!" />
          </FancyButton>
        </Link>
      </WrapperCard>
    )
  }

  if (needsToConfirmEmail) {
    return (
      <WrapperCard>
        <h2 style={{ fontWeight: 700, fontSize: 30 }}>
          <FormattedMessage key="needsToConfirmEmailDataTitle" defaultMessage="One last thing!" />
        </h2>

        <p>
          <FormattedMessage key="needsToConfirmEmailDataText" defaultMessage="We need you to confirm your email before you can start sending invoices!" />
        </p>

        <UserConfirmationButton />
      </WrapperCard>
    )
  }

  return (
    <WrapperCard>
      <h2 style={{ fontWeight: 700, fontSize: 30 }}>
        <FormattedMessage key="getStartedTitle" defaultMessage="Get started!" />
      </h2>
      <p>
        <FormattedMessage
          key="getStartedText"
          defaultMessage="Charge your customer by creating a new invoice. We will do all accounting and paper work. Once we get paid from your customer we will make a payment to your bank account within 24 hours. Super simple!"
        />
      </p>
      <Link to={'/invoices/create'}>
        <FancyButton variant="cta" size="large">
          <FormattedMessage key="getStartedButton" defaultMessage="Create invoice" />
        </FancyButton>
      </Link>
    </WrapperCard>
  )
}

export default OnbardingProgress
