import React, { Component } from 'react'
import { Button, Table, InputNumber, Input, Tooltip, Icon, Popover } from 'antd'
import './InvoiceRowsOverrides.css'
import { FormattedAmount } from 'lib/locale'
import FancyButton from '../FancyButton/FancyButton'
import { defineMessages, FormattedMessage } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import AmountInput from '../AmountInput/AmountInput'
import moment from 'moment'
import VatPercentageSelect from 'components/VatPercentageSelect/VatPercentageSelect'
import VatDisplay from 'components/VatDisplay/VatDisplay'

const messages = defineMessages({
  description: {
    id: 'InvoiceRows.description',
    defaultMessage: 'Description',
  },
  rot: {
    id: 'InvoiceRows.rot',
    defaultMessage: 'ROT/RUT',
  },
  count: {
    id: 'InvoiceRows.count',
    defaultMessage: 'Count',
  },
  hourCount: {
    id: 'InvoiceRows.hourCount',
    defaultMessage: 'Number of hours',
  },
  amountExVat: {
    id: 'InvoiceRows.amountExVat',
    defaultMessage: 'Price (excl VAT)',
  },
  amountPerHour: {
    id: 'InvoiceRows.amountPerHour',
    defaultMessage: 'Price / hour',
  },
  vatPercentage: {
    id: 'InvoiceRows.vatPercentage',
    defaultMessage: 'VAT',
  },
  sum: {
    id: 'InvoiceRows.sum',
    defaultMessage: 'Sum',
  },
  addRow: {
    id: 'InvoiceRows.addRow',
    defaultMessage: 'Add row',
  },
  invoiceRows: {
    id: 'InvoiceRows.invoiceRows',
    defaultMessage: 'Invoice rows',
  },
})

export const emptyRow = {
  description: moment().format('YYYY-MM-DD'),
  count: 1,
  amountExVat: 100000,
  rot: false,
  vatPercentage: '_25',
}

const RotColumn = ({ row }) => {
  if (!row.rot) return null

  const text = `Type: ${row.rotType}, Hours: ${row.rotHours}`

  return (
    <Tooltip title={text} placement="right">
      <Icon type="check" />
    </Tooltip>
  )
}

const InvoiceRowsTableFooter = ({ onAddRow }) => {
  return (
    <div style={{ textAlign: 'right' }}>
      <FancyButton onClick={onAddRow} variant="secondary">
        <Icon type="plus" /> <FormattedMessage {...messages.addRow} />
      </FancyButton>
    </div>
  )
}

class InvoiceRows extends Component {
  onChangeValue = (index, key, value) => {
    const { invoiceRows } = this.props
    invoiceRows[index][key] = value
    this.props.onChange(invoiceRows)
  }

  onAddRow = (newRow) => {
    const { invoiceRows } = this.props
    invoiceRows.push({ ...emptyRow })

    this.props.onChange(invoiceRows)
  }

  onRemoveRow = (index) => {
    const { invoiceRows } = this.props

    invoiceRows.splice(index, 1)

    this.props.onChange(invoiceRows)
  }

  render() {
    const {
      invoiceRows = [],
      readOnly = false,
      isMobile,
      calendarMode = false,
    } = this.props

    const hasRot = invoiceRows.some((r) => r.rot)

    const mobileColumns = [
      {
        title: <FormattedMessage {...messages.invoiceRows} />,
        key: 'rowKey',
        dataIndex: 'id',
        render: (_, row, index) => (
          <div>
            <div style={{ marginBottom: 10 }}>
              <FormattedMessage {...messages.description} />
              {readOnly ? (
                <p>{row.description}</p>
              ) : (
                <Input
                  value={row.description}
                  style={{ width: '100%' }}
                  onChange={(e) =>
                    this.onChangeValue(index, 'description', e.target.value)
                  }
                />
              )}
            </div>
            <div style={{ marginBottom: 10 }}>
              {calendarMode ? (
                <FormattedMessage {...messages.hourCount} />
              ) : (
                <FormattedMessage {...messages.count} />
              )}
              <br />

              {readOnly ? (
                <p>{row.count}</p>
              ) : (
                <InputNumber
                  value={row.count}
                  min={1}
                  max={1000}
                  step={1}
                  pattern="/\d+/"
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    const newVal = Number.isNaN(val)
                      ? parseInt(val.toString().replace(/[^0-9.]/g, ''))
                      : parseInt(val)
                    this.onChangeValue(
                      index,
                      'count',
                      Number.isNaN(newVal) ? 1 : newVal
                    )
                  }}
                />
              )}
            </div>
            <div style={{ marginBottom: 10 }}>
              {calendarMode ? (
                <FormattedMessage {...messages.amountPerHour} />
              ) : (
                <FormattedMessage {...messages.amountExVat} />
              )}

              {readOnly ? (
                <>
                  <br />
                  <FormattedAmount {...row.amountExVat} />
                </>
              ) : (
                <AmountInput
                  style={{ width: '100%' }}
                  value={row.amountExVat}
                  onChange={(newVal) => {
                    this.onChangeValue(index, 'amountExVat', newVal)
                  }}
                />
              )}
            </div>
            <div style={{ marginBottom: 10 }}>
              <FormattedMessage {...messages.vatPercentage} />

              {readOnly ? (
                <>
                  <br />
                  <VatDisplay value={row.vatPercentage} />
                </>
              ) : (
                <div style={{ display: 'flex' }}>
                  <VatPercentageSelect
                    style={{ width: '100%' }}
                    value={row.vatPercentage}
                    onChange={(newVal) => {
                      this.onChangeValue(index, 'vatPercentage', newVal)
                    }}
                  />

                  <Popover
                    content={
                      'Det är 25% moms på nästan alla varor och tjänster du säljer (är du osäker kontakta oss eller läs mer på skatteverkets hemsida)'
                    }
                    title={'Momssats'}
                  >
                    <Icon
                      type="question-circle"
                      style={{ marginTop: 12, marginLeft: 6, color: 'red' }}
                    />
                  </Popover>
                </div>
              )}
            </div>
          </div>
        ),
      },
    ]
    const desktopColumns = [
      {
        title: '#',
        key: 'rowNumber',
        render: (_, __, index) => index + 1,
      },
      !calendarMode && {
        title: <FormattedMessage {...messages.description} />,
        dataIndex: 'description',
        key: 'description',
        render: (description, _, index) =>
          readOnly ? (
            description
          ) : (
            <Input
              value={description}
              onChange={(e) =>
                this.onChangeValue(index, 'description', e.target.value)
              }
            />
          ),
      },
      calendarMode && {
        title: 'Datum',
        dataIndex: 'description',
        key: 'date',
      },
      calendarMode && {
        dataIndex: 'description',
        key: 'weekday',
        render: (description) => moment(description).format('dddd'),
      },
      readOnly && hasRot
        ? {
            title: <FormattedMessage {...messages.rot} />,
            key: 'rot',
            render: (row) => <RotColumn row={row} />,
          }
        : null,
      {
        title: calendarMode ? (
          <FormattedMessage {...messages.hourCount} />
        ) : (
          <FormattedMessage {...messages.count} />
        ),
        dataIndex: 'count',
        align: calendarMode ? 'left' : 'right',
        key: 'count',
        render: (count, _, index) =>
          readOnly ? (
            count
          ) : (
            <InputNumber
              value={count}
              min={1}
              max={1000}
              step={1}
              pattern="/\d+/"
              style={{ width: 80 }}
              onChange={(val) => {
                const newVal = Number.isNaN(val)
                  ? parseInt(val.toString().replace(/[^0-9.]/g, ''))
                  : parseInt(val)
                this.onChangeValue(
                  index,
                  'count',
                  Number.isNaN(newVal) ? 1 : newVal
                )
              }}
            />
          ),
      },
      {
        title: calendarMode ? (
          <FormattedMessage {...messages.amountPerHour} />
        ) : (
          <FormattedMessage {...messages.amountExVat} />
        ),
        dataIndex: 'amountExVat',
        key: 'amountExVat',
        align: 'right',
        render: (amountExVat, _, index) =>
          readOnly ? (
            <FormattedAmount {...amountExVat} />
          ) : (
            <AmountInput
              value={amountExVat}
              onChange={(newVal) => {
                this.onChangeValue(index, 'amountExVat', newVal)
              }}
            />
          ),
      },

      {
        title: <FormattedMessage {...messages.vatPercentage} />,
        dataIndex: 'vatPercentage',
        key: 'vatPercentage',
        align: 'right',
        render: (vatPercentage, _, index) =>
          readOnly ? (
            <VatDisplay value={vatPercentage} />
          ) : (
            <div style={{ display: 'flex' }}>
              <VatPercentageSelect
                style={{ minWidth: 100 }}
                value={vatPercentage}
                onChange={(newVal) => {
                  this.onChangeValue(index, 'vatPercentage', newVal)
                }}
              />
              <Popover
                content={
                  'Det är 25% moms på nästan alla varor och tjänster du säljer (är du osäker kontakta oss eller läs mer på skatteverkets hemsida)'
                }
                title={'Momssats'}
              >
                <Icon
                  type="question-circle"
                  style={{ marginTop: 12, marginLeft: 6, color: 'red' }}
                />
              </Popover>
            </div>
          ),
      },

      {
        title: <FormattedMessage {...messages.sum} />,
        dataIndex: 'sum',
        key: 'sum',
        align: 'right',
        render: (_, row) =>
          readOnly ? (
            <FormattedAmount {...row.amountTotalExVat} />
          ) : (
            <FormattedAmount valueInCent={row.amountExVat * row.count} />
          ),
      },
    ]

    const columns = isMobile ? mobileColumns : desktopColumns

    if (!readOnly) {
      columns.push({
        title: '',
        key: 'remove',
        dataIndex: 'remove',
        align: 'right',
        render: (_, __, index) => (
          <Button
            type="link"
            onClick={() => {
              this.onRemoveRow(index)
            }}
            icon="delete"
          />
        ),
      })
    }

    const footer = !(readOnly || calendarMode)
      ? () => <InvoiceRowsTableFooter onAddRow={this.onAddRow} />
      : undefined

    return (
      <Table
        columns={columns.filter(Boolean)}
        pagination={false}
        dataSource={invoiceRows.map((row, key) => {
          return { ...row, key }
        })}
        footer={footer}
      />
    )
  }
}

export default (props) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return (
    <div className="invoice-rows">
      <InvoiceRows isMobile={isMobile} {...props} />
    </div>
  )
}
