import SalaryCalculator from 'components/SalaryCalculator/SalaryCalculator'
import React from 'react'

export default function SalaryCalculatorView() {
  const params = new URLSearchParams(location.search)
  const backgroundColor = params.get('backgroundColor') || 'ffffff'

  return (
    <SalaryCalculator
      hideTitle={true}
      backgroundColor={`#${backgroundColor}`}
    />
  )
}
