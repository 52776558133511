import React, { Component } from 'react'
import { Select } from 'antd'
const { Option } = Select

const percentages = [
  { value: '_6', title: '6%' },
  { value: '_12', title: '12%' },
  { value: '_25', title: '25%' },
  { value: '_0', title: '0%' },
]

class VatPercentageSelect extends Component {
  render() {
    return (
      <Select {...this.props}>
        {percentages.map((percentage) => (
          <Option value={percentage.value} key={percentage.value}>
            {percentage.title}
          </Option>
        ))}
      </Select>
    )
  }
}

export default VatPercentageSelect
