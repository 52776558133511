import React from 'react'
import { Table, Button } from 'antd'
import { FormattedAmount } from 'lib/locale'
import { FormattedMessage } from 'react-intl'

const Footer = ({ travelExpenses }) => {
  const sum = travelExpenses.reduce((sum, row) => (sum + row.amount), 0)

  return (
    <>
      <span style={{
        fontSize: 18
      }}>
        <FormattedMessage id="TravelExpensesTable.Footer.sumTitle" defaultMessage="Travel expenses sum" />
      </span>
      <strong style={{ float: 'right', fontSize: 18 }}>
        <FormattedAmount valueInCent={sum} currency="SEK" />
      </strong>
    </>
  )
}

const TravelExpensesTable = ({ travelExpenses, onRemoveTravelExpense, readOnly = false }) => {
  const columns = [
    {
      title: <FormattedMessage id="TravelExpensesTable.Header.description" defaultMessage="Description" />,
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: <FormattedMessage id="TravelExpensesTable.Header.date" defaultMessage="Date" />,
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: <FormattedMessage id="TravelExpensesTable.Header.numberOfMiles" defaultMessage="Number of miles" />,
      dataIndex: 'numberOfMiles',
      key: 'numberOfMiles',
    },
    {
      title: <FormattedMessage id="TravelExpensesTable.Header.amountInclVat" defaultMessage="Total amount" />,
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: amount => (
        <FormattedAmount valueInCent={amount} currency="SEK" />
      )
    } 
  ]

  if (!readOnly) {
    columns.push({
      title: null,
      key: 'action',
      align: 'right',
      render: (id) => <Button type="link" onClick={() => { onRemoveTravelExpense(id) }} icon="delete" />
    })
  }

  const dataSource = travelExpenses.map((item, id) => {
    const { amount, ...itemProps } = item
    return {
      id,
      key: id,
      amount: readOnly ? amount.valueInCent : amount,
      ...itemProps
    }
  })

  return <Table dataSource={dataSource} columns={columns} footer={() => (
    <Footer travelExpenses={dataSource} />
  )} pagination={false} />
}

export default TravelExpensesTable