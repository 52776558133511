import React, { lazy, Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ModalRoute } from 'react-router-modal'

// Routes
import PublicRoute from './public-route'
import UserRoute from './user-route'

// Layouts
import EmbedLayout from 'layouts/embed'
import EmptyLayout from 'layouts/empty'
import UserLayout from 'layouts/user'
import AuthLayout from 'layouts/auth'

// Public
import GenericNotFoundView from 'views/not-found'
import LoginView from 'views/login'
import SignupView from 'views/signup'
import ConfirmView from 'views/confirm'
import ForgotPasswordView from 'views/forgot-password'
import ResetPasswordView from 'views/reset-password'
import ChangeEmailView from 'views/change-email'
import SalaryCalculatorView from 'views/salary-calculator'

// User
import UserHomeView from 'views/user/home'
import UserInvoicesView from 'views/user/invoices'
import UserInvoiceView from 'views/user/invoices/show'
import UserInvoicesEditView from 'views/user/invoices/edit'
import UserInvoicesCreateView from 'views/user/invoices/create'
import UserHomeModalExampleModal from 'views/user/modal-example'
import UserSupportView from 'views/user/support'
import UserActivitiesView from 'views/user/activities'
import UserCustomersView from 'views/user/customers'
import UserCustomerView from 'views/user/customers/edit'

import UserEmployeeSettingsView from 'views/user/settings/employee'
import UserSettingsLoginView from 'views/user/settings/login'
// import UserSettingsRemoveAccountView from 'views/user/settings/remove-account'
import UserSettingsChangeEmailView from 'views/user/settings/change-email'
import UserSettingsTestInfoView from 'views/user/settings/test-info'
import BigLoader from 'components/BigLoader'

const AdminRoutes = lazy(() => import('./AdminRoutes'))

const Routes = () => {
  return (
    <>
      <Suspense fallback={<BigLoader />}>
        <Switch>
          <PublicRoute
            name="Log in"
            path="/login"
            component={LoginView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Sign up"
            path="/signup"
            component={SignupView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Confirm"
            path="/confirm/:token"
            component={ConfirmView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Get a new password"
            path="/forgot-password"
            component={ForgotPasswordView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Reset your password"
            path="/reset-password/:token"
            component={ResetPasswordView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Confirm your email change"
            path="/change-email/:token"
            component={ChangeEmailView}
            layout={AuthLayout}
          />
          <PublicRoute
            name="Salary Calculator"
            path="/salary-calculator"
            component={SalaryCalculatorView}
            layout={EmbedLayout}
          />

          <UserRoute
            name="Home"
            path="/home"
            component={UserHomeView}
            layout={UserLayout}
          />
          <UserRoute
            name="Create invoice"
            path="/invoices/create"
            component={UserInvoicesCreateView}
            layout={UserLayout}
          />
          <UserRoute
            name="Edit invoice"
            path="/invoices/:id/edit"
            component={UserInvoicesEditView}
            layout={UserLayout}
          />
          <UserRoute
            name="Invoice"
            path="/invoices/:id"
            component={UserInvoiceView}
            layout={UserLayout}
          />
          <UserRoute
            name="Invoices"
            path="/invoices"
            component={UserInvoicesView}
            layout={UserLayout}
          />
          <UserRoute
            name="Customers"
            path="/customers/:id"
            component={UserCustomerView}
            layout={UserLayout}
          />
          <UserRoute
            name="Customers"
            path="/customers"
            component={UserCustomersView}
            layout={UserLayout}
          />

          <UserRoute
            name="Activities"
            path="/all-activities"
            component={UserActivitiesView}
            layout={UserLayout}
          />
          <UserRoute
            exact
            name="Support"
            path="/support"
            component={UserSupportView}
            layout={UserLayout}
          />

          <UserRoute
            exact
            name="SettingsLogin"
            path="/settings/login"
            component={UserSettingsLoginView}
            layout={UserLayout}
          />
          <UserRoute
            exact
            name="SettingsChangeEmail"
            path="/settings/change-email"
            component={UserSettingsChangeEmailView}
            layout={UserLayout}
          />
          <UserRoute
            exact
            name="UserSettingsTestInfo"
            path="/settings/test-info"
            component={UserSettingsTestInfoView}
            layout={UserLayout}
          />
          {/* <UserRoute
            exact
            name="SettingsRemoveAccount"
            path="/settings/remove-account"
            component={UserSettingsRemoveAccountView}
            layout={UserLayout}
          /> */}
          <UserRoute
            name="EmployeeSettings"
            path="/settings"
            component={UserEmployeeSettingsView}
            layout={UserLayout}
          />

          <Route path="/admin" component={AdminRoutes} />
          <Route exact path="/" render={() => <Redirect to="/login" />} />

          <PublicRoute component={GenericNotFoundView} layout={EmptyLayout} />
        </Switch>
        <ModalRoute
          path="/home/modal-example"
          component={UserHomeModalExampleModal}
          parentPath="/home"
          closeModal="closeModal"
        />
      </Suspense>
    </>
  )
}

export default Routes
