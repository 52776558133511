import { Result, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import jwtDecode from 'jwt-decode'
import moment from 'moment'

const LinkHasExpired = ({ expiredResult }) =>
  expiredResult || (
    <Result
      status="error"
      title={<FormattedMessage key="expiredTitle" defaultMessage="Link has expired" />}
      subTitle={<FormattedMessage key="expiredSubTitle" defaultMessage="Login to resend link" />}
      extra={
        <Link to="/login">
          <Button type="primary" key="login">
            <FormattedMessage key="expiredButtonLogin" defaultMessage="Log in" />
          </Button>
        </Link>
      }
    />
  )

const IncorrectType = ({ type }) => (
  <Result
    status="warning"
    title={<FormattedMessage key="incorrectTypeTitle" defaultMessage="The token received is not of correct type {type}" values={{ type }} />}
    subTitle={<FormattedMessage key="incorrectTypeSubTitle" defaultMessage="Check so you copied the link correctly" />}
  />
)

class TokenWrapper extends Component {
  state = {
    type: null,
    expired: null,
    decodedToken: null
  }

  componentDidMount() {
    const decodedToken = jwtDecode(this.props.match.params.token)
    const { type, exp } = decodedToken

    this.setState({ type, expired: moment(exp * 1000).isBefore(moment()), decodedToken })
  }

  render() {
    const { children, expiredResult, type } = this.props
    if (this.state.expired) {
      return <LinkHasExpired expiredResult={expiredResult} />
    }
    if (type !== this.state.type) {
      return <IncorrectType type={type} />
    }
    if (typeof children === 'function') {
      return children({ decodedToken: this.state.decodedToken })
    } else {
      return children
    }
  }
}

export default withRouter(TokenWrapper)
