import React from 'react'
import InvoiceView from '../../../components/InvoiceView/InvoiceView'
import PageLayout from '../../../components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.InvoiceView.pageTitle',
    defaultMessage: 'Invoice'
  }
})
const UserInvoicesView = ({ match }) => (
  <PageLayout pageTitleKey={messages.pageTitle}>
    <InvoiceView id={match.params.id} />
  </PageLayout>
)

export default UserInvoicesView