import React from 'react'
import ApolloQueryTable from 'components/ApolloQueryTable'
import CustomerTypeText from 'components/CustomerTypeText/CustomerTypeText'
import listCustomers from './listCustomers.gql'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useMediaQuery } from 'react-responsive'
import './CustomersTable.css'

const CustomersTable = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const tableStyle = isMobile
    ? {
        width: 'calc(100% + 40px)',
        marginLeft: -20,
      }
    : {}

  const columns = [
    {
      title: (
        <FormattedMessage
          key="CustomersTable.customerName"
          defaultMessage="Name"
        />
      ),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      render: (text, customer) => (
        <Link to={`/customers/${customer.id}`}>{text}</Link>
      ),
    },
    {
      title: (
        <FormattedMessage
          key="CustomersTable.customerType"
          defaultMessage="Type"
        />
      ),
      dataIndex: 'customerType',
      key: 'customerType',
      render: (text) => <CustomerTypeText text={text} />,
      sorter: false,
    },
    !isMobile
      ? {
          title: (
            <FormattedMessage
              key="CustomersTable.actionTitle"
              defaultMessage="Action"
            />
          ),
          key: 'action',
          align: 'right',
          render: (_text, customer) => (
            <span>
              <Link to={`/customers/${customer.id}`}>
                <FormattedMessage
                  key="CustomersTable.view"
                  defaultMessage="View"
                />
              </Link>
            </span>
          ),
        }
      : null,
  ]

  return (
    <ApolloQueryTable
      queryProps={{ query: listCustomers, fetchPolicy: 'cache-and-network' }}
      dataRef="currentUser.customers"
      tableProps={{
        className: 'customers-table',
        columns: columns.filter(Boolean),
        style: { ...tableStyle },
      }}
    />
  )
}

export default CustomersTable
