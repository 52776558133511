import React from 'react'
import CustomersTable from '../../../components/CustomersTable/CustomersTable'
import PageLayout from '../../../components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.CustomersView.pageTitle',
    defaultMessage: 'Customers',
  },
})

const UserCustomersView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <CustomersTable />
    </PageLayout>
  )
}

export default UserCustomersView
