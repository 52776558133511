import React from 'react'
import { Form } from 'antd'
import { FormattedMessage } from 'react-intl'
import personnummer from 'personnummer'
import BankidInput from '../BankidInput/BankidInput'
import FancyButton from '../FancyButton/FancyButton'

const personnrTransform = val => {
  if (!personnummer.valid(val)) {
    return val
  }
  return personnummer.format(val, true)
}

class BankidSignForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { personalIdentityNumber } = values
        this.props.onSubmit({
          personalIdentityNumber: personnrTransform(personalIdentityNumber)
        })
      }
    })
  }

  render() {
    const { form, loading, loginUi } = this.props
    const { getFieldDecorator } = form
    return (
      <Form onSubmit={this.handleSubmit} layout="vertical">
        <Form.Item>
          {getFieldDecorator('personalIdentityNumber', {
            rules: [
              { transform: personnrTransform },
              { required: true, message: <FormattedMessage key="personalIdentityNumberRequired" defaultMessage="Please input your personnr!" /> },
              {
                validator: (_rule, value) => personnummer.valid(value),
                message: <FormattedMessage key="personalIdentityNumberInvalid" defaultMessage="Invalid personal identity number" />
              }
            ]
          })(<BankidInput disabled={Boolean(loading)} />)}
        </Form.Item>
        <Form.Item>
          <FancyButton loading={loading} type="submit" block size="large" style={{ marginTop: 20 }}>
            {loginUi ? <FormattedMessage key="logIn" defaultMessage="Log in with BankID" /> : <FormattedMessage key="signUp" defaultMessage="Create account with BankID" />}
          </FancyButton>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedBankidSignForm = Form.create({ name: 'bankid_sign' })(BankidSignForm)

export default WrappedBankidSignForm
