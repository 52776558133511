import React from 'react'
import { Alert } from 'antd'

const InvoiceErrors = ({ title, errors }) => {
  if (!errors || errors.length === 0) return null
  const description = (
    <ul style={{ margin: '10px 0 10px 20px', padding: 0 }}>
      {errors.map((error, n) => (
        <li key={n}>
          {error}
        </li>
      ))}
    </ul>
  )
  return (
    <Alert style={{ marginTop: 24 }} message={title} type="warning" showIcon description={description} />
  )
}

export default InvoiceErrors