import React from 'react'
import { Alert } from 'antd'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

const EmployeeDetailsMissing = () => {
  const title = <FormattedMessage key="EmployeeDetailsMissing.title" defaultMessage="Employee details missing" />
  const description = <>
    <FormattedMessage key="EmployeeDetailsMissing.description" defaultMessage="You need to add employee details before you can start invoicing." />
    {' '}
    <Link to="/settings">
      <FormattedMessage key="EmployeeDetailsMissing.cta" defaultMessage="Do it here." />
    </Link>
  </>

  return (
    <Alert 
      style={{ marginTop: 24 }} 
      message={title} 
      type="warning" 
      showIcon 
      description={description} />
  )
}

export default EmployeeDetailsMissing