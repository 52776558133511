const rutTypes = [
  'CLEANING',
  'ITSERVICES',
  'MAJORAPPLIANCEREPAIR',
  'TEXTILECLOTHING',
  'SNOWPLOWING',
  'OTHERCARE',
  'GARDENING',
  'BABYSITTING',
]

function isRotOrRut(type) {
  if (rutTypes.includes(type)) return 'RUT'
  else return 'ROT'
}

export default isRotOrRut
