import React from 'react'
import { Table, Button } from 'antd'
import { FormattedAmount } from 'lib/locale'
import { FormattedMessage } from 'react-intl'

const Footer = ({ reimbursements }) => {
  const sum = reimbursements.reduce((sum, row) => sum + row.amount, 0)
  return (
    <>
      <span
        style={{
          fontSize: 18
        }}
      >
        <FormattedMessage
          id="ReimbursementsTable.Footer.sumTitle"
          defaultMessage="Reimbursement Sum"
        />
      </span>
      <strong style={{ float: 'right', fontSize: 18 }}>
        <FormattedAmount valueInCent={sum} currency="SEK" />
      </strong>
    </>
  )
}

const ReimbursementsTable = ({
  reimbursements,
  onRemoveReimbursement,
  readOnly = false
}) => {
  const columns = [
    {
      title: (
        <FormattedMessage
          id="ReimbursementsTable.Header.description"
          defaultMessage="Description"
        />
      ),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: (
        <FormattedMessage
          id="ReimbursementsTable.Header.supplier"
          defaultMessage="Supplier"
        />
      ),
      dataIndex: 'supplier',
      key: 'supplier'
    },
    {
      title: (
        <FormattedMessage
          id="ReimbursementsTable.Header.vatPercentage"
          defaultMessage="VAT Percentage"
        />
      ),
      dataIndex: 'vatPercentage',
      key: 'vatPercentage',
      render: p => `${p.replace('_', '')}%`
    },
    {
      title: (
        <FormattedMessage
          id="ReimbursementsTable.Header.amountInclVat"
          defaultMessage="Amount (incl VAT)"
        />
      ),
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: amount => <FormattedAmount valueInCent={amount} currency="SEK" />
    },
    {
      title: null,
      key: 'action',
      align: 'right',
      render: (id, reimbursement) => (
        <>
          {reimbursement.file && reimbursement.file.downloadUrl && (
            <a
              href={reimbursement.file.downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="link" icon="file" />
            </a>
          )}
          {!readOnly && (
            <Button
              type="link"
              onClick={() => {
                onRemoveReimbursement(id)
              }}
              icon="delete"
            />
          )}
        </>
      )
    }
  ]

  const dataSource = reimbursements.map((item, id) => {
    const { amount, ...itemProps } = item
    return {
      id,
      amount: readOnly ? amount.valueInCent : amount,
      key: id,
      ...itemProps
    }
  })

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      footer={() => <Footer reimbursements={dataSource} />}
      pagination={false}
    />
  )
}

export default ReimbursementsTable
