import isRotOrRut from 'lib/isRotOrRut'

const percentages = {
  _6: 0.06,
  _12: 0.12,
  _25: 0.25,
  _0: 0,
}

function getRotRutDeductable(invoiceRow) {
  const { rot, rotType, amountExVat, count } = invoiceRow
  if (!rot) return 0

  const amountIncVat = Math.round(
    amountExVat * (1 + percentages[invoiceRow.vatPercentage])
  )

  const rowType = isRotOrRut(rotType)

  // 30% of the value inc VAT for ROT, 50% for RUT
  const deductablePercentage = rowType === 'RUT' ? 0.5 : 0.3

  // Round the value (/100 * 100) to remove ören and only return kronor
  return Math.round((amountIncVat * count * deductablePercentage) / 100) * 100
}

export default getRotRutDeductable
