import React, { Component } from 'react'
import { Layout } from 'antd'
import AdminGhostBar from 'components/AdminGhostBar/AdminGhostBar'
import Zendesk from 'components/Zendesk/Zendesk'

const { Content } = Layout

class EmptyLayout extends Component {
  render() {
    return (
      <>
        <AdminGhostBar />
        <Layout style={{ minHeight: '100vh' }}>
          <Content>{this.props.children}</Content>
        </Layout>
        <Zendesk />
      </>
    )
  }
}

export default EmptyLayout
