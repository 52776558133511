import React, { Component } from 'react'
import { Switch } from 'antd'
import { defineMessages, FormattedMessage } from 'react-intl'
import ReimbursementForm from '../ReimbursementForm/ReimbursementForm'
import RotForm from '../RotForm/RotForm'
import TravelExpensesForm from '../TravelExpensesForm/TravelExpensesForm'
import TractamentForm from '../TractamentForm/TractamentForm'

const messages = defineMessages({
  rot: {
    id: 'InvoiceAddons.rot',
    defaultMessage: 'Rot-deduction',
  },
  reimbursements: {
    id: 'InvoiceAddons.reimbursements',
    defaultMessage: 'Reimbursements',
  },
  tractament: {
    id: 'InvoiceAddons.tractament',
    defaultMessage: 'Tractament',
  },
  travelExpenses: {
    id: 'InvoiceAddons.travelExpenses',
    defaultMessage: 'Travel Expenses',
  },
})

class InvoiceAddons extends Component {
  state = {
    open: [],
  }

  onToggle = (what) => {
    const { open } = this.state

    const index = open.indexOf(what)
    if (index !== -1) {
      open.splice(index, 1)
    } else {
      open.push(what)
    }

    this.setState({ open })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rot !== this.props.rot) {
      if (nextProps.rot) {
        const currentState = this.state
        currentState.open.push('rot')
        this.setState(currentState)
      }
    }
  }

  render() {
    const { open } = this.state
    const {
      rot,
      onRotChange,
      invoiceRows,
      reimbursements,
      onAddReimbursement,
      onRemoveReimbursement,
      setInvoiceRows,
      travelExpenses,
      onAddTravelExpense,
      onRemoveTravelExpense,
      tractaments,
      onAddTractament,
      onRemoveTractament,
      customer,
    } = this.props

    const customerType = customer && customer.customerType

    return (
      <>
        <div style={{ paddingBottom: 5 }}>
          <span
            style={{ minWidth: 'calc(100% - 44px)', display: 'inline-block' }}
          >
            <FormattedMessage {...messages.rot} />
          </span>
          <Switch
            checked={this.state.open.includes('rot')}
            onChange={() => {
              this.onToggle('rot')
            }}
            disabled={customerType === 'COMPANY'}
          />
        </div>
        <div style={{ paddingBottom: 5 }}>
          <span
            style={{ minWidth: 'calc(100% - 44px)', display: 'inline-block' }}
          >
            <FormattedMessage {...messages.reimbursements} />
          </span>
          <Switch
            checked={this.state.open.includes('reimbursement')}
            onChange={() => {
              this.onToggle('reimbursement')
            }}
          />
        </div>
        <div style={{ paddingBottom: 5 }}>
          <span
            style={{ minWidth: 'calc(100% - 44px)', display: 'inline-block' }}
          >
            <FormattedMessage {...messages.travelExpenses} />
          </span>
          <Switch
            checked={this.state.open.includes('travelExpenses')}
            onChange={() => {
              this.onToggle('travelExpenses')
            }}
          />
        </div>
        <div style={{ paddingBottom: 5 }}>
          <span
            style={{ minWidth: 'calc(100% - 44px)', display: 'inline-block' }}
          >
            <FormattedMessage {...messages.tractament} />
          </span>
          <Switch
            checked={this.state.open.includes('tractament')}
            onChange={() => {
              this.onToggle('tractament')
            }}
          />
        </div>

        {open.includes('rot') && (
          <RotForm
            rot={rot}
            onRotChange={onRotChange}
            invoiceRows={invoiceRows}
            setInvoiceRows={setInvoiceRows}
          />
        )}

        {open.includes('reimbursement') && (
          <ReimbursementForm
            reimbursements={reimbursements}
            onAddReimbursement={onAddReimbursement}
            onRemoveReimbursement={onRemoveReimbursement}
          />
        )}

        {open.includes('travelExpenses') && (
          <TravelExpensesForm
            travelExpenses={travelExpenses}
            onAddTravelExpense={onAddTravelExpense}
            onRemoveTravelExpense={onRemoveTravelExpense}
          />
        )}

        {open.includes('tractament') && (
          <TractamentForm
            tractaments={tractaments}
            onAddTractament={onAddTractament}
            onRemoveTractament={onRemoveTractament}
          />
        )}
      </>
    )
  }
}

export default InvoiceAddons
