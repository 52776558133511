import React from 'react'
import FinePrint from 'components/FinePrint/FinePrint'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

const messages = defineMessages({
  finePrint: {
    id: 'LoginView.finePrint',
    defaultMessage: 'By logging in i agree to the ',
  },
  finePrintTosLink: {
    id: 'LoginView.finePrintTosLink',
    defaultMessage: 'terms of service.',
  },
  finePrint2: {
    id: 'LoginView.finePrint2',
    defaultMessage: ' and ',
  },
  finePrintPrivacyLink: {
    id: 'LoginView.finePrintPrivacyLink',
    defaultMessage: 'privacy policy.',
  },
})

function LoginFineprint() {
  return (
    <FinePrint>
      <FormattedMessage {...messages.finePrint} />{' '}
      <a
        href="http://konsultfaktura.se/wp-content/uploads/KF-Allmanna-Villkor-Egenanstallning.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage {...messages.finePrintTosLink} />
      </a>{' '}
      <FormattedMessage {...messages.finePrint2} />{' '}
      <a
        href="http://konsultfaktura.se/wp-content/uploads/KF-Behandling-av-Personuppgifter.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FormattedMessage {...messages.finePrintPrivacyLink} />
      </a>
    </FinePrint>
  )
}

export default injectIntl(LoginFineprint)
