import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Checkbox } from 'antd'

function CheckboxesFilter({ setFilter, currentFilter, options }) {
  const operator = options.operator || '$in'
  const multiple = options.multiple !== false

  const setSelected = ({ selectedKeys }) => {
    const newFilter = {}
    newFilter[operator] = selectedKeys
    setFilter({
      ...currentFilter,
      ...newFilter
    })
  }

  const selectedKeys = currentFilter[operator] || []

  return (
    <Menu style={{ paddingBottom: '8px' }} onSelect={setSelected} onDeselect={setSelected} multiple={multiple} selectedKeys={selectedKeys}>
      {options.options.map(graphqlCheckboxOption => (
        <Menu.Item key={graphqlCheckboxOption.value} style={{ marginBottom: '0px', lineHeight: '24px', height: '24px', marginTop: '8px' }}>
          <Checkbox checked={selectedKeys.indexOf(graphqlCheckboxOption.value) >= 0} />
          <span>{graphqlCheckboxOption.text}</span>
        </Menu.Item>
      ))}
    </Menu>
  )
}

CheckboxesFilter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  options: PropTypes.object
}

export default CheckboxesFilter
