import React from 'react'
import { Table, Button } from 'antd'
import { FormattedAmount } from 'lib/locale'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import useIsMobile from 'hooks/useIsMobile'

const Footer = ({ tractaments }) => {
  const sum = tractaments.reduce((sum, row) => sum + row.amount, 0)
  return (
    <>
      <span
        style={{
          fontSize: 18,
        }}
      >
        <FormattedMessage
          id="TractamentTable.Footer.sumTitle"
          defaultMessage="Tractament sum"
        />
      </span>
      <strong style={{ float: 'right', fontSize: 18 }}>
        <FormattedAmount valueInCent={sum} currency="SEK" />
      </strong>
    </>
  )
}

const TractamentTable = ({
  tractaments,
  onRemoveTractament,
  readOnly = false,
}) => {
  const isMobile = useIsMobile()
  const columns = [
    !isMobile && {
      title: (
        <FormattedMessage
          id="TractamentTable.Header.country"
          defaultMessage="Country"
        />
      ),
      dataIndex: 'country',
      key: 'country',
    },
    !isMobile && {
      title: (
        <FormattedMessage
          id="TractamentTable.Header.dates"
          defaultMessage="Dates"
        />
      ),
      key: 'dates',
      render: (row) =>
        `${moment(row.dateFrom).format('YYYY-MM-DD HH:mm')} - ${moment(
          row.dateTo
        ).format('YYYY-MM-DD HH:mm')}`,
    },
    isMobile && {
      title: (
        <>
          <FormattedMessage
            id="TractamentTable.Header.country"
            defaultMessage="Country"
          />
          {' & '}
          <FormattedMessage
            id="TractamentTable.Header.dates"
            defaultMessage="Dates"
          />
        </>
      ),
      key: 'dates',
      render: (row) => (
        <>
          {row.country}
          <br />
          {moment(row.dateFrom).format('YYYY-MM-DD HH:mm')}
          <br />
          {moment(row.dateTo).format('YYYY-MM-DD HH:mm')}
        </>
      ),
    },
    {
      title: (
        <FormattedMessage
          id="TractamentTable.Header.totalAmount"
          defaultMessage="totalAmount"
        />
      ),
      key: 'amount',
      dataIndex: 'amount',
      align: 'right',
      render: (amount) => (
        <FormattedAmount valueInCent={amount} currency="SEK" />
      ),
    },
  ].filter(Boolean)

  if (!readOnly) {
    columns.push({
      title: null,
      key: 'action',
      align: 'right',
      render: (id) => (
        <Button
          type="link"
          onClick={() => {
            onRemoveTractament(id)
          }}
          icon="delete"
        />
      ),
    })
  }
  const dataSource = tractaments.map((item, id) => {
    const { amount, ...itemProps } = item

    return {
      id,
      amount: readOnly ? amount.valueInCent : amount,
      key: id,
      ...itemProps,
    }
  })

  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      footer={() => <Footer tractaments={dataSource} />}
      pagination={false}
    />
  )
}

export default TractamentTable
