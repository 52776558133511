import React from 'react'
import ApolloQueryTable from 'components/ApolloQueryTable'
import listInvoices from './listInvoices.gql'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FormattedAmount } from 'lib/locale'
import InvoiceStatusIcon from 'components/InvoiceStatusIcon/InvoiceStatusIcon'
import InvoiceStatusText from 'components/InvoiceStatusText/InvoiceStatusText'
import MobileInvoiceItem from 'components/MobileInvoiceItem/MobileInvoiceItem'
import { useMediaQuery } from 'react-responsive'
import './InvoiceTable.css'

const InvoiceTable = () => {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  const tableStyle = isMobile
    ? {
        width: 'calc(100% + 40px)',
        marginLeft: -20,
      }
    : {}

  const columns = [
    {
      title: '#',
      dataIndex: 'documentNumber',
      key: 'documentNumber',
      render: (text, invoice) => {
        return {
          children: <Link to={`/invoices/${invoice.id}`}>{text}</Link>,
          props: {
            colSpan: isMobile ? 0 : 1,
          },
        }
      },
      sorter: true,
    },
    {
      title: (
        <FormattedMessage
          key="InvoiceTable.customerTitle"
          defaultMessage="Customer"
        />
      ),
      dataIndex: 'customerName',
      key: 'customerName',
      sorter: true,
      render: (customerName, invoice) => {
        return {
          children: isMobile ? (
            <MobileInvoiceItem invoice={invoice} />
          ) : (
            customerName
          ),
          props: {
            colSpan: isMobile ? 4 : 1,
          },
        }
      },
    },
    {
      title: (
        <FormattedMessage
          key="InvoiceTable.statusTitle"
          defaultMessage="Status"
        />
      ),
      dataIndex: 'status',
      sorter: true,
      key: 'status',
      graphqlFilters: [
        {
          type: 'checkboxes',
          checkboxesOptions: {
            options: [
              {
                text: 'Draft',
                value: 'draft',
              },
              {
                text: 'Ready for approval',
                value: 'readyForApproval',
              },
              {
                text: 'Approved',
                value: 'approved',
              },
              {
                text: 'Invoice paid',
                value: 'invoicePaid',
              },
              {
                text: 'Credited',
                value: 'credited',
              },
            ],
          },
        },
      ],
      render: (status, invoice) => {
        return {
          children: (
            <>
              <InvoiceStatusIcon status={status} />
              <span style={{ paddingLeft: 12, fontSize: 14 }}>
                <InvoiceStatusText invoice={invoice} />
              </span>
            </>
          ),
          props: {
            colSpan: isMobile ? 0 : 1,
          },
        }
      },
    },
    {
      title: (
        <FormattedMessage
          key="InvoiceTable.amountTitle"
          defaultMessage="Amount (incl VAT)"
        />
      ),
      dataIndex: 'totalIncVat.formattedValue',
      align: 'right',
      key: 'totalIncVat',
      sorter: true,
      render: (_text, invoice) => {
        return {
          children: <FormattedAmount {...invoice.totalIncVat} />,
          props: {
            colSpan: isMobile ? 0 : 1,
          },
        }
      },
    },
    !isMobile
      ? {
          title: (
            <FormattedMessage
              key="InvoiceTable.actionTitle"
              defaultMessage="Action"
            />
          ),
          key: 'action',
          align: 'right',
          render: (_text, invoice) => (
            <span>
              <Link to={`/invoices/${invoice.id}`}>
                <FormattedMessage
                  key="InvoiceTable.view"
                  defaultMessage="View"
                />
              </Link>
            </span>
          ),
        }
      : null,
  ]

  return (
    <ApolloQueryTable
      queryProps={{ query: listInvoices, fetchPolicy: 'cache-and-network' }}
      dataRef="currentUser.invoices"
      tableProps={{
        className: 'invoice-table',
        columns: columns.filter(Boolean),
        style: { ...tableStyle },
      }}
    />
  )
}

export default InvoiceTable
