import React from 'react'
import { Query } from 'react-apollo'
import { FormattedMessage } from 'react-intl'
import getInvoiceForEdit from './getInvoiceForEdit.gql'
import { Spin, Result } from 'antd'
import EditForm from './EditForm'
import PageLayout from '../../../../components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'
import { useParams } from 'react-router-dom'

const messages = defineMessages({
  pageTitle: {
    id: 'App.InvoicesEdit.pageTitle',
    defaultMessage: 'Edit Invoice'
  }
})

const UserInvoicesEditView = () => {
  const { id } = useParams()

  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <Query query={getInvoiceForEdit} variables={{ id }}>
        {({ loading, error, data = {} }) => {
          if (error) return <div style={{ color: 'red' }}>Error loading invoice.</div>
          if (loading) return <Spin />
          const invoice = data.invoice
          if (invoice.status !== 'draft') {
            return <Result status="warning" title={<FormattedMessage key="onlyDraftTitle" defaultMessage="You can only edit invoice drafts." />} />
          }

          return <EditForm invoice={invoice} />
        }}
      </Query>
    </PageLayout>
  )
}

export default UserInvoicesEditView
