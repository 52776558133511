
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"taxReductionFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"TaxReduction"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"propertyDesignation"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"residenceAssociationOrganisationNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"taxReductionAmounts"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"workType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"askedAmount"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"valueInCent"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":344}};
    doc.loc.source = {"body":"fragment taxReductionFragment on TaxReduction {\n  id\n  # approvedAmount {\n  #     valueInCent\n  # }\n  # askedAmount {\n  #     valueInCent\n  # }\n  # billedAmount {\n  #     valueInCent\n  # }\n  propertyDesignation\n  residenceAssociationOrganisationNumber\n  taxReductionAmounts {\n    id\n    workType\n    askedAmount {\n      valueInCent\n    }\n  }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
