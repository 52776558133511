import React from 'react'
import './PageLayout.css'
import { Typography, Icon } from 'antd'
import { LocalePicker } from '../../lib/locale'
import { FormattedMessage } from 'react-intl'
import cn from 'classnames'

const { Title } = Typography

const PageLayout = ({ children, pageTitleKey, wide = false }) => {
  return (
    <div className={cn('logged-in-page', {
      'logged-in-page--wide': wide
    })}>
      <div className="logged-in-page__header">
        <button className="logged-in-page__menu-toggle" onClick={() => {
          window.dispatchEvent(new CustomEvent('toggleMenu', {}))
        }}>
          <Icon type="menu" />
        </button>
        <div className="logged-in-page__page-title">
          {pageTitleKey && (
            <Title level={2}>
              <FormattedMessage {...pageTitleKey} />
            </Title>
          )}
        </div>
        <div className="logged-in-page__locale-switcher">
          <LocalePicker />
        </div>
      </div>
      <main className="logged-in-page__content">      
        {children}
      </main>
    </div>
  )
}

export default PageLayout