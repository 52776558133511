import React from 'react'
import { Avatar } from 'antd'

const statusDetails = {
  draft: {
    title: 'Draft',
    description:
      'The invoice is still being edited by the user and has not yet been sent.',
    color: 'orange',
  },
  readyForApproval: {
    title: 'Sent for approval',
    description:
      'The user has sent the invoice, but it iss now awaiting invoice approval.',
    color: '#a3b2d1',
  },
  approved: {
    title: 'Approved',
    description: 'The invoice is sent to the users customer',
    color: '#a3b2d1',
  },
  notApproved: {
    title: 'Not Approved',
    description: 'An admin chose not to approve this invoice.',
    color: 'red',
  },
  invoicePaid: {
    title: 'Paid',
    description: 'The invoice has been paid',
    color: '#99D7BF',
  },
  credited: {
    title: 'Credited',
    description: 'You have credited this invoice',
    color: 'grey',
  },
}

const InvoiceStatusIcon = ({ status }) => {
  if (!statusDetails[status]) {
    console.warn(
      `Invalid status "${status}" supplied to <InvoiceStatusTag> component.`
    )
  }

  const {
    color = '#fafafa',
    description = `No description available for ${status}`,
  } = statusDetails[status] || {}
  return (
    <Avatar icon="file" alt={description} style={{ backgroundColor: color }} />
  )
}

export default InvoiceStatusIcon
