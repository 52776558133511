import React from 'react'
import moment from 'moment'
import DayPicker from 'react-day-picker'

import 'react-day-picker/lib/style.css'
import './InvoiceCalendar.css'

import InvoiceRows, { emptyRow } from '../InvoiceRows/InvoiceRows'
import useIsMobile from 'hooks/useIsMobile'

const getDatesFromRows = (invoiceRows) => {
  return invoiceRows
    .map((row) => {
      const m = moment(row.description)
      return m.isValid() ? m : null
    })
    .filter(Boolean)
}

const InvoiceCalendar = ({ invoiceRows, onChange }) => {
  const isMobile = useIsMobile()
  const dates = getDatesFromRows(invoiceRows)
  const selectedDates = dates.map((m) => m.format('YYYY-MM-DD'))
  const selectedDays = dates.map((d) => d.toDate())
  return (
    <>
      <div className="invoice-calendar">
        <DayPicker
          showWeekNumbers
          selectedDays={selectedDays}
          numberOfMonths={isMobile ? 1 : 2}
          onDayClick={(day) => {
            const date = moment(day).format('YYYY-MM-DD')
            const index = selectedDates.indexOf(date)
            if (index !== -1) {
              if (selectedDates.length === 1) {
                return false // Do not allow to remove all days!
              }
              invoiceRows.splice(index, 1)
            } else {
              invoiceRows.push({
                ...emptyRow,
                description: date,
              })
            }
            onChange(invoiceRows)
          }}
        />
      </div>

      <InvoiceRows invoiceRows={invoiceRows} onChange={onChange} calendarMode />
    </>
  )
}

export default InvoiceCalendar
