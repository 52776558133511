import React from 'react'
import Zendesk from 'react-zendesk'
const zendeskKey = '9a516edf-c03c-48de-81ad-0904b1721f26'

export default function (props) {
  return (
    <Zendesk
      zendeskKey={zendeskKey}
      {...props}
      onLoaded={() => console.log('zendesk is loaded')}
    />
  )
}
