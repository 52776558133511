
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"fullEmployee"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Employee"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"taxTable"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"personalIdentityNumber"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"firstName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"lastName"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"address"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"zipCode"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"city"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"country"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"phone"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"clearingNo"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"bankAccountNo"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":176}};
    doc.loc.source = {"body":"fragment fullEmployee on Employee {\n  id\n  taxTable\n  personalIdentityNumber\n  firstName\n  lastName\n  address\n  zipCode\n  city\n  country\n  phone\n  clearingNo\n  bankAccountNo\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
