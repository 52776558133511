/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

function FilterButtons({ ok, clear }) {
  return (
    <div className={'ant-table-filter-dropdown-btns'}>
      <a className={'ant-table-filter-dropdown-link confirm'} onClick={ok}>
        <FormattedMessage key="ok" defaultMessage="OK" />
      </a>
      <a className={'ant-table-filter-dropdown-link clear'} onClick={clear}>
        <FormattedMessage key="reset" defaultMessage="Reset" />
      </a>
    </div>
  )
}

FilterButtons.propTypes = {
  ok: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired
}

export default FilterButtons
