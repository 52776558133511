import React, { Component } from 'react'
import { Typography } from 'antd'
import { withAuth } from 'services/auth'
import LoginForm from '../components/LoginForm/LoginForm'
import { Redirect, Link } from 'react-router-dom'
import BankidSign from '../components/BankidSign/BankidSign'
import LoginFineprint from '../components/LoginFineprint/LoginFineprint'
import TabsBankidEmail from '../components/TabsBankidEmail/TabsBankidEmail'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'LoginView.title',
    defaultMessage: 'Log in',
  },
  signupInfo: {
    id: 'LoginView.signupInfo',
    defaultMessage: 'No account yet?',
  },
  signupAction: {
    id: 'LoginView.signupAction',
    defaultMessage: 'Sign up now',
  },
  forgotPwInfo: {
    id: 'LoginView.forgotPwInfo',
    defaultMessage: 'Trouble?',
  },
  forgotPwAction: {
    id: 'LoginView.forgotPwAction',
    defaultMessage: 'Request a new password',
  },
})

const { Title } = Typography

class LoginView extends Component {
  state = {
    fieldErrors: {},
    saving: false,
    email: null,
  }
  saveFormRef = (formRef) => {
    this.formRef = formRef
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = this.formRef.props.form

    form.validateFieldsAndScroll(async (err, values) => {
      if (err) return
      this.setState({ saving: true })
      const { errors } = await this.props.login(values)
      this.setState({ saving: false })
      if (errors) {
        this.setState({ fieldErrors: errors })
      }
    })
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/home" />
    }

    const { handleLoginRes } = this.props

    return (
      <>
        <Title level={2}>
          <FormattedMessage {...messages.title} />
        </Title>

        <TabsBankidEmail
          bankidPane={<BankidSign onCompleted={handleLoginRes} loginUi />}
          emailPane={
            <LoginForm
              wrappedComponentRef={this.saveFormRef}
              onChange={({ email }) =>
                email && this.setState({ email: email.value })
              }
              fieldErrors={this.state.fieldErrors}
              onSubmit={this.handleSubmit}
              saving={this.state.saving}
            />
          }
        />

        <p style={{ marginTop: 50 }}>
          <FormattedMessage {...messages.signupInfo} />{' '}
          <Link
            to={{ pathname: '/signup', state: { email: this.state.email } }}
          >
            <FormattedMessage {...messages.signupAction} />
          </Link>
        </p>
        <p>
          <FormattedMessage {...messages.forgotPwInfo} />{' '}
          <Link
            to={{
              pathname: '/forgot-password',
              state: { email: this.state.email },
            }}
          >
            <FormattedMessage {...messages.forgotPwAction} />
          </Link>
        </p>

        <LoginFineprint />
      </>
    )
  }
}

export default injectIntl(withAuth(LoginView))
