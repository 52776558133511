function componentToHex(c) {
  var hex = c.toString(16)
  return hex.length == 1 ? '0' + hex : hex
}
/**
* Parse color parameters to hex value.
* @param r
* @param g
* @param b
*/
function rgbToHex(value) {
  return '#' + componentToHex(value.r) + componentToHex(value.g) + componentToHex(value.b)
}
/**
* Parse hex value to colors parameters.
* @param hex
*/
function hexToRgb(hex) {
  let data = parseInt(hex[0] != '#' ? hex : hex.substring(1), 16)
  return {
      r: data >> 16 & 255,
      g: data >> 8 & 255,
      b: data & 255,
  }
}


function toUnitVector(n) {
    return Math.round(n / 255 * 1000) / 1000
}
function fromUnitVector(n) {
    return Math.round(n * 255)
}
function colorsIterator(data, callback) {
    if (!data) {
        return
    }
    data.forEach((layer, i) => {
        if (layer.shapes) {
            layer.shapes.forEach((shape, j) => {
                if (!shape.it) {
                    return
                }
                shape.it.forEach((prop, k) => {
                    if (['fl', 'st'].includes(prop.ty)) {
                        if (!callback) {
                            return
                        }
                        let [r, g, b, a] = prop.c.k
                        r = fromUnitVector(r)
                        g = fromUnitVector(g)
                        b = fromUnitVector(b)
                        callback({
                            i,
                            j,
                            k,
                            r,
                            g,
                            b,
                            a,
                            color: rgbToHex({ r, g, b }),
                        })
                    }
                })
            })
        }
    })
}

/**
 * Extracts colors found in the animation.
 * @param data
 */
export function colors(data) {
    const colors = []
    colorsIterator(data.layers, (row) => {
        const { color } = row
        if (!colors.includes(color)) {
            colors.push(color)
        }
    })
    return colors
}
export function replacePalette(data, paletteData) {
    if (!paletteData || !data) {
        throw new TypeError('Missing parameters.')
    }
    if (typeof data !== 'object' || (typeof paletteData !== 'object' && typeof paletteData !== 'string')) {
        throw new TypeError('Invalid parameters.')
    }
    let parsedPalette = {}
    if (typeof paletteData === 'object') {
        parsedPalette = paletteData
    }
    else if (typeof paletteData === 'string') {
        let usedColors
        const sections = paletteData.split(';').filter(c => c)
        for (let i = 0; i < sections.length; ++i) {
            const currentSection = sections[i]
            const parts = currentSection.split(':').filter(c => c)
            const [first, second] = parts
            if (first && second) {
                parsedPalette[first] = second
            }
            else if (first) {
                if (!usedColors) {
                    usedColors = colors(data)
                }
                parsedPalette[usedColors[i]] = first
            }
        }
    }
    if (!Object.keys(parsedPalette).length) {
        return data
    }
    const clonedData = JSON.parse(JSON.stringify(data))
    colorsIterator(clonedData.layers, (row) => {
        const { i, j, k, a, color } = row
        if (!parsedPalette[color]) {
            return
        }
        const { r, g, b } = hexToRgb(parsedPalette[color])
        clonedData.layers[i].shapes[j].it[k].c.k = [
            toUnitVector(r),
            toUnitVector(g),
            toUnitVector(b),
            a,
        ]
    })
    return clonedData
}
