import React from 'react'

class Hoverable extends React.Component{
  state = { hovered: false };
  
  render() {
    return (
      <div
        onMouseEnter={() => this.setState({ hovered: true })}
        onMouseLeave={() => this.setState({ hovered: false })}
      >
        {this.props.children(this.state.hovered)}
      </div>
    )
  }
}

export default Hoverable