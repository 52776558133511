import React from 'react'
import { AuthConsumer } from 'services/auth'
import ChangePassword from '../../../components/ChangePassword/ChangePassword'
import PageLayout from '../../../components/PageLayout/PageLayout'
import SettingsLayout from '../../../components/SettingsLayout/SettingsLayout'
import BankidLogo from 'components/BankidLogo/BankidLogo'
import { defineMessages, FormattedMessage } from 'react-intl'

const BankIdEnabled = () => {
  return (
    <>
      <BankidLogo style={{ float: 'right' }} />
      <div>
        <FormattedMessage key="bankidLoginEnabled" defaultMessage="Your login method is BankID. It's not possible to change from BankID login after you have enabled BankID." />
      </div>
    </>
  )
}

const messages = defineMessages({
  pageTitle: {
    id: 'App.SettingsChangePassword.pageTitle',
    defaultMessage: 'Settings - Change password'
  }
})

const UserSettingsView = () => {
  return (
    <PageLayout pageTitleKey={messages.pageTitle}>
      <SettingsLayout active="login">
        <AuthConsumer>
          {({ user }) => {
            if (user && !user.bankIdConfirmed) return <ChangePassword user={user} />
            if (user && user.bankIdConfirmed) return <BankIdEnabled />
          }}
        </AuthConsumer>
      </SettingsLayout>
    </PageLayout>
  )
}

export default UserSettingsView
