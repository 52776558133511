import React from 'react'
import draftInvoice from './draftInvoice.gql'
import { Mutation } from 'react-apollo'
import { message } from 'antd'
import FancyButton from '../FancyButton/FancyButton'
import { FormattedMessage, useIntl } from 'react-intl'

import getInvoice from '../InvoiceView/getInvoice.gql'
import listInvoices from '../InvoiceTable/listInvoices.gql'

const DraftInvoiceButton = ({ invoice }) => {
  const intl = useIntl()

  if (!invoice) return null

  const { id, status } = invoice

  if (status !== 'readyForApproval') {
    return null
  }

  return (
    <Mutation
      mutation={draftInvoice}
      variables={{ id }}
      refetchQueries={[
        { query: getInvoice, variables: { id } },
        { query: listInvoices },
      ]}
      awaitRefetchQueries={true}
      onCompleted={() => {
        message.info(
          intl.formatMessage({
            id: 'components.DraftInvoiceButton.successMessage',
            defaultMessage: 'Invoice moved back to draft',
            values: { status },
          })
        )
      }}
    >
      {(mutate, { loading }) => (
        <FancyButton
          variant="secondary"
          type="primary"
          onClick={() => mutate()}
          loading={loading}
          style={{ marginRight: 10 }}
        >
          <FormattedMessage
            key="buttonText"
            defaultMessage="Move to Draft"
            values={{ status }}
          />
        </FancyButton>
      )}
    </Mutation>
  )
}

export default DraftInvoiceButton
