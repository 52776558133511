import React from 'react'

import Lottie from 'lottie-react-web'
import { replacePalette } from './replacePalette'

const LottieIcon = ({ animation, play = false }) => (
  <div style={{ display: 'inline-block', marginRight: 3, verticalAlign: '-0.25em' }}>
    <Lottie
      isStopped={!play}
      width={24}
      height={24}
      options={{
        animationData: replacePalette(animation, '#ffffff;#dddddd;#34af7f'),
        autoplay: false,
        loop: false
      }}
    />
  </div>
)

export default LottieIcon