import React, { Component } from 'react'
import { Layout, Menu, Badge, Icon } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { BrandLogo } from 'components/Brand/Brand'
import './Sidebar.css'
import LottieIcon from '../LottieIcon/LottieIcon'
import Hoverable from '../Hoverable/Hoverable'
import { useMediaQuery } from 'react-responsive'

const { Sider } = Layout
const SubMenu = Menu.SubMenu

const Logo = ({ theme = 'dark' }) => (
  <div className="sidebar__logo">
    <Link to={'/home'}>
      <BrandLogo theme={theme === 'dark' ? 'brightSlogan' : 'defaultSlogan'} />
    </Link>
  </div>
)

const MenuClickable = ({ to, onClick, children }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })

  if (to) return <Link to={to} onClick={() => {
    if (isMobile) {
      window.dispatchEvent(new CustomEvent('toggleMenu', {}))
    }
  }}>{children}</Link>
  if (onClick) return <div onClick={onClick}>{children}</div>

  return <>{children}</>
}

const MenuLink = ({ item }) => {
  return (
    <MenuClickable to={item.to} onClick={item.onClick}>
      <Hoverable>{hovered => <MenuTextIconAndBadge item={item} hovered={hovered} />}</Hoverable>
    </MenuClickable>
  )
}

const MenuTextIconAndBadge = ({ item, hovered }) => (
  <>
    {item.lottieIcon && <LottieIcon play={hovered} animation={item.lottieIcon} />}
    {item.icon && <Icon type={item.icon} />}
    <span className="menu-item__text">{item.text}</span>
    {item.bagde && <Badge count={item.bagde} />}
  </>
)

class Sidebar extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  onToggleMenu = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('toggleMenu', this.onToggleMenu)
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('toggleMenu', this.onToggleMenu)
    }
  }

  getActiveRoute = () => {
    const { menuItems } = this.props
    const { pathname } = this.props.history.location
    const primaryActiveRoute = [...menuItems.filter(menuItem => !menuItem.subItems), ...menuItems.map(menuItem => (menuItem.subItems ? menuItem.subItems : []))]
      .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
      .find(({ to }) => to === pathname)
    const secondaryActiveRoute = [...menuItems, ...menuItems.map(menuItem => (menuItem.subItems ? menuItem.subItems : []))]
      .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
      .filter(({ to }) => pathname.match(new RegExp('^' + to, 'g')))
      .sort((a, b) => pathname.match(new RegExp('^' + b.to, 'g'))[0].length - pathname.match(new RegExp('^' + a.to, 'g'))[0].length)[0]

    return primaryActiveRoute ? primaryActiveRoute : secondaryActiveRoute
  }

  getActiveCategory = () => {
    const { menuItems } = this.props
    const primaryActiveCategory = [...menuItems].find(({ subItems }) => subItems && subItems.find(({ to }) => to === this.props.history.location.pathname))

    const secondaryActiveCategory = [...menuItems, ...menuItems.map(menuItem => (menuItem.subItems ? menuItem.subItems : []))]
      .reduce((accumulator, currentValue) => accumulator.concat(currentValue), [])
      .find(({ to }) => this.props.history.location.pathname.includes(to))

    return primaryActiveCategory ? primaryActiveCategory : secondaryActiveCategory
  }

  getActiveCategoryKey = () => (this.getActiveCategory() ? this.getActiveCategory().key : undefined)

  state = {
    openKeysNonCollapsed: [this.getActiveCategoryKey()],
    openKeysCollapsed: [],
    expanded: false
  }

  onRouteChanged() {
    this.setState({
      openKeysNonCollapsed: [this.getActiveCategoryKey()]
    })
  }

  render() {
    const { theme = 'dark', isMobile } = this.props
    const { expanded, openKeys = [], openKeysCollapsed } = this.state
    const collapsed = isMobile ? !expanded : false

    return (
      <Sider trigger={null} breakpoint="lg" collapsedWidth="0" collapsed={collapsed} width={280} className="sidebar" theme={theme}>
        <Logo theme={theme} />
        <Menu
          theme={theme}
          selectedKeys={[this.getActiveRoute() ? this.getActiveRoute().key : this.getActiveCategory() ? this.getActiveCategoryKey() : 'none']}
          defaultOpenKeys={openKeys}
          openKeys={openKeysCollapsed}
          mode="inline"
          onOpenChange={openKeys => {
            this.setState({ openKeysCollapsed: openKeys })
          }}
        >
          {this.props.menuItems.map(menuItem => {
            if (menuItem.condition && !menuItem.condition()) {
              return null
            }

            return menuItem.subItems ? (
              <SubMenu
                className={menuItem.key === this.getActiveCategoryKey() ? 'ant-menu-submenu-act-as-open' : ''}
                key={menuItem.key}
                title={<Hoverable>{hovered => <MenuTextIconAndBadge hovered={hovered} item={menuItem} />}</Hoverable>}
                onTitleClick={() => {
                  this.props.history.push(menuItem.to)
                }}
              >
                {menuItem.subItems.map(subItem => (
                  <Menu.Item key={subItem.key}>
                    <MenuLink item={subItem} />
                  </Menu.Item>
                ))}
              </SubMenu>
            ) : (
              <Menu.Item key={menuItem.key}>
                <MenuLink item={menuItem} />
              </Menu.Item>
            )
          })}
        </Menu>
      </Sider>
    )
  }
}

const SidebarWithMobile = props => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  return <Sidebar isMobile={isMobile} {...props} />
}

export default withRouter(SidebarWithMobile)
