import React from 'react'
import InvoiceForm from '../../../../components/InvoiceForm/InvoiceForm'
import PageLayout from '../../../../components/PageLayout/PageLayout'
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  pageTitle: {
    id: 'App.InvoicesCreate.pageTitle',
    defaultMessage: 'New Invoice'
  }
})

const UserInvoicesCreateView = () => (
  <PageLayout pageTitleKey={messages.pageTitle}>
    <InvoiceForm />
  </PageLayout>
)

export default UserInvoicesCreateView
