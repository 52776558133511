import React from 'react'
import { Select } from 'antd'

import './LocaleSelect.css'
import sweFlag from './flag-sweden.svg'
import engFlag from './flag-eng.svg'

const { Option } = Select

const LocaleSelect = ({ loading, defaultValue, onChange }) => (
  <div className='locale-select'>
    <Select
      size="large"
      loading={loading}
      defaultValue={defaultValue}
      onChange={onChange}
      >
      <Option key="en" value="en">
        <img src={engFlag} alt="English" />
      </Option>
      <Option key="sv" value="sv">
        <img src={sweFlag} alt="Svenska" />
      </Option>
    </Select>
  </div>
)

export default LocaleSelect