import { useState, useEffect } from 'react'
import dotProp from 'dot-prop-immutable'
import { useQuery } from 'react-apollo'
import useEmployeeQuery from './useEmployee.gql'

const enrichEmployee = data => {
  const currentUser = dotProp.get(data, 'currentUser')
  const raw = dotProp.get(data, 'currentUser.employee')
  if (!raw) return null

  const readyToInvoice = Boolean(raw.personalIdentityNumber) && Boolean(raw.bankAccountNo) && Boolean(raw.clearingNo)
  const hasInvoices = Boolean(currentUser.invoices && currentUser.invoices.totalCount > 0)

  return {
    readyToInvoice,
    hasInvoices,
    ...raw
  }
}

const useEmployee = () => {
  const [employee, setEmployee] = useState(null)
  const { data, error } = useQuery(useEmployeeQuery)

  useEffect(() => {
    if (error) {
      console.error('Error in useEmployee')
      console.error(error)
    } else {
      setEmployee(enrichEmployee(data))
    }
  }, [data, error])

  return employee
}

export default useEmployee
