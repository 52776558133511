import { Alert } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { brandName } from 'components/Brand/Brand'

const AdminAlert = ({ user }) => {
  if (!user || !user.roles || !user.roles.includes('admin')) return null

  return (
    <Alert
      type="info"
      closable
      message={
        <>
          <span
            style={{
              marginRight: '5px',
            }}
          >
            <FormattedMessage
              key="asAdmin"
              defaultMessage="You are using {brandName} as an admin user"
              values={{ brandName }}
            />
          </span>
          <Link to="/admin">
            <FormattedMessage
              key="asAdminLink"
              defaultMessage="Go to admin panel"
            />
          </Link>
        </>
      }
      banner
    />
  )
}

export default AdminAlert
