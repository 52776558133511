import React from 'react'
import { Form, Input, Upload, Icon } from 'antd'
import { FormattedMessage } from 'react-intl'
import FancyButton from '../FancyButton/FancyButton'
import AmountInput from '../AmountInput/AmountInput'
import VatPercentageSelect from '../VatPercentageSelect/VatPercentageSelect'
import FileUpload from 'components/FileUpload/FileUpload'
import { fileUpload } from 'lib/validators'

class ReimbursementForm extends React.Component {
  handleSubmit = e => {
    const { form, onAddReimbursement } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        onAddReimbursement(values)
        form.resetFields()
      }
    })
  }

  render() {
    const { form } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Item label={<FormattedMessage id="ReimbursementForm.Form.descriptionLabel" defaultMessage="Description of reimbursement" />}>
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id="ReimbursementForm.Form.descriptionRequired" defaultMessage="Description is required" />
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={<FormattedMessage id="ReimbursementForm.Form.supplierLabel" defaultMessage="Supplier of reimbursement" />}>
          {getFieldDecorator('supplier', {
            rules: [
              {
                required: true,
                message: <FormattedMessage id="ReimbursementForm.Form.supplierRequired" defaultMessage="Supplier is required" />
              }
            ]
          })(<Input />)}
        </Form.Item>

        <Form.Item label={<FormattedMessage id="ReimbursementForm.Form.amountLabel" defaultMessage="Amount of reimbursement" />}>
          {getFieldDecorator('amount', {
            initialValue: 10000,
            rules: [
              {
                required: true,
                message: <FormattedMessage id="ReimbursementForm.Form.amountRequired" defaultMessage="Amount is required" />
              }
            ]
          })(<AmountInput />)}
        </Form.Item>

        <Form.Item label={<FormattedMessage id="ReimbursementForm.Form.vatPercentageLabel" defaultMessage="VAT percentage of reimbursement" />}>
          {getFieldDecorator('vatPercentage', {
            initialValue: '_25',
            rules: [
              {
                required: true,
                message: <FormattedMessage id="ReimbursementForm.Form.vatPercentageRequired" defaultMessage="Vat Percentage is required" />
              }
            ]
          })(<VatPercentageSelect />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('file', {
            validateTrigger: false,
            rules: [{ validator: fileUpload() }, { required: true, message: <FormattedMessage key="fileRequired" defaultMessage="File is required" /> }]
          })(
            <FileUpload multiple={false}>
              {extraProps => (
                <Upload.Dragger {...extraProps} multiple={false} accept=".pdf,.jpg,.png,.jpeg">
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">
                    <FormattedMessage key="uploadTitle" defaultMessage="Upload Receipt / Invoice" />
                  </p>
                  <p className="ant-upload-hint">
                    <FormattedMessage key="hint" defaultMessage="Drag file here or click and choose a file" /><br />
                    <FormattedMessage key="supported" defaultMessage="(Supported file formats are .pdf, .jpg, .jpeg, .png)" />
                  </p>
                </Upload.Dragger>
              )}
            </FileUpload>
          )}
        </Form.Item>

        <Form.Item style={{ marginTop: 24 }}>
          <FancyButton variant="cta" type="submit">
            <FormattedMessage id="ReimbursementForm.Form.cta" defaultMessage="Add Reimbursement" />
          </FancyButton>
        </Form.Item>
      </Form>
    )
  }
}

const WrappedReimbursementForm = Form.create({ name: 'reimbursementForm' })(ReimbursementForm)

export default WrappedReimbursementForm
