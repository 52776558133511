import React from 'react'
import { InputNumber } from 'antd'

class AmountInput extends React.Component {
  render() {
    const {
      min = 1,
      max = 10000000,
      style = { width: 140 },
      step = 0.01,
      value,
      onChange,
      ...extraProps
    } = this.props

    const formattedValue = (value / 100).toFixed(2)

    const localOnChange = (val) => {
      const raw = Number.isNaN(val)
        ? val
            .toString()
            .replace(/[^0-9.]/g, '')
            .replace(/(\..*)\./g, '$1')
        : val
      const newVal = Math.round(parseFloat(raw) * 100)
      onChange(newVal)
    }

    return (
      <InputNumber
        min={min}
        max={max}
        style={style}
        step={step}
        value={formattedValue}
        onChange={localOnChange}
        {...extraProps}
      />
    )
  }
}

export default AmountInput
