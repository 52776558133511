import React from 'react'
import { Row, Col, Tooltip } from 'antd'
import styles from './MyFinancesWidget.module.css'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { FormattedAmount } from 'lib/locale'
import { Query } from 'react-apollo'
import myFinancesQuery from './myFinancesQuery.gql'
import dotProp from 'dot-prop-immutable'
import { useMediaQuery } from 'react-responsive'

const calcHeight = (value, maxValue, maxPercentageHeight, minPercentageHeight) => {
  if (value === 0) return minPercentageHeight
  // Calculates the height of the smaller bar.
  // The smaller bar is at least 10% of the total height, and at most the same height as the larger bar.
  const percentage = Math.round((value / maxValue) * 100 * (maxPercentageHeight / 100))
  return Math.max(percentage, minPercentageHeight)
}

const MyFinancesWidget = ({ lastInvoiceId, lastAmount = 0, upcomingAmount = 0 }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 })
  const maxPercentageHeight = 80
  const minPercentageHeight = 10

  const lastPercentage = lastAmount > upcomingAmount ? maxPercentageHeight : calcHeight(lastAmount, upcomingAmount, maxPercentageHeight, minPercentageHeight)
  const upcomingPercentage = upcomingAmount > lastAmount ? maxPercentageHeight : calcHeight(upcomingAmount, lastAmount, maxPercentageHeight, minPercentageHeight)

  return (
    <div className={styles.container}>
      {isMobile && (
        <h2 className={styles.title}>
          <FormattedMessage key="MyFinancesWidget.title" defaultMessage="My Salary" />
        </h2>
      )}
      <Row gutter={25}>
        <Col sm={24} md={24} lg={24} xl={10}>
          <div className={styles.graphs}>
            <Tooltip title={<FormattedAmount valueInCent={lastAmount} currency="SEK" />}>
              <Link to={lastInvoiceId ? `/invoices/${lastInvoiceId}` : '/invoices/create'} className={styles.graphLast}>
                <div className={styles.lastAmount} style={{ bottom: `${lastPercentage}%` }}>
                  <FormattedAmount valueInCent={lastAmount} currency="SEK" />
                </div>
                <div style={{ height: `${lastPercentage}%` }} className={styles.graphBarLast} />
              </Link>
            </Tooltip>

            <Tooltip title={<FormattedAmount valueInCent={upcomingAmount} currency="SEK" />}>
              <Link
                to={'/invoices?currentUser.invoices.sort=totalExVat_ASC&currentUser.invoices.filter=%7B"status"%3A%7B"%24in"%3A%5B"approved"%5D%7D%7D'}
                className={styles.graphUpcoming}
              >
                <div className={styles.upcomingAmount} style={{ bottom: `${upcomingPercentage}%` }}>
                  <FormattedAmount valueInCent={upcomingAmount} currency="SEK" />
                </div>
                <div style={{ height: `${upcomingPercentage}%` }} className={styles.graphBarUpcoming} />
              </Link>
            </Tooltip>
          </div>
        </Col>

        <Col sm={24} md={24} lg={24} xl={14}>
          <div className={styles.details}>
            {!isMobile && (
              <h2 className={styles.title}>
                <FormattedMessage key="MyFinancesWidget.title" defaultMessage="My Salary" />
              </h2>
            )}

            <div className={styles.legendContainer}>
              <div className={styles.legendLast}>
                <FormattedMessage key="MyFinancesWidget.lastSalaryLegend" defaultMessage="Last salary" />
              </div>
              <div className={styles.legendUpcoming}>
                <FormattedMessage key="MyFinancesWidget.upcomingSalaryLegend" defaultMessage="Upcoming salary" />
              </div>
            </div>

            <p className={styles.finePrint}>
              <FormattedMessage key="MyFinancesWidget.upcomingSalaryDescription" defaultMessage="Upcoming salary is calculated from all unpaid invoices" />
            </p>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const MyFinancesWidgetData = () => (
  <Query query={myFinancesQuery}>
    {({ loading, error, data = {} }) => {
      if (error) return <div style={{ color: 'red' }}>Error loading widget data.</div>

      const lastInvoiceId = dotProp.get(data, 'currentUser.insights.myFinances.lastInvoiceId')
      const lastAmount = dotProp.get(data, 'currentUser.insights.myFinances.lastAmount.valueInCent')
      const upcomingAmount = dotProp.get(data, 'currentUser.insights.myFinances.upcomingAmount.valueInCent')

      return <MyFinancesWidget loading={loading} lastInvoiceId={lastInvoiceId} lastAmount={lastAmount} upcomingAmount={upcomingAmount} />
    }}
  </Query>
)

export default MyFinancesWidgetData
